const scroll = {
  mounted(el, { value }, vnode) {
    el.$drag = function (e) {
      if (value && value.dragByHandleClass) {
        const ev = e || window.event || arguments.callee.caller.arguments[0];
        if (![...ev.target.classList].includes(value.dragByHandleClass)) return false;
      }
      let disx = e.pageX - el.offsetLeft;
      let disy = e.pageY - el.offsetTop;
      document.onmousemove = function (e) {
        if (!document.querySelector('#yololiv-drag-mask')) {
          scroll.mask(el, vnode);
        }
        el.style.userSelect = 'none';
        el.style.left = 'unset';
        el.style.top = 'unset';
        el.style.right = `${document.body.clientWidth - e.pageX + disx - el.clientWidth}px`;
        el.style.bottom = `${document.body.clientHeight - e.pageY + disy - el.clientHeight}px`;
        if (parseInt(el.style.right) <= 0) {
          el.style.right = '0px';
        }
        if (parseInt(el.style.bottom) <= 0) {
          el.style.bottom = '0px';
        }
        if (parseInt(el.style.right) >= document.body.clientWidth - el.clientWidth) {
          el.style.right = `${document.body.clientWidth - el.clientWidth}px`;
        }
        if (parseInt(el.style.bottom) >= document.body.clientHeight - el.clientHeight) {
          el.style.bottom = `${document.body.clientHeight - el.clientHeight}px`;
        }
      };
      document.onmouseup = function () {
        el.style.userSelect = '';
        document.onmousemove = document.onmouseup = null;
        el.maskDom && el.maskDom.remove && el.maskDom.remove();
      };
    };
    el.addEventListener('mousedown', el.$drag);
  },
  unmounted(el) {
    el.removeEventListener('mousedown', el.$drag);
  },
  mask(el) {
    el.maskDom = document.createElement('div');
    el.maskDom.style.position = 'fixed';
    el.maskDom.style.zIndex = '1';
    el.maskDom.style.left = '0';
    el.maskDom.style.top = '0';
    el.maskDom.style.right = '0';
    el.maskDom.style.bottom = '0';
    el.maskDom.id = 'yololiv-drag-mask';
    el.append(el.maskDom);
    return function () {
      el.maskDom.remove();
    };
  },
};
export default scroll;
