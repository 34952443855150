import * as ConstantLabel from '@/constant/label';
import * as ConstantRouter from '@/constant/router';
import loadable from '@/utils/loadable';

const route = {
  path: '/yolocut',
  name: ConstantRouter.YOLOCUT_LIST,
  meta: {
    name: ConstantLabel.YOLOCUT_LIST,
  },
  component: loadable(() => import(/* webpackChunkName: "composition" */ '@/yolocut/list.vue')),
};

export default route;
