import * as ConstantLabel from '@/constant/label';
import * as ConstantRouter from '@/constant/router';
import loadable from '@/utils/loadable';

const route = {
  path: 'embed/:id',
  name: ConstantRouter.EMBED_DETAIL,
  component: loadable(() => import('@/pages/embed/detail/index.vue')),
  redirect: { name: ConstantRouter.EMBED_GENERAL },
  children: [
    {
      path: 'general',
      name: ConstantRouter.EMBED_GENERAL,
      meta: {
        name: ConstantLabel.EMBED_GENERAL,
      },
      component: loadable(() => import('@/pages/embed/detail/general/index.vue')),
    },
    {
      path: 'embed',
      name: ConstantRouter.EMBED_MAIN,
      meta: {
        name: ConstantLabel.EMBED_MAIN,
      },
      component: loadable(() => import('@/pages/embed/detail/embed/index.vue')),
    },
  ],
};

export default route;
