import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import { localStore } from '@/utils/store';
// import useUserStore from '@/store/user';
// import useCurrentPlanStore from '@/store/currentPlan';
// import upgradeTip from '@/utils/upgradeTip';

const router = createRouter({
  history: createWebHistory('/'),
  routes,
});

router.beforeEach((to, _, next) => {
  // const userStore = useUserStore();
  // const currentPlanStore = useCurrentPlanStore();

  if (to.meta.noVerify || to.meta.noOverdue) {
    next();
    return;
  }
  // 现在即使套餐过期了, 也是有个默认套餐，默认套餐有相应的权限
  // if (userStore.detail?.isExpiredSubscription && currentPlanStore.currentPlanData) {
  //   upgradeTip();
  //   return;
  // }

  const token = localStore.get('token');

  if (to.path.startsWith('/user/password-reset') || to.path.startsWith('/user/register')) {
    // 重置密码的话 不需要根据token判断进去主页 还是可以修改密码
    // 注册也不需要根据token判断进去主页 还是可以注册操作
    next();
    return;
  }

  if (to.path.startsWith('/user')) {
    if (token) {
      next('/');
      return;
    }
    next();
    return;
  }
  if (!token) {
    next(`/user/login?redirect=${to.fullPath}`);
  }
  next();
});

export default router;
