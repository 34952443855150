<template>
  <div class="media-preview">
    <div v-show="visiblePreview" class="video-fixed">
      <div class="content-white">
        <div class="img-f-del" @click="closeDialog"></div>
        <div class="player-video-container-wrap">
          <video
            id="back-player"
            class="preview-content"
            preload="auto"
            playsinline
            webkit-playsinline
          ></video>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { nextTick, onMounted, PropType, ref, toRefs } from 'vue';

import { ICurrentItem } from '@/pages/media/type';

const emit = defineEmits(['close']);
const props = defineProps({
  currentItem: {
    type: Object as PropType<ICurrentItem>,
    required: true,
  },
});
const { currentItem } = toRefs(props);
const visiblePreview = ref<boolean>(false);
let backPlayer: any = null;

const closeDialog = () => {
  visiblePreview.value = false;
  emit('close', backPlayer.currentTime());
};

function resetVideoDom() {
  if (document.querySelector('.player-video-container-wrap')) {
    if (backPlayer) {
      backPlayer.dispose();
      backPlayer = null;
    }
    document.querySelector('.player-video-container-wrap')!.innerHTML = `
        <video
          id="back-player"
          class="preview-content"
          preload="auto"
          playsinline
          webkit-playsinline
        ></video>`;
  }
}

onMounted(() => {
  visiblePreview.value = true;
  nextTick(async () => {
    await resetVideoDom();
    let timer: any = setTimeout(() => {
      clearTimeout(timer);
      timer = null;
      backPlayer = TCPlayer('back-player', {
        language: 'en',
        autoplay: true,
      });
      backPlayer.src(currentItem.value.viewUrl || currentItem.value.url);
      if (currentItem?.value.currentPlayTime) {
        backPlayer.currentTime(currentItem?.value.currentPlayTime);
      }
    }, 50);
  });
});
onBeforeUnmount(() => {
  if (backPlayer) {
    backPlayer.dispose();
    backPlayer = null;
  }
});
</script>
<style lang="scss">
.media-preview {
  .video-fixed {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.4);

    .content-white {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 960px;
      height: 540px;
    }

    .img-f-del {
      position: absolute;
      top: -20px;
      right: -20px;
      z-index: 1000;
      width: 40px;
      height: 40px;
      cursor: pointer;
      background: url(@/assets/images/media/icon-close-video.png) no-repeat;
      background-size: 100%;
      transition: all 0.2s;
      transform: rotate(0deg);

      &:hover {
        transform: rotate(90deg);
      }
    }

    #back-player {
      position: relative;
      z-index: 10;
      width: 960px !important;
      height: 540px !important;
      margin: 0 auto;
    }
  }
}
</style>
