<script setup lang="ts">
import debounce from 'lodash/debounce';

import { PlanCalculateParam } from '@/api/plan';

export interface IInstance {
  show: () => void;
  hide: () => void;
}

const visible = ref<boolean>(false);
const model = reactive<PlanCalculateParam>({
  frequencyType: 4,
  frequency: 1,
  person: 0,
  minute: 0,
  bitrate: 1200,
});
const totalNeedBandwidth = ref(0);
const resultLoading = ref(false);

watch(
  model,
  debounce(() => {
    const values = Object.values(model);
    if (values.every(item => ![undefined, null, ''].includes(item as any))) {
      calculate();
    }
  }, 500),
  {
    deep: true,
  },
);

const calculate = async () => {
  resultLoading.value = true;
  const timeRange = model.frequencyType === 1 ? 1 : model.frequencyType === 2 ? 4 : 30;
  totalNeedBandwidth.value = Math.ceil(
    timeRange *
      (model.frequency || 0) *
      (model.person || 0) *
      ((model.minute || 0) * 60) *
      (model.bitrate / 8 / 1024 / 1024),
  );
  setTimeout(() => {
    resultLoading.value = false;
  }, 500);
};

function show() {
  Object.assign(model, {
    frequencyType: 4,
    frequency: 1,
    person: 100,
    minute: 60,
    bitrate: 3500,
  });
  visible.value = true;
}

function hide() {
  visible.value = false;
}

defineExpose({
  show,
  hide,
});
</script>

<template>
  <a-modal
    v-model:visible="visible"
    width="520px"
    :footer="null"
    wrapClassName="modal-setting-bandwidth-calc-confirm g-noborder-header-modal g-notoppadding-modal"
  >
    <template #title>
      <div class="setting-plans-modal-title">
        <i></i>
        Bandwidth Calculator
      </div>
    </template>
    <a-spin :spinning="resultLoading">
      <div class="calculator-wrap">
        <div class="calculator-usage">
          <div class="calculator-wrap-title">Your Usage</div>
          <div class="calc-text">How often will your content be streamed?</div>
          <div class="calc-fitst">
            <div class="calc-data-range">
              <a-select v-model:value="model.frequencyType" style="width: 100%">
                <a-select-option :value="1">Monthly</a-select-option>
                <a-select-option :value="2">Weekly</a-select-option>
                <a-select-option :value="4">Daily</a-select-option>
              </a-select>
            </div>
            <div class="calc-data-frequency">
              <a-input-number :min="1" v-model:value="model.frequency" style="width: 120px" />
              <span class="ant-form-text">Occurences</span>
            </div>
          </div>
          <div class="calc-text">How many viewers will watch it?</div>
          <div class="calc-second">
            <div class="calc-data-people">
              <a-input-number
                :min="1"
                :max="1000000000"
                style="width: 160px"
                v-model:value="model.person"
              />
              <span class="ant-form-text">Viewers</span>
            </div>
          </div>
          <div class="calc-text">How many minutes will they watch?</div>
          <div class="calc-second">
            <a-input-number
              :min="1"
              :max="1000000000"
              style="width: 160px"
              v-model:value="model.minute"
            />
            <span class="ant-form-text">Mins</span>
          </div>
          <div class="fingle-line"></div>
          <div class="calculator-usage-msg">
            <p class="calculator-usage-title">Total needed:</p>
            <div>
              <div class="calculator-usage-total">
                <i>{{ totalNeedBandwidth }}</i>
                <span>GB / per month</span>
              </div>
            </div>
          </div>
          <div class="calculator-result-tip">
            <a-alert class="bonding-tip" type="warning">
              <template #message>
                Please note: Actual bandwidth usage may exceed the theoretical values by
                approximately 10% or so.
              </template>
            </a-alert>
          </div>
        </div>
      </div>
    </a-spin>
  </a-modal>
</template>

<style lang="scss">
.modal-setting-bandwidth-calc-confirm {
  .ant-modal-body {
    padding: 0 20px 20px !important;
  }

  .calculator-wrap {
    display: flex;
  }

  .calculator-wrap-title {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    color: #4e5969;
  }

  .setting-plans-modal-title {
    display: flex;
    align-items: center;

    i {
      display: block;
      width: 20px;
      height: 20px;
      margin-right: 5px;
      background: url(@/assets/images/setting/icon-plans-calc-modal.png) no-repeat;
      background-size: 100%;
    }
  }

  .calculator-usage {
    flex: 1;
  }

  .calculator-usage {
    .calc-text {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 16px;
      color: #4e5969;
    }

    .ant-form-text {
      margin-left: 10px;
      font-size: 14px;
      color: #4e5969;
    }

    .calc-fitst {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .calc-data-range {
        width: 160px;
        margin-right: 20px;
      }

      .calc-data-frequency {
        display: flex;
        align-items: center;
        width: 210px;
      }
    }

    .calc-second {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }

    .calc-third {
      display: flex;
      align-items: center;
      margin-bottom: 32px;
    }

    .fingle-line {
      width: calc(100% - 20px);
      height: 1px;
      margin: 30px 0 12px;
      box-shadow: inset 0 -1px 0 0 rgba(229, 230, 235, 0.5);
    }

    .calculator-usage-msg {
      display: flex;
      align-items: baseline;
      margin-bottom: 16px;

      .calculator-usage-title {
        margin-right: 20px;
        font-size: 14px;
        font-weight: 600;
        color: #1d2129;
      }

      .calculator-usage-total {
        i {
          margin-right: 5px;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          color: #ff0043;
        }

        span {
          font-size: 12px;
          color: #4e5969;
        }
      }
    }

    .calculator-result-tip {
      width: 100%;

      .bonding-tip {
        padding: 16px;
        font-size: 14px;
        line-height: 16px;
        color: #333;
      }
    }
  }
}
</style>
