<script setup lang="ts">
import { useRouter } from 'vue-router';
import * as ConstantRouter from '@/constant/router';
import { INotifications } from '@/api/notifications';
import { PropType } from 'vue';
import DefaultCouponCover from '@/assets/images/coupon/icon-off-modal.png';

const props = defineProps({
  detail: {
    type: Object as PropType<INotifications>,
    require: true,
  },
});
const emits = defineEmits(['close']);
const router = useRouter();
const { detail } = toRefs(props);
const couponDetail = detail!.value!.orgCoupon;
const duration = computed(() => {
  let duration = 0;
  if (couponDetail && couponDetail && couponDetail.expireTime) {
    duration =
      couponDetail.expireTime > new Date().getTime()
        ? couponDetail.expireTime - new Date().getTime()
        : 0;
  }
  return duration;
});
const couponRate = computed(() => {
  if (couponDetail && couponDetail && couponDetail.rate) {
    return couponDetail.rate;
  }
  return '';
});
const conditionsList = computed(() => {
  return (couponDetail.ruleContent || '').split('\n');
});

function sure() {
  emits('close');
  router.push({
    name: ConstantRouter.PLANS,
  });
}
</script>

<template>
  <div class="has-coupon">
    <p class="has-coupon-title">{{ detail.title }}</p>
    <div class="coupon-content-box">
      <div
        class="has-coupon-msg"
        :style="{
          background: detail.coverUrl
            ? `url(${detail.coverUrl}) no-repeat`
            : `url(${DefaultCouponCover}) no-repeat`,
          backgroundSize: '100%',
        }"
      >
        <div class="coupon-off">
          <span class="key">{{ couponRate ? `${100 - couponRate}%` : '--' }}</span>
          <span class="value">OFF</span>
        </div>
        <div class="coupon-cout-down">
          <van-count-down :time="duration">
            <template #default="timeData">
              <div class="count-down-inner">
                <div class="time-block">
                  <div class="player-block">
                    {{ timeData.days < 10 ? `0${timeData.days}` : timeData.days }}
                  </div>
                  <div class="player-colon">Days</div>
                </div>
                <div class="icon-colon">:</div>
                <div class="time-block">
                  <div class="player-block">
                    {{ timeData.hours < 10 ? `0${timeData.hours}` : timeData.hours }}
                  </div>
                  <div class="player-colon">Hours</div>
                </div>
                <div class="icon-colon">:</div>
                <div class="time-block">
                  <div class="player-block">
                    {{ timeData.minutes < 10 ? `0${timeData.minutes}` : timeData.minutes }}
                  </div>
                  <div class="player-colon">Mins</div>
                </div>
                <div class="icon-colon">:</div>
                <div class="time-block">
                  <div class="player-block">
                    {{ timeData.seconds < 10 ? `0${timeData.seconds}` : timeData.seconds }}
                  </div>
                  <div class="player-colon">Secs</div>
                </div>
              </div>
            </template>
          </van-count-down>
        </div>
      </div>
      <p class="has-coupon-sub-title">
        {{ couponDetail?.describe }}
      </p>
      <div class="coupon-conditions">
        <div class="conditions-title">Terms & Conditions</div>
        <div class="conditions-list">
          <div class="conditions-item" v-for="item in conditionsList" :key="item">
            <i></i>
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <div class="btn-box">
      <a-button class="has-coupon-sure" @click="sure" type="primary">{{
        detail.btnText || 'CLAIM THE OFFER'
      }}</a-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.has-coupon {
  p {
    margin: 0;
  }

  img {
    display: block;
    width: 100%;
  }

  .has-coupon-title {
    margin-bottom: 20px !important;
    font-size: 22px;
    font-weight: 500;
    line-height: 25px;
    color: #1d2129;
  }

  .coupon-content-box {
    height: 410px;
    overflow-y: auto;
  }

  .has-coupon-msg {
    width: 100%;
    height: 270px;
    padding-top: 35px;
    margin-bottom: 20px;

    .coupon-off {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 424px;
      height: 110px;
      margin: 0 auto;
      font-family: Montserrat-Bold;
      font-size: 90px;
      font-weight: 800;
      line-height: 110px;
      color: #fff;
    }

    .coupon-cout-down {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 46px;

      .count-down-inner {
        display: flex;
        align-items: center;

        .time-block {
          .player-block {
            width: 48px;
            height: 48px;
            margin-bottom: 2px;
            font-size: 28px;
            font-weight: bold;
            line-height: 48px;
            color: #fff;
            text-align: center;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 6px;
          }

          .player-colon {
            width: 48px;
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            color: rgba(255, 255, 255, 0.69);
            text-align: center;
          }
        }

        .icon-colon {
          width: 8px;
          height: 28px;
          margin: -20px 6px 0;
          font-size: 28px;
          font-weight: 600;
          line-height: 28px;
          color: #fff;
        }
      }
    }
  }

  .has-coupon-sub-title {
    margin-top: 15px;
    margin-bottom: 15px !important;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    color: #1d2129;
  }

  .coupon-conditions {
    margin-bottom: 30px !important;

    .conditions-title {
      margin-bottom: 15px !important;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      color: #4e5969;
    }

    .conditions-list {
      .conditions-item {
        display: flex;
        align-items: center;
        margin-bottom: 15px !important;
        font-size: 16px;
        color: #4e5969;

        i {
          display: block;
          width: 20px;
          height: 20px;
          margin-right: 10px;
          background: url(@/assets/images/coupon/icon-condition.png) no-repeat;
          background-size: 100%;
        }
      }
    }
  }

  .btn-box {
    margin-top: 20px;
    margin-bottom: -4px;
    text-align: right;

    button {
      min-width: 120px;
      padding: 0 15px;
    }
  }
}
</style>
