export enum PermissionEnum {
  /** 七天退款保证 */
  SevenDayMoneyBackGuarantee = 1,
  /** 免费试用(全权限开放) */
  // FreeTrialAvailable = 2,
  /** 不限观众数量 */
  UnlimitedViewer = 3,
  /** 视频分辨率(480 720 1080) */
  VideoResolution = 4,
  /** 最大直播时长，如果是媒体库视频，只允许播放到最大时长，回放不管，是BUG*/
  MaximumEventDuration = 6,
  /** 可以转码 */
  CloudTranscoding = 8,
  /** 可同时直播数量 */
  ConcurrentEvents = 9,
  /** 模拟直播（媒体库直播，去掉Re-event选择项） */
  SimulatedLive = 10,
  /** 观众人时（超过播放流量后观看端黑屏并给提示） */
  ViewerHoursOrMonth = 11,
  /** 分发数量合计 */
  ConcurrentStreamDestinations = 12,
  /** 推流到你的网站（没有该权限禁止embed嵌入，并禁止观看端Link展示）以及社交分享隐藏 */
  StreamToYourWebsite = 13,
  /** 推流到Facebook（控制数量 合计不超过套餐数量） */
  StreamToSocialPlatforms = 14,
  /** 内存控制 */
  Storage = 18,
  /** 下载导出 */
  DownloadingAndExporting = 19,
  /** 自动生成回放 */
  AutomaticRecording = 21,
  /** 回放剪辑 */
  Triming = 22,
  /** 回放替换 */
  EventReplacements = 23,
  /** 高光剪辑 */
  HighlightClipping = 24,
  /** 接入YoloBox源(接入源，合计不能超过套餐数量) */
  YoloBoxOrProOrMini = 26,
  /** 接入任意RTMP源(接入源，合计不能超过套餐数量) */
  AnyRTMPSource = 27,
  /** 流诊断 */
  StreamDiagnostics = 28,
  /** 实时查看分析 */
  RealTimeViewerAnalytics = 29,
  /** 查看者参与状态（reprot --> When Viewers Watch） */
  ViewerEngagementStates = 30,
  /** 观众分析(（reprot --> How Viewers Watch)*/
  ViewerInsights = 31,
  /** 地理分析（reprot --> Where Viewers Watch) */
  GeoAnalytics = 32,
  SocialSharing = 33,
  /** 观看端权限，直播中看回放 */
  LiveDVR = 34,
  /** 图层叠加，Overlay */
  GraphicOverlays = 36,
  /** 自动生成字幕 */
  AutomatedCaptioning = 37,
  /** 视频标记点 */
  VideoMarkers = 39,
  /** 白标嵌入（嵌入时没有yololiv LOGO） */
  WhiteLabeledEmbed = 40,
  /** 高fps（60） */
  HighFrameRate = 41,
  /** 预告片 */
  PreEventVideo = 43,
  /** 密码保护 */
  PasswordProtection = 45,
  /** 打赏 */
  Donations = 47,
  /** 24小时直播 */
  AlwaysLive = 50,
  /** 是否可嵌入 */
  Embed = 51,
  /** 低延时 */
  LowLatency = 52,
  /** 低延播 */
  ASP = 53,
  /** 子菜单 */
  User = 54,
  /** 谷歌登录 */
  SocialLogin = 55,
  /** 封面设置 */
  Thumbnail = 56,
  /** 预约 */
  SocialScheduling = 57,
  /** 自适应码流 */
  AdaptiveResolutionPlayback = 58,
  /** 评论 */
  Chat = 59,
  /** CDN */
  MultiCDN = 60,
  /** 备份流 */
  BackupStream = 61,
  /** 云剪辑 */
  YoloCut = 62,
  /** URL图层 */
  OverlayLab = 63,
  /** 多网聚合 */
  MultiNetworkBonding = 64,
  /** 观看端设置 */
  AppearenceSettings = 65,
}
