import { defineStore } from 'pinia';
import { queryInfo, updateInfo } from '@/api/user';
import { IUserInfo } from '@/types/account';

interface IError {
  message: string;
  code: number;
}

const useStore = defineStore('user', {
  state: (): {
    loading: boolean;
    error: Nullable<IError>;
    detail: Nullable<IUserInfo>;
  } => {
    return {
      loading: true,
      error: null,
      detail: null,
    };
  },
  getters: {},
  actions: {
    async setUserInfo(userData: IUserInfo) {
      // data.isExpiredSubscription = true;
      this.detail = userData;
      this.loading = false;
    },
    async fetchUserInfo() {
      try {
        const { data } = await queryInfo();
        // MOCK 模拟套餐到期
        // data.isExpiredSubscription = true;
        this.detail = data;
        return true;
      } catch (error) {
        this.error = error as IError;
        return false;
      } finally {
        this.loading = false;
      }
    },
    async updateUserInfo(params: any) {
      await updateInfo(params);
      await this.fetchUserInfo();
    },
    async updateOperateStatus() {
      if ((this.detail?.operateStatus || 1) <= 3) {
        return await this.fetchUserInfo();
      }
      return false;
    },
    updateAvatar(data: string) {
      this.detail!.avatar = data;
    },
  },
});

export default useStore;
