import * as ConstantLabel from '@/constant/label';
import * as ConstantRouter from '@/constant/router';
import loadable from '@/utils/loadable';

export const types = [
  { key: 'videos', name: 'Videos' },
  { key: 'audios', name: 'Audios' },
  { key: 'images', name: 'Images' },
  { key: 'documents', name: 'Documents' },
  // { key: 'live-event', name: 'Live-Event' },
  { key: 'overlays', name: 'Overlays' },
  // { key: 'highlights', name: 'Highlights' },
  // { key: 'recordings', name: 'Recordings' },
];

const route = {
  path: 'media/:type',
  name: ConstantRouter.MEDIA,
  component: loadable(() => import(/* webpackChunkName: "media" */ '@/pages/media/index.vue')),
  meta: {
    name: ConstantLabel.MEDIA,
    showUploadBox: true,
  },
};

export default route;
