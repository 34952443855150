import * as ConstantRouter from '@/constant/router';
import * as ConstantLabel from '@/constant/label';
import loadable from '@/utils/loadable';

const route = {
  path: 'themes/:id',
  name: ConstantRouter.THEMES_DETAIL,
  component: loadable(
    () => import(/* webpackChunkName: "media" */ '@/pages/themes/detail/index.vue'),
  ),
  meta: {
    name: ConstantLabel.THEMES_DETAIL,
  },
};

export default route;
