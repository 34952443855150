import request from '@/utils/request';

export interface TokenData {
  /** 桶名称 */
  bucket: string;
  /** 所在区域 */
  region: string;
  /** 临时secretKey */
  tmpSecretKey: string;
  /** 临时secretId */
  tmpSecretId: string;
  /** 会话token */
  sessionToken: string;
  /** 过期时间 */
  expiredTime: number;
  /** 请求id */
  requestId: string;
  /** 过期时间 */
  expiration: string;
  /** 起始时间 */
  startTime: number;
  /** 目录 */
  dir: string;
  bizId: string;
  materialId: string;
  signature: string;
}
/**
 *  获取cos直传token
 */
export const getCosToken = (data: { materialType: number }) => {
  return request.get<TokenData>('/api/material/tmpToken', data);
};

/**
 *  获取vod直传token
 */
export const getVodToken = (data: { bizId: string; bizType: string }) => {
  return request.get<TokenData>('/api/material/getVodSignature', data);
};
