import { defineStore } from 'pinia';

const usePageStore = defineStore('page', {
  state: (): {
    home: boolean;
  } => {
    return {
      home: false,
    };
  },
  actions: {
    homeUpdate(data: boolean) {
      this.home = data;
    },
  },
});

export default usePageStore;
