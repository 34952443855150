import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { CosUploaderService } from '@yunxi/snow-upload';
import { message, Modal } from 'ant-design-vue';
import dayjs, { Dayjs } from 'dayjs';
import html2canvas from 'html2canvas';
import { createVNode } from 'vue';

import { getCosToken } from '@/api/common';
import { saveImageMediaUrl } from '@/api/media';

export function saveFile(pic: any, name: string) {
  const elink = document.createElement('a');
  elink.download = name;
  elink.style.display = 'none';
  elink.href = pic;
  document.body.appendChild(elink);
  elink.click();
  URL.revokeObjectURL(elink.href);
  document.body.removeChild(elink);
}

export function exportFile(blobData: Blob, name: string) {
  const fileReader = new FileReader();
  fileReader.readAsDataURL(blobData);
  fileReader.onload = event => {
    saveFile(event.target?.result, name);
  };
}

export function getFile(path: string) {
  return path.substring(path.lastIndexOf('/') + 1);
}

export function secondToDate(result: number, { s } = { s: true }) {
  const hours = Math.floor(result / 3600);
  const minutes = Math.floor((result / 60) % 60);
  const seconds = Math.floor(result % 60);
  const dateStr =
    (hours < 10 ? '0' + hours : hours) +
    ':' +
    (minutes < 10 ? '0' + minutes : minutes) +
    (s ? ':' + (seconds < 10 ? '0' + seconds : seconds) : '');

  return dateStr;
}

export const base64toFile = (dataurl: any, filename = 'file') => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  // const suffix = mime.split('/')[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], `${filename}`, {
    type: mime,
  });
};

export const getMax = (data: Array<any>, attr: string) => {
  const arr: Array<any> = [];
  for (let i = 0; i < data.length; i++) {
    arr.push(data[i][attr]);
  }
  return Math.max(...arr) / 1000;
};

export const getcombineTime = (date: Dayjs, time: Dayjs): Dayjs => {
  const year = date.year();
  const month = date.month();
  const day = date.date();
  const h = time.hour();
  const m = time.minute();
  const s = time.second();
  const newDate = new Date();
  newDate.setFullYear(year);
  newDate.setMonth(month, 1);
  newDate.setDate(day);
  newDate.setHours(h);
  newDate.setMinutes(m);
  newDate.setSeconds(s);
  newDate.setMilliseconds(0);

  return dayjs(newDate);
  // const fommatDate = date.format('YYYY-MM-DD');
  // const formatTime = time.format('HH:mm:ss');
  // return dayjs(`${fommatDate} ${formatTime}`);
};

export const millToSecond = (millisecond: number) => {
  return Math.floor(millisecond / 1000);
};

export const formatTime = (time: string | number | dayjs.Dayjs | Date) => {
  return dayjs(time).format('MMM D, YYYY hh:mm A');
};

export function shareFacebook(url: string) {
  // 兼容mac14.0.2版本，猛哥电脑加载SDK失败
  if (!document.getElementsByTagName('script')[0].attributes.crossorigin) {
    const js = document.createElement('script') as HTMLScriptElement | any;
    js.crossOrigin = 'anonymous';
    js.charset = 'utf-8';
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    const fjs = document.getElementsByTagName('script')[0] as HTMLScriptElement;
    fjs.parentNode!.insertBefore(js, fjs);
    let facebookSharingOK = false;
    let time = 0;
    let timer: any = null;
    timer = setInterval(() => {
      time++;
      if (time >= 5) {
        clearInterval(timer);
        if (!facebookSharingOK) {
          message.error('Network Error');
        }
      }
    }, 1000);
    js.onload = () => {
      facebookSharingOK = true;
      clearInterval(timer);
      if (time >= 5) return;
      try {
        FB.init({
          appId: import.meta.env.VITE_FACEBOOK_ID,
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v13.0',
        });
        FB.ui({
          method: 'share',
          href: url,
        });
      } catch (error) {
        message.error('Network Error');
      }
    };
  } else {
    try {
      FB.init({
        appId: import.meta.env.VITE_FACEBOOK_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v13.0',
      });
      FB.ui({
        method: 'share',
        href: url,
      });
    } catch (error) {
      message.error('Network Error');
    }
  }
}

export function shareTwitter({ url, description }: { url: string; description: string }) {
  const params = [
    ['url', url],
    ['text', description],
  ];
  const query = params.map(item => item.join('=')).join('&');
  window.open(`https://twitter.com/intent/tweet?${query}`);
}

export function ceilTime(time: Dayjs) {
  const minute = time.minute();
  const rem = minute % 10;
  let diff = 0;
  if (rem < 5) {
    diff = 10 - rem;
  } else {
    diff = 15 - rem;
  }
  return time.add(diff, 'm');
}

export function getRemTimeText(startTime: Dayjs, endTime: Dayjs) {
  const diff = +endTime - +startTime;
  const isSameDay = startTime.isSame(endTime, 'd');
  const seconds = dayjs.duration(diff).seconds();
  return `${secondToDate(seconds)}${isSameDay ? '' : ' - Following Day'}`;
}

//获取图片宽高
export const getImageSize = (url: string) => {
  return new Promise<{ width: number; height: number }>(resolve => {
    // 记录当前时间戳
    const start_time = new Date().getTime();
    // 图片地址 后面加时间戳是为了避免缓存
    const img_url = url.indexOf('blob:') > -1 ? url : url + '?' + start_time;
    // 创建对象
    const img = new Image();
    // 改变图片的src
    img.src = img_url;
    // 定时执行获取宽高
    const check = function () {
      // 只要任何一方大于0
      // 表示已经服务器已经返回宽高
      if (img.width > 0 || img.height > 0) {
        resolve({
          width: img.width,
          height: img.height,
        });
        clearInterval(set);
      }
    };
    const set = setInterval(check, 40);
  });
};

//数组移动
export function arrayUp(arr: any[], index: number) {
  if (index !== 0) {
    swapItems(arr, index, index - 1);
  }
}
export function arrayDown(arr: any[], index: number) {
  if (index !== arr.length - 1) {
    swapItems(arr, index, index + 1);
  }
}
function swapItems(arr: any[], index1: number, index2: number) {
  arr[index1] = arr.splice(index2, 1, arr[index1])[0];
}

/**
 * [链接分享常见问题](https://developers.facebook.com/docs/sharing/webmasters/faq?locale=zh_CN)
 */
export function facebookDebug(q: string) {
  const ifrm = document.createElement('iframe');
  ifrm.src = q;
  ifrm.style.display = 'none';
  document.body.appendChild(ifrm);
  document.body.removeChild(ifrm);
}

/**
 * 文件上传
 */
const uploadFinal = (file: File, materialId: string, res: any) => {
  return new Promise<string>(resolve => {
    CosUploaderService.upload({
      file,
      filename: `${materialId}.png`,
      // eslint-disable-next-line
      onProgress: () => {},
      onSuccess: async (_: unknown, data: { Location: string }) => {
        const url = data ? `${res.dir}${materialId}.png` : '';
        if (url) {
          resolve(url);
        }
      },
    });
  });
};

export const uploadFile = async (file: File, type: string, name?: string) => {
  const res: any = await CosUploaderService.getToken(getCosToken, {
    type: type,
  });
  // 创建 sdk 实例
  await CosUploaderService.initInstance();
  let url = await uploadFinal(file, res.materialId, res);
  if (file.size > 1024 * 1024 && file.size <= 10 * 1024 * 1024) {
    url += '?imageMogr2/thumbnail/!30p';
  } else if (file.size > 10 * 1024 * 1024 && file.size <= 15 * 1024 * 1024) {
    url += '?imageMogr2/thumbnail/!20p';
  } else if (file.size > 15 * 1024 * 1024) {
    url += '?imageMogr2/thumbnail/!10p';
  }
  const materialId = res.materialId;
  const params: any = {
    materialList: [
      {
        url,
        fileName: name ? name : file.name,
        materialId,
        format: 'png',
      },
    ],
    groupId: undefined,
    materialType: type,
  };
  await saveImageMediaUrl(params); //把图层保存到素材库
  return { url, materialId };
};
//html转图片文件（图层和记分板使用）
export const htmlToPng = async (id: string, name: string, type?: string): Promise<File> => {
  const htmlCanvas = await html2canvas(document.querySelector(id)!, {
    allowTaint: true,
    useCORS: true,
    backgroundColor: null,
    scale: 1,
  });
  // console.log(new Date(), '开始base64转文件');
  const imageType = type ? type : 'image/png';
  const file: File = await new Promise(resolve => {
    htmlCanvas.toBlob(blob => {
      const file = new window.File([blob!], name + new Date().getTime() + '');
      resolve(file);
    }, imageType);
  });
  return file;
};
/**
 * 设置子元素自适应宽高比
 * 父元素样式.container { position: relative; }
 * 子元素样式.content { position: absolute; }
 */
export const resizeElement = (
  width: number,
  height: number,
  container: HTMLElement,
  content: HTMLElement,
) => {
  if (container.clientWidth / container.clientHeight > width / height) {
    content.style.height = container.clientHeight + 'px';
    content.style.width = (container.clientHeight / height) * width + 'px';
    content.style.top = '0px';
    content.style.left = (container.clientWidth - content.clientWidth) / 2 + 'px';
  } else {
    content.style.width = container.clientWidth + 'px';
    content.style.height = (container.clientWidth / width) * height + 'px';
    content.style.left = '0px';
    content.style.top = (container.clientHeight - content.clientHeight) / 2 + 'px';
  }
};

export const formatCapacity = (capacity: number) => {
  capacity = capacity || 0;
  if (capacity < 1024) {
    return Math.floor(capacity * 10) / 10 + 'GB'; // capacity.toFixed(1) + 'GB';
  }
  return Math.floor((capacity / 1024) * 10) / 10 + 'TB'; // (capacity / 1024).toFixed(1) + 'TB';
};

export const newWindow = (url: string) => {
  return new Promise(resolve => {
    const w = window.open('', '_blank');
    if (w) {
      w.location.href = url;
      resolve(true);
      return;
    }
    Modal.confirm({
      title: 'The popup window has been blocked by your browser.',
      icon: createVNode(ExclamationCircleOutlined),
      okText: 'Click to try again',
      zIndex: 1019,
      onOk() {
        resolve(true);
        window.open(url, '_blank');
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onCancel() {},
    });
  });
};

export function formatDuration(
  val: number,
  { fh, fm, fs, zero, paddingZero, showLast } = {
    fh: ':',
    fm: ':',
    fs: '',
    zero: true,
    paddingZero: true,
    showLast: true,
  },
) {
  if (val < 60) {
    const second_str = val < 10 ? `${paddingZero ? '0' : ''}${val}` : val;
    return `${
      zero ? `${paddingZero ? '0' : ''}0${fh}${paddingZero ? '0' : ''}0${fm}` : ''
    }${second_str}${fs}`.slice(0, showLast ? undefined : -1);
  } else {
    const min_total = Math.floor(val / 60); // 分钟
    const min_total_str = min_total < 10 ? `0${min_total}` : min_total;
    const sec = Math.floor(val % 60); // 余秒
    const sec_str = sec < 10 ? `0${sec}` : sec;
    if (min_total < 60) {
      return (
        (zero ? `${paddingZero ? '0' : ''}0` + fh : '') +
        (min_total_str + fm) +
        (sec ? sec_str + fs : zero ? `${paddingZero ? '0' : ''}0` + fs : '')
      ).slice(0, showLast ? undefined : -1);
    } else {
      const hour_total = Math.floor(min_total / 60); // 小时数
      const hour_total_str =
        hour_total < 10 ? `${paddingZero ? '0' : ''}${hour_total}` : hour_total;
      const min = Math.floor(min_total % 60); // 余分钟
      const min_str = min < 10 ? `${paddingZero ? '0' : ''}${min}` : min;
      return (
        hour_total_str +
        fh +
        (min ? min_str + fm : zero ? `${paddingZero ? '0' : ''}0` + fm : '') +
        (sec ? sec_str + fs : zero ? `${paddingZero ? '0' : ''}0` + fs : '')
      ).slice(0, showLast ? undefined : -1);
    }
  }
}

export const notRepeat = (list: any[], originList: { commentId: any }[]) => {
  const seqList = originList.map(({ commentId }) => commentId);
  return list.filter(item => !seqList.includes(item.commentId));
};

export const insertAfter = (newElement: HTMLElement, targetElement: HTMLElement) => {
  const parent = targetElement.parentNode;
  if (parent?.lastChild == targetElement) {
    parent.appendChild(newElement);
  } else {
    parent?.insertBefore(newElement, targetElement.nextSibling);
  }
};
