<script setup lang="ts">
import { StarFilled } from '@ant-design/icons-vue';
import { Modal } from 'ant-design-vue';
import { useRequest } from 'vue-request';

import { getPlanFree } from '@/api/plan';
import useCurrentPlan from '@/hooks/useCurrentPlan';
import useNewGuideStore from '@/store/newGuide';
import usePermissionStore from '@/store/permission';

const visible = ref<boolean>(false);
const { updateCurrentPlan } = useCurrentPlan();
const permission = usePermissionStore();
const newGuideStore = useNewGuideStore();
const getLoading = ref(false);

const { run: getPlanFreeRun, error: getPlanFreeError } = useRequest(getPlanFree, {
  manual: true,
  formatResult(res) {
    return res.data;
  },
});

async function getFreeTrial() {
  getLoading.value = true;
  await getPlanFreeRun();
  if (getPlanFreeError.value) {
    getLoading.value = false;
    return false;
  }
  await updateCurrentPlan();
  await permission.fetchPermissionList();
  newGuideStore.updateGuideInfo({ doneTrial: 1 });
  getLoading.value = false;
  Modal.success({
    content: '14 Days Free Trial activated successfully!',
    okText: 'OK',
    type: 'success',
    title: 'Prompt',
    width: '520px',
    wrapClassName: 'g-modal-comfirm-20 g-no-cancel-modal',
  });
  hide();
}

function show() {
  visible.value = true;
}

function hide() {
  visible.value = false;
}

defineExpose({
  show,
  hide,
});
</script>

<template>
  <a-modal
    v-model:visible="visible"
    wrapClassName="modal-free-trial g-noborder-header-modal"
    :width="700"
    :closable="false"
  >
    <div class="free-trial-content">
      <div class="free-title">Get 14 Days Free Trial</div>
      <div class="free-sub-title">During Your Free Trial</div>
      <div class="free-highlight">
        <div class="highlight-item">
          <StarFilled></StarFilled>
          Stream free for 14 days — no credit card required.
        </div>
        <div class="highlight-item">
          <StarFilled></StarFilled>
          Stream to destinations like Facebook Live, YouTube Live, Twitch live.
        </div>
        <div class="highlight-item">
          <StarFilled></StarFilled>
          Simulated live (upload local video)
        </div>
        <div class="highlight-item">
          <StarFilled></StarFilled>
          And more…
        </div>
      </div>
    </div>

    <template #footer>
      <a-button type="link" @click="hide"> I want to get it later </a-button>
      <a-button type="primary" @click="getFreeTrial" :loading="getLoading">Get It Now</a-button>
    </template>
  </a-modal>
</template>
<style lang="scss">
.modal-free-trial {
  .free-trial-content {
    .free-title {
      margin-bottom: 15px;
      font-size: 32px;
      font-weight: 600;
      line-height: 38px;
      color: #1d2129;
      text-align: center;
    }

    .free-sub-title {
      margin-bottom: 15px;
      font-size: 24px;
      font-weight: 600;
      line-height: 38px;
      color: #1d2129;
    }

    .free-highlight {
      .highlight-item {
        margin-bottom: 16px;

        .anticon {
          margin-right: 10px;
          color: #ff0043;
        }

        font-size: 20px;
      }
    }
  }

  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
