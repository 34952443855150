import request from '@/utils/request';
import { ICouponDetail } from '@/api/coupon';

export enum NOTIFIS {
  COMMON_NOTIFI = 1, // 普通消息
  COUPON_NOTIFI = 2, // 优惠券
  ABOUT_EXPIRE_NOTIFI = 3, // 套餐临近到期
  HAS_EXPIRED_NOTIFI = 4, // 套餐到期
}

export interface INotifications {
  btnText: string;
  coverUrl: string;
  description: string;
  link: string;
  messageId: string;
  startTime: string;
  title: string;
  scene: NOTIFIS;
  orgCoupon: ICouponDetail;
}

/**
 *  获取通知消息走马灯、未读标记
 */
export const getAlertListBroadcast = () => {
  return request.get<{ broadcasts: Array<INotifications>; hasUnread: boolean }>(
    `/api/message/info`,
  );
};

/**
 *  获取通知消息分页列表
 */
export const getAlertListPage = (data: { pageNo: number; pageSize: number; type: 1 }) => {
  return request.get<{ items: Array<INotifications>; paginator: { totalCount: number } }>(
    `/api/message/page`,
    data,
  );
};

/**
 *  获取弹窗通知消息和横幅消息
 */
export const getAlertAndBanner = (data: { booleanLogin: 1 | 0 }) => {
  return request.get<{
    alertData: Array<INotifications>;
    bannerData: Array<INotifications>;
  }>(`/api/message/union-data`, data);
};
