import { RouteRecordRaw } from 'vue-router';

import RouterHolder from '@/components/router-holder/index.vue';
import * as ConstantLabel from '@/constant/label';
import * as ConstantRouter from '@/constant/router';
import loadable from '@/utils/loadable';

const route: RouteRecordRaw = {
  path: '/overlay',
  name: ConstantRouter.URL_OVERLAY,
  meta: {
    name: ConstantLabel.URL_OVERLAY,
  },
  component: RouterHolder,
  redirect: { name: ConstantRouter.OVERLAYS_LIBRARY },
  children: [
    {
      path: 'overlays-library',
      name: ConstantRouter.OVERLAYS_LIBRARY,
      meta: {
        name: ConstantLabel.OVERLAYS_LIBRARY,
      },
      component: RouterHolder,
      redirect: { name: ConstantRouter.OVERLAYS_LIBRARY_LIST },
      children: [
        {
          path: 'overviews',
          meta: {},
          name: ConstantRouter.OVERLAYS_LIBRARY_OVERVIEWS,
          component: loadable(
            () =>
              import(
                /* webpackChunkName: "overviews" */ '@/overlay/pages/overlays-library/index.vue'
              ),
          ),
        },
        {
          path: 'overlay-list',
          meta: {},
          name: ConstantRouter.OVERLAYS_LIBRARY_LIST,
          component: loadable(
            () =>
              import(
                /* webpackChunkName: "overlay-list" */ '@/overlay/pages/overlays-library/overlay-list.vue'
              ),
          ),
        },
      ],
    },
    {
      path: 'my-overlays',
      name: ConstantRouter.MY_OVERLAYS,
      meta: {
        name: ConstantLabel.MY_OVERLAYS,
      },
      component: loadable(
        () => import(/* webpackChunkName: "my-overlays" */ '@/overlay/pages/my-overlays/index.vue'),
      ),
    },
    {
      path: 'my-compositions',
      name: ConstantRouter.MY_COMPOSITIONS,
      meta: {
        name: ConstantLabel.MY_COMPOSITIONS,
      },
      component: loadable(
        () =>
          import(
            /* webpackChunkName: "my-compositions" */ '@/overlay/pages/my-compositions/index.vue'
          ),
      ),
    },
    {
      path: 'third-overlays',
      name: ConstantRouter.MY_THIRD_OVERLAYS,
      meta: {
        name: ConstantLabel.MY_THIRD_OVERLAYS,
      },
      component: loadable(
        () =>
          import(/* webpackChunkName: "my-overlays" */ '@/overlay/pages/third-overlays/index.vue'),
      ),
    },
  ],
};

export default route;
