/** 宽带储存弹快到期窗提示 */
export const BRANDWIDTH_ABOUT_EXPIRE_TIP_SHOWED = 'brandwidth-about-expire-tip-showed';

/** 宽带储存弹到期窗提示 */
export const BRANDWIDTH_EXPIRED_TIP_SHOWED = 'brandwidth-about-expired-tip-showed';

/** 套餐到期提醒 */
export const PACKAGE_EXPIRE_TIP_SHOWED = 'package-expire-tip-showed';

/** 套餐快到期提醒 */
export const PACKAGE_ABOUT_EXPIRE_TIP_SHOWED = 'package-about-expire-tip-showed';

/** Facebook Group 指引弹窗 */
export const FACEBOOKGROUP_GUIDE_SHOWED = 'facebookgroup-guide-showed';

/** 优惠券引用提醒 */
export const HAS_COUPON = 'has-coupon';
