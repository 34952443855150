import { RouteParamsRaw } from 'vue-router';

import * as ConstantLabel from '@/constant/label';
import * as ConstantRouter from '@/constant/router';

export interface IMenuItem {
  title: string;
  key: string;
  icon?: string;
  activeIcon?: string;
  initRouteParams?: RouteParamsRaw;
  children?: IMenuItem[];
  hideChildrenInMenu?: boolean;
  splitLine?: boolean; // 分割线
  newFeature?: boolean; // 新功能
  thirdUrl?: string; // 打开第三方新页面
}

const menu: Array<IMenuItem> = [
  {
    title: ConstantLabel.BROADCASTS,
    key: ConstantRouter.BROADCASTS,
    icon: 'g-icon-broadcasts',
    activeIcon: 'g-icon-broadcasts-active',
  },
  {
    title: ConstantLabel.EMBED,
    key: ConstantRouter.EMBED,
    icon: 'g-icon-embed',
    activeIcon: 'g-icon-embed-active',
    // newFeature: true,
  },
  {
    title: ConstantLabel.MEDIA,
    key: ConstantRouter.MEDIA,
    initRouteParams: { type: 'videos' },
    icon: 'g-icon-media',
    activeIcon: 'g-icon-media-active',
  },
  {
    title: ConstantLabel.REPORTS,
    key: ConstantRouter.REPORTS,
    icon: 'g-icon-reports',
    activeIcon: 'g-icon-reports-active',
  },
  {
    title: ConstantLabel.SOURCES,
    key: ConstantRouter.SOURCES,
    icon: 'g-icon-source',
    activeIcon: 'g-icon-source-active',
  },
  {
    title: ConstantLabel.DESTINATIONS,
    key: ConstantRouter.DESTINATIONS,
    icon: 'g-icon-dest',
    activeIcon: 'g-icon-dest-active',
  },
  // {
  //   title: ConstantLabel.THEMES,
  //   key: ConstantRouter.THEMES,
  //   icon: 'g-icon-theme',
  //   activeIcon: 'g-icon-theme-active',
  // },
  {
    title: ConstantLabel.YOLOCUT_LIST,
    key: ConstantRouter.YOLOCUT_LIST,
    icon: 'g-icon-yolocut',
    activeIcon: 'g-icon-yolocut-active',
    splitLine: true,
  },
  {
    title: ConstantLabel.URL_OVERLAY,
    key: ConstantRouter.URL_OVERLAY,
    icon: 'g-icon-overlays-app',
    activeIcon: 'g-icon-overlays-app-active',
    children: [
      {
        title: ConstantLabel.OVERLAYS_LIBRARY,
        key: ConstantRouter.OVERLAYS_LIBRARY,
      },
      {
        title: ConstantLabel.MY_OVERLAYS,
        key: ConstantRouter.MY_OVERLAYS,
      },
      {
        title: ConstantLabel.MY_COMPOSITIONS,
        key: ConstantRouter.MY_COMPOSITIONS,
      },
      {
        title: ConstantLabel.MY_THIRD_OVERLAYS,
        key: ConstantRouter.MY_THIRD_OVERLAYS,
        newFeature: true,
      },
    ],
  },
  {
    title: ConstantLabel.WEB_CONTROL,
    key: ConstantRouter.WEB_CONTROL,
    icon: 'g-icon-web-control',
    activeIcon: 'g-icon-web-control-active',
    thirdUrl: import.meta.env.VITE_WEBCONTROLURL,
  },
  {
    title: ConstantLabel.SETTINGS,
    key: ConstantRouter.SETTINGS,
    icon: 'g-icon-setting',
    activeIcon: 'g-icon-setting-active',
  },
  {
    title: ConstantLabel.VIDEO_TUTORIALS,
    key: ConstantRouter.VIDEO_TUTORIALS,
    icon: 'g-icon-tutorials',
    activeIcon: 'g-icon-tutorials-active',
  },
];

export default menu;
