const badge = {
  mounted(el, { value }) {
    el._setElStyleDestory = badge._setElStyle(el);
    el._badgeDestory = badge._badge(el, value);
  },
  unmounted(el) {
    if (el._setElStyleDestory) {
      el._setElStyleDestory();
    }
    if (el._badgeDestory) {
      el._badgeDestory();
    }
  },
  _setElStyle(el) {
    const timer = setTimeout(() => {
      if (!['relative', 'fixed', 'absolute'].includes(window.getComputedStyle(el).position)) {
        el.style.position = 'relative';
      }
    }, 0);
    return function () {
      clearTimeout(timer);
    };
  },
  _badge(el, value = {}) {
    const style = Object.assign(
      {},
      {
        right: '-27px',
        top: '-16px',
        text: 'New',
        backgroundColor: '#FF0043',
        zIndex: 9,
        fontWeight: 600,
      },
      value,
    );
    const badgeDom = document.createElement('span');
    badgeDom.style.cssText =
      'position: absolute; padding: 0px 6px; border-radius: 6px 6px 6px 0; color: #fff; font-size: 20px; transform: scale(0.5)';
    badgeDom.style.backgroundColor = style.backgroundColor;
    badgeDom.style.zIndex = 9;
    badgeDom.style.right = style.right;
    badgeDom.style.top = style.top;
    badgeDom.style.fontWeight = style.fontWeight;
    badgeDom.style.lineHeight = 1.5715;
    if (style.cssText) {
      badgeDom.style.cssText = badgeDom.style.cssText + style.cssText;
    }
    badgeDom.innerHTML = style.text;
    el.append(badgeDom);
    return function () {
      badgeDom.remove();
    };
  },
};

export default badge;
