import request from '@/utils/request';

/**
 *  订阅套餐接口
 */
export const getPaypalInfo = (data: { planId: string }) => {
  return request.post<{ approveUrl: string; subscriptionId: string }>(
    '/api/plan/subscription/paypal',
    data,
  );
};

export interface SubscriptionPaypalInfo {
  approveUrl: string;
  subscriptionId: string;
  bizType?: 1 | 2; // 1：套餐  2：资源
  id?: string;
}

/**
 *  订阅套餐接口，套餐改版后只用于购买流量
 */
export const subscriptionPaypal = (data: {
  bizId: number;
  couponId?: string;
  country: string;
  address?: string;
  zipCode?: string;
  bizType: 1 | 2; // 1套餐 2资源
  dealerId?: string;
}) => {
  return request.post<SubscriptionPaypalInfo>('/api/plan/subscription/paypal', data);
};

export enum PlanSubPayStatusEnum {
  Wait = 1,
  Success = 2,
  Fail = 3,
  Cancel = 4,
}

/**
 *  获取流量包订阅单信息
 */
export const planSubscription = (data: { subscriptionId: string }) => {
  return request.get<{ planSubPayStatus: PlanSubPayStatusEnum }>('/api/plan/subscription', data);
};

export interface ResourceItem {
  /** 当前套餐的总流量 */
  data: number;
  /** 当前套餐的总存储 */
  storage: number;
  /** 当前套餐的已用流量 */
  usedData: number;
  /** 当前套餐的已用存储 */
  usedStorage: number;
  /** 当前套餐的总多网流量 */
  bondingData: number;
  /** 当前套餐的已用多网流量 */
  usedBondingData: number;
}

export interface PlanItem {
  name: string;
  expireDate: string;
  effectDate: string;
  planId: number;
  /** 套餐类型 1 正常套餐 2 无限期使用套餐（试用套餐结束后套餐） 3 注册十四天试用套餐 4 盒子专用套餐 */
  planType: 1 | 2 | 3 | 4;
  data: number; // 原bandwidth，套餐包含流量大小
  storage: number; // 套餐包含内存大小
  bondingData: number; // 多网聚合流量
  nextBillingDate: string; // 自动续订时的下一个账单日
  subscriptionEnabled: boolean; // 是否自动续订中
  price: number;
  payType: 1 | 2 | 3; // 1月付 2季付 3:年付
  canRenew: boolean; // 是否可以Renew
}

export interface PlanDetail {
  planName: string;
  subtitle: string;
  description: string;
  planId: number;
  highlight: string;
  storage: number;
  data: number;
  bondingData: number; // 多网聚合流量
  annuallyPackage: {
    id: number;
    name: string;
    pricePerMonth: number;
    pricePerYear: number;
  };
  monthlyPackage: {
    id: number;
    name: string;
    pricePerMonth: number;
    pricePerYear: number;
  };
  [propName: string]: any;
}

export interface IInvitationCode {
  code: string;
  planId: number;
  dayNum: number;
}

/**
 *  套餐列表
 */
export const getPlanList = () => {
  return request.get<PlanDetail[]>('/api/plan/package-list');
};

/**
 * 获取当前正在使用的套餐
 */
export const getCurrentPlan = () => {
  return request.get<PlanItem>('/api/plan/current-plan');
};

/**
 * 获取用户资源
 */
export const getCurrentResource = () => {
  return request.get<ResourceItem>('/api/plan/current-resource');
};

export interface RecordItem {
  subscriptionId: string;
  name: string;
  price: number;
  actuallyPaid: number;
  // 支付状态: 1待支付；2支付成功；3支付失败；4支付取消
  payStatus: 1 | 2 | 3 | 4;
  expireDate: string;
  createdAt: string;
  type: number;
}

/**
 * 获取历史订购列表
 */
export const getPurchaseRecordsList = (data: { startTime: number }) => {
  return request.get<RecordItem[]>('/api/plan/purchase-records', data);
};

export interface PlanCalculateParam {
  frequencyType: 1 | 2 | 4; // 推流频率 1 Monthly(默认)、2 Weekly 4 Daily
  frequency: number | undefined;
  minute: number | undefined;
  person: number | undefined;
  bitrate: 350 | 800 | 1000 | 1200 | 1500 | 1800 | 2500 | 3500 | 4500 | 7000;
}

/**
 * 计算器
 */
export const planCalculate = (data: PlanCalculateParam) => {
  return request.get<PlanItem>('/api/plan/calculate', data);
};

/**
 * 套餐描述
 */
export interface IFeatureItem {
  dict: any;
  id: number;
  name: string;
  type: 1 | 2;
  tips: string;
}
export const getFeatures = (data: { ids: string }) => {
  return request.get<Array<IFeatureItem>>('/api/plan/list-features', data);
};

/**
 * 折扣优惠
 */
export enum PromoType {
  FIX_PRICE = 1,
  PERCENTAGE_PRICE = 2,
}
export interface IPromoDetail {
  discountAmount: number;
  promotionCodeId: string;
  sceneType: PromoType;
}
export const checkPromotionCode = (data: { code: string }) => {
  return request.post<IPromoDetail>(`/api/plan/check-promotion-code/${data.code}`);
};

/**
 *  新版订阅套餐接口
 */
export const newSubscriptionPaypal = (data: {
  country: string;
  address?: string;
  zipCode?: string;
  id: number;
}) => {
  return request.post<SubscriptionPaypalInfo>('/api/subscription/subscribe', data);
};

/**
 *  新版获取套餐订阅单信息
 */
export const newPlanSubscription = (data: { id: string }) => {
  return request.get<{ id: number; status: PlanSubPayStatusEnum }>(
    '/api/subscription/subscribe-result',
    data,
  );
};

/**
 *  取消自动订阅
 */
export const cancelSubscription = () => {
  return request.post('/api/subscription/cancel');
};

/**
 *  套餐续订
 */
export const subscriptionRenew = (data: { id: number }) => {
  return request.post('/api/subscription/resubscribe', data);
};

/**
 *  获取套餐续订结果
 */
export const getSubscriptionRenewOrder = (data: { id: string }) => {
  return request.get('/api/order/status', data);
};

/**
 *  获取套餐续订结果
 */
export const getPlanById = (data: { id: number }) => {
  return request.get<Array<any>>('/api/package/list-renew-package', data);
};

/**
 *  领取14天试用套餐
 */
export const getPlanFree = () => {
  return request.post('/api/plan/receive-trial-plan');
};

export interface SalePackage {
  id: number;
  name: string;
  payType: 1 | 3; // 1:月付 3：年付
  price: number;
  recommend?: boolean; // 推荐
}

/**
 *  获取可续订的套餐列表
 */
export const getResubscribePackage = (data: { payType: 1 | 3 | undefined }) => {
  return request.get<{ firstBillingDate: string; pricingList: Array<SalePackage> }>(
    '/api/package/list-resubscribe-package',
    data,
  );
};

/**
 *  获取经销商数据
 */
export const getDealerDetail = (data: { dealerId: string }) => {
  return request.get<{ name: string; rate: number }>(`/api/dealer/${data.dealerId}`, data);
};
