import request from '@/utils/request';

export interface IReceipt {
  actualAmount: number;
  address: string;
  discountAmount: number;
  email: string;
  fee: number;
  nickname: string;
  originAmount: number;
  paidAt: string;
  payAccount: string;
  paymentMethod: 1;
  subscriptionId: string;
  zipCode: string;
  packageName: string;
}

export interface IRsourceDetail {
  id: number | string;
  name: string;
  number: number;
  // 原价
  price: number;
  // 实际支付价格
  discountPrice: number;
  resourceType: number;
}

//获取当前使用套餐列表
export const getCurrentPlanList = () => {
  return request.get('/api/plan/current-plan');
};

//获取历史订购列表
export const getPurchaseRecordsList = (data: { startTime: number }) => {
  return request.get('/api/plan/purchase-records', data);
};
//获取余额
export const getAssetMe = () => {
  return request.get<{
    balance: number;
    canWithdraw: boolean;
  }>('/api/asset/me');
};
//提现
export const drawBalance = (data: { amount: number; email: string }) => {
  return request.post('/api/asset/withdraw', data);
};
//获取账单
export const getBillDetail = (data: { subscriptionId: string }) => {
  return request.get<IReceipt>('/api/plan/get-bill', data);
};

// 获取流量包列表 1：带宽流量 2：存储 3：聚合流量
export const getResourceList = (data: { resourceType: 1 | 2 | 3 }) => {
  return request.get<Array<IRsourceDetail>>('/api/resource/package-list', data);
};
