import loadable from '@/utils/loadable';
import * as ConstantRouter from '@/constant/router';
import * as ConstantLabel from '@/constant/label';

const route = {
  path: 'embed',
  name: ConstantRouter.EMBED,
  meta: {
    name: ConstantLabel.EMBED,
  },
  component: loadable(() => import(/* webpackChunkName: "embed" */ '@/pages/embed/index.vue')),
};

export default route;
