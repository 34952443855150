import { defineStore } from 'pinia';

import { getCurrentResource, ResourceItem } from '@/api/plan';

interface IError {
  message: string;
  code: number;
}

const useCurrentResource = defineStore('currentResource', {
  state: (): {
    loading: boolean;
    error: Nullable<IError>;
    detail: Nullable<ResourceItem>;
  } => {
    return {
      loading: true,
      error: null,
      detail: null,
    };
  },
  getters: {},
  actions: {
    async setCurrentResource(resourceData: ResourceItem) {
      this.detail = resourceData;
      this.loading = false;
    },
    async fetchCurrentResource() {
      try {
        const { data } = await getCurrentResource();
        this.detail = data;
        return true;
      } catch (error) {
        this.error = error as IError;
        return false;
      } finally {
        this.loading = false;
      }
    },
  },
});

export default useCurrentResource;
