<script lang="ts">
export default defineComponent({
  name: 'sub-menu',
});
</script>

<script setup lang="ts">
import { PropType } from 'vue';

import { IMenuItem } from '@/config/menu';
import * as ConstantRouter from '@/constant/router';

const props = defineProps({
  menuInfo: {
    type: Object as PropType<IMenuItem>,
    require: true,
  },
  isActive: {
    type: Function,
    require: true,
  },
});
const { menuInfo, isActive } = toRefs(props);
const emits = defineEmits(['menuClick']);
</script>

<template>
  <a-sub-menu v-if="menuInfo" :key="menuInfo.key" class="sub-menu">
    <template v-if="menuInfo.icon" #icon>
      <i :class="isActive(menuInfo) ? menuInfo.activeIcon : menuInfo.icon"></i>
    </template>
    <template #title>
      {{ menuInfo.title }}
      <img
        v-if="menuInfo.key === ConstantRouter.URL_OVERLAY"
        src="@/assets/images/icon-beta.png"
        alt="icon-beta"
        class="icon-beta"
      />
    </template>
    <template v-for="item in menuInfo.children" :key="item.key">
      <template v-if="item.children && !item.hideChildrenInMenu">
        <sub-menu
          :menu-info="item"
          :key="item.key"
          :isActive="isActive"
          @menuClick="emits('menuClick', $event)"
        ></sub-menu>
      </template>
      <template v-else>
        <a-menu-item :key="item.key" @click="emits('menuClick', item)">
          <template v-if="item.icon" #icon>
            <i :class="isActive(item) ? item.activeIcon : item.icon"></i>
          </template>
          {{ item.title }}
          <img
            v-if="item.newFeature"
            src="@/assets/images/icon-new.png"
            alt="icon-new"
            class="icon-new"
          />
        </a-menu-item>
      </template>
    </template>
  </a-sub-menu>
</template>

<style lang="scss">
.sub-menu {
  margin: 8px;
  border-radius: 4px;

  .ant-menu-submenu-title {
    padding-left: 12px !important;
  }

  .ant-menu-submenu-arrow {
    right: 10px;
    color: rgba(255, 255, 255, 0.8);
  }

  .ant-menu-title-content {
    overflow: unset !important;
    color: rgba(255, 255, 255, 0.8);
    text-overflow: unset !important;
  }

  &:hover {
    .ant-menu-submenu-arrow {
      color: #fff !important;
    }
  }

  &.ant-menu-submenu-selected {
    .ant-menu-title-content {
      color: rgba(255, 255, 255) !important;
    }
  }

  .ant-menu-submenu-title:active {
    background: transparent;
  }

  .ant-menu {
    li.ant-menu-item {
      padding: 8px 8px 8px 36px !important;
      margin: 8px 4px !important;
    }
  }

  .icon-beta {
    position: relative;
    top: -1px;
    left: 1px;
    width: 28px;
  }

  .icon-new {
    position: absolute;
    top: 10px;
    right: 4px;
    width: 28px;
  }
}

.ant-menu-inline-collapsed {
  .sub-menu {
    .ant-menu-submenu-title {
      padding: 10px calc(50% - 22px / 2) !important;
    }
  }
}
</style>
