import RouterHolder from '@/components/router-holder/index.vue';
import * as ConstantRouter from '@/constant/router';
import * as ConstantLabel from '@/constant/label';
import loadable from '@/utils/loadable';

const route = {
  path: 'broadcasts',
  component: RouterHolder,
  name: ConstantRouter.BROADCASTS,
  meta: {
    name: ConstantLabel.BROADCASTS,
  },
  redirect: '/broadcasts',
  children: [
    {
      path: '',
      name: ConstantRouter.BROADCASTS_LIST,
      component: loadable(
        () => import(/* webpackChunkName: "broadcasts" */ '@/pages/broadcasts/index.vue'),
      ),
      meta: {
        showUploadBox: true,
      },
    },
    {
      path: 'add',
      component: loadable(
        () => import(/* webpackChunkName: "broadcasts-add" */ '@/pages/broadcasts/add/index.vue'),
      ),
      name: ConstantRouter.BROADCASTSADD,
      meta: {
        name: ConstantLabel.BROADCASTSADD,
        showUploadBox: true,
      },
    },
    {
      path: 'update/:id',
      component: loadable(
        () => import(/* webpackChunkName: "broadcasts-add" */ '@/pages/broadcasts/add/index.vue'),
      ),
      name: ConstantRouter.BROADCASTSUPDATE,
      meta: {
        name: ConstantLabel.BROADCASTSUPDATE,
        showUploadBox: true,
      },
    },
  ],
};

export default route;
