<script lang="ts" setup>
export interface Instance {
  show: () => void;
  hide: () => void;
}
const emits = defineEmits(['sure']);
const visible = ref(false);
const show = () => {
  visible.value = true;
};

const hide = () => {
  visible.value = false;
};

const sure = () => {
  visible.value = false;
  emits('sure');
};

defineExpose({
  show,
  hide,
});
</script>

<template>
  <a-modal
    v-model:visible="visible"
    :footer="null"
    wrapClassName="video-introduce"
    :maskClosable="false"
    :width="800"
  >
    <div class="video-introduce__main">
      <p class="video-introduce__title">What is a Video Source?</p>
      <p class="video-introduce__desc">
        Encoder, OBS, that-thing-in-the-corner-with-blinking-lights ... whatever you call it, a
        source is what gets your video from the camera to YoloCast. Some people have hardware,
        others use software.
      </p>
      <a-button class="video-introduce__sure" @click="sure">Set Up Video Source</a-button>
    </div>
  </a-modal>
</template>

<style lang="scss">
.video-introduce {
  .ant-modal-body {
    padding: 0;
  }
}

.video-introduce__main {
  padding: 55px 80px 35px;
  text-align: center;
}

.video-introduce__title {
  margin-bottom: 15px;
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  color: #1d2129;
}

.video-introduce__desc {
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #1d2129;
}

.video-introduce__sure {
  width: 205px;
  height: 40px;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  color: #4e5969;
  border: 1px solid #4e5969;
  border-radius: 20px;
}
</style>
