import { RouteRecordRaw } from 'vue-router';

import * as ConstantRouter from '@/constant/router';
import Index from '@/index.vue';
import HomeLayout from '@/layout/home/index.vue';
import loadable from '@/utils/loadable';

import broadcasts from './modules/broadcasts';
import broadcastsAlways from './modules/broadcasts-always';
import broadcastsDetail from './modules/broadcasts-detail';
import callback from './modules/callback';
import destinations from './modules/destinations';
import embed from './modules/embed';
import embedDetail from './modules/embed-detail';
import media from './modules/media';
import overlay from './modules/overlay';
import reports from './modules/reports';
import settings from './modules/settings';
import sources from './modules/sources';
import themes from './modules/themes';
import themesDetail from './modules/themes-detail';
import user from './modules/user';
import videoTutorials from './modules/video-tutorials';
import webControl from './modules/web-control';
import yolocut from './modules/yolocut';

/** noVerify 不需要token登录校验 noOverdue 不需要验证套餐过期 */
const routes: Array<RouteRecordRaw> = [
  user,
  callback,
  {
    path: '/',
    component: Index,
    children: [
      {
        path: '',
        component: HomeLayout,
        name: ConstantRouter.HOME,
        redirect: '/broadcasts',
        children: [
          broadcasts,
          embed,
          media,
          reports,
          sources,
          settings,
          destinations,
          themes,
          overlay,
          videoTutorials,
          yolocut,
          webControl,
        ],
      },
      broadcastsDetail,
      broadcastsAlways,
      themesDetail,
      embedDetail,
    ],
  },
  {
    path: '/404',
    component: () => import('@/pages/exception/404.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
  {
    path: '/blank',
    component: () => import('@/pages/blank/index.vue'),
  },
  {
    path: '/receipt/:id',
    component: () => import('@/pages/receipt/index.vue'),
    name: ConstantRouter.RECEIPT,
  },
  {
    path: '/control/:appId',
    name: ConstantRouter.CONTROL,
    meta: {
      noVerify: true,
    },
    component: loadable(
      () => import(/* webpackChunkName: "control" */ '@/overlay/pages/control/index.vue'),
    ),
  },
  {
    path: '/composition/:id',
    name: ConstantRouter.COMPOSITION,
    meta: {
      noVerify: true,
    },
    component: loadable(
      () => import(/* webpackChunkName: "composition" */ '@/overlay/pages/composition/index.vue'),
    ),
  },
  {
    path: '/coupon/:id',
    name: ConstantRouter.COUPON,
    meta: {
      noVerify: true,
    },
    component: loadable(
      () => import(/* webpackChunkName: "composition" */ '@/pages/coupon/index.vue'),
    ),
  },
  {
    path: '/youtube',
    name: ConstantRouter.YOUTUBE,
    meta: {
      noVerify: true,
    },
    component: loadable(
      () => import(/* webpackChunkName: "composition" */ '@/pages/youtube-no-permission/index.vue'),
    ),
  },
  {
    path: '/yolocut-detail',
    name: ConstantRouter.YOLOCUT_DETAIL,
    meta: {
      noVerify: true,
    },
    component: loadable(() => import(/* webpackChunkName: "composition" */ '@/yolocut/index.vue')),
  },
  {
    path: '/sub-exclusive',
    name: ConstantRouter.SUB_EXCLUSIVE,
    meta: {
      noVerify: true,
    },
    component: loadable(
      () => import(/* webpackChunkName: "composition" */ '@/pages/sub-exclusive/index.vue'),
    ),
  },
  {
    path: '/dealer/:id',
    name: ConstantRouter.DEALER,
    meta: {
      noVerify: true,
    },
    component: loadable(
      () => import(/* webpackChunkName: "composition" */ '@/pages/dealer/index.vue'),
    ),
  },
  {
    path: '/plan/:id',
    name: ConstantRouter.PLAN,
    meta: {
      noVerify: true,
    },
    component: loadable(
      () => import(/* webpackChunkName: "composition" */ '@/pages/plan/index.vue'),
    ),
  },
  {
    path: '/suggestions/:activityId',
    component: () => import('@/pages/broadcasts/detail/coms/suggestions-detail.vue'),
    name: ConstantRouter.SUGGESTIONS,
  },
  {
    path: '/report/:id',
    name: ConstantRouter.REPORT,
    meta: {
      noVerify: true,
    },
    component: loadable(
      () => import(/* webpackChunkName: "composition" */ '@/pages/share-report/index.vue'),
    ),
  },
];

export default routes;
