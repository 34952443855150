import { defineStore } from 'pinia';
import { getPermissionList } from '@/api/user';
import { PermissionListResponse } from '@/types/api';
import { PermissionEnum } from '@/types/permission';

interface IError {
  message: string;
  code: number;
}

const usePermissionStore = defineStore('auth', {
  state: (): {
    list: Required<PermissionListResponse>[] | null;
    error: Nullable<IError>;
    loading: boolean;
    permissionMap: Map<PermissionEnum, any>;
  } => {
    return {
      list: null,
      loading: true,
      error: null,
      permissionMap: new Map(),
    };
  },
  getters: {},
  actions: {
    async setCurrentPlan(permissionData: Required<PermissionListResponse>[]) {
      this.list = permissionData;
      this.list.forEach(({ featureId, value }) => {
        this.permissionMap.set(featureId, value || true);
      });
      this.loading = false;
    },
    async fetchPermissionList() {
      try {
        const { data } = await getPermissionList();
        this.list = data;
        this.list.forEach(({ featureId, value }) => {
          this.permissionMap.set(featureId, value || true);
        });
        return true;
      } catch (error) {
        this.error = error as IError;
        return false;
      } finally {
        this.loading = false;
      }
    },
    hasPermission(featureId: PermissionEnum) {
      return this.permissionMap.has(featureId);
    },
    getPermission(featureId: PermissionEnum) {
      return this.permissionMap.get(featureId);
    },
  },
});

export default usePermissionStore;
