import * as ConstantRouter from '@/constant/router';
import * as ConstantLabel from '@/constant/label';

const route = {
  path: 'destinations',
  name: ConstantRouter.DESTINATIONS,
  component: () => import(/* webpackChunkName: "sources" */ '@/pages/destinations/index.vue'),
  meta: {
    name: ConstantLabel.DESTINATIONS,
    showUploadBox: true,
  },
};

export default route;
