<script setup lang="ts">
import TcPlayer from '@/components/tcplayer/index.vue';
import DefaultTrimUrl from '@/assets/images/login/icon-login-trim.png';
import ActivedTrimUrl from '@/assets/images/login/icon-login-trim-actived.png';
import DefaultEmbedUrl from '@/assets/images/login/icon-login-embed.png';
import ActivedEmbedUrl from '@/assets/images/login/icon-login-embed-actived.png';
import DefaultHighlightUrl from '@/assets/images/login/icon-login-highlight.png';
import ActivedHighlightUrl from '@/assets/images/login/icon-login-highlight-actived.png';
import DefaultAnalyticsUrl from '@/assets/images/login/icon-login-analytics.png';
import ActivedAnalyticsUrl from '@/assets/images/login/icon-login-analytics-actived.png';

const changedTcPlayer = ref(false);
const tcPlayerRef = ref();
const typeList = ['Discover the difference YoloCast provides.'];
const advanceList = ref([
  {
    videoUrl: 'https://static.yololiv.com/static/videos/1e474a24-6ab0-4818-b04f-3c5b29c717e6_6.mp4',
    iconDefaultUrl: DefaultTrimUrl,
    iconActivedUrl: ActivedTrimUrl,
    title: 'Video Trimming',
    desc: 'Cut out dead time by trimming from your broadcast to get the best version of the live stream.',
    select: true,
    poster:
      'https://static.yololiv.com/static/imgs/6cd493a8-4f76-43f8-a15b-a07ffd8f9b2b_login-6-trimming.jpg',
  },
  {
    videoUrl: 'https://static.yololiv.com/static/videos/81c31007-74fa-4dc5-9a74-e3ed981c1038_7.mp4',
    iconDefaultUrl: DefaultEmbedUrl,
    iconActivedUrl: ActivedEmbedUrl,
    title: 'Embed to Website',
    desc: 'Use iframe code、js code or link embed the live event or video to your website.',
    select: false,
    poster:
      'https://static.yololiv.com/static/imgs/3412d41b-7156-4861-903e-f938190c7c12_login-7-embed.jpg',
  },
  {
    videoUrl: 'https://static.yololiv.com/static/videos/86fcd798-fae0-4952-8c8e-a182ce6e0cdb_8.mp4',
    iconDefaultUrl: DefaultHighlightUrl,
    iconActivedUrl: ActivedHighlightUrl,
    title: 'Highlight Clipping',
    desc: 'Capture important moments and share them on social media by quickly clipping highlights.',
    select: false,
    poster:
      'https://static.yololiv.com/static/imgs/4346d7b6-1c99-4c52-a6b7-af5f5cc14b32_login-8-highlight.jpg',
  },
  {
    videoUrl: 'https://static.yololiv.com/static/videos/2320af03-8822-4d5a-9665-6422a8c90a0c_9.mp4',
    iconDefaultUrl: DefaultAnalyticsUrl,
    iconActivedUrl: ActivedAnalyticsUrl,
    title: 'Real-Time Analytics',
    desc: 'Detailed analytics for your viewers, including engagement、location,view times and more.',
    select: false,
    poster:
      'https://static.yololiv.com/static/imgs/2bb30703-c6f0-41c4-ba16-4565a73f5623_login-9-analytics.jpg',
  },
]);
const currentAdvance = ref(advanceList.value[0]);
const playOption = computed(() => {
  if (currentAdvance.value && currentAdvance.value.videoUrl) {
    return {
      src: currentAdvance.value.videoUrl,
      poster: currentAdvance.value.poster,
    };
  }
  return null;
});

function selectCurrentAdvance(item: any) {
  changedTcPlayer.value = false;
  advanceList.value.forEach(item => {
    item.select = false;
  });
  item.select = true;
  currentAdvance.value = item;
  let timer: any = setTimeout(() => {
    changedTcPlayer.value = true;
    clearTimeout(timer);
    timer = null;
  }, 100);
}

function stopPlayer() {
  if (tcPlayerRef.value && tcPlayerRef.value.getPlayerInstance()) {
    tcPlayerRef.value.getPlayerInstance().pause();
    changedTcPlayer.value = false;
  }
}

function startPlayer() {
  changedTcPlayer.value = true;
}

defineExpose({
  stopPlayer,
  startPlayer,
});
</script>

<template>
  <div class="page-third-step">
    <div class="step-title">Why YoloCast?</div>
    <div class="step-type">
      <div class="type-item" v-for="(type, index) in typeList" :key="type">
        {{ type }}<i v-if="index !== typeList.length - 1"></i>
      </div>
    </div>
    <div class="step-video">
      <div class="left-flower"></div>
      <div class="right-flower"></div>
      <div class="video-round-box">
        <div class="step-video-box">
          <TcPlayer
            v-if="playOption && changedTcPlayer"
            ref="tcPlayerRef"
            class="control-player__play-inner"
            :id="`tc-player-${new Date().getTime()}`"
            v-bind="playOption"
          ></TcPlayer>
        </div>
        <div class="video-mask"></div>
      </div>
    </div>
    <div class="step-advance">
      <div
        class="advance-item"
        :class="{ 'select-advance-item': item.select }"
        v-for="item in advanceList"
        :key="item.title"
        @click="selectCurrentAdvance(item)"
      >
        <div class="advance-mask"></div>
        <div class="advance-item-logo">
          <img class="default-logo" :src="item.iconDefaultUrl" alt="" />
          <img class="actived-logo" :src="item.iconActivedUrl" alt="" />
        </div>
        <div class="advance-item-msg">
          <div class="msg-title">{{ item.title }}</div>
          <div class="msg-desc">{{ item.desc }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.page-third-step {
  width: 100%;
  min-height: 100vh;
  padding: 70px 0 0;

  .step-title {
    margin-bottom: 20px;
    font-family: Montserrat-Bold, Montserrat;
    font-size: 32px;
    font-weight: bold;
    line-height: 39px;
    color: #fff;
    text-align: center;
  }

  .step-type {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 45px;

    .type-item {
      display: flex;
      align-items: center;
      font-family: Montserrat-Regular, Montserrat;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      color: #fff;

      i {
        display: block;
        width: 1px;
        height: 12px;
        margin: 0 15px;
        background: rgba(255, 255, 255, 0.3);
      }
    }
  }

  .step-video {
    position: relative;
    width: 39vw;
    min-width: 590px;
    max-width: 750px;
    margin: 0 auto 30px;

    .left-flower {
      position: absolute;
      bottom: 0;
      left: -93px;
      z-index: 1;
      width: 120px;
      height: 220px;
      background: url(@/assets/images/icon-left-flower.png) no-repeat;
      background-size: 100%;
    }

    .right-flower {
      position: absolute;
      right: -117px;
      bottom: 0;
      z-index: 1;
      width: 164px;
      height: 326px;
      background: url(@/assets/images/icon-right-flower.png) no-repeat;
      background-size: 100%;
    }

    .video-round-box {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 15px;

      .step-video-box {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 56.25%;
        overflow: hidden;
        border-radius: 12px;

        .control-player__play-inner {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 200 !important;
          width: 100% !important;
          height: 100% !important;
        }
      }

      .video-mask {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 100;
        background: rgba(255, 255, 255, 0.1);
        border: 2px solid rgba(96, 107, 255, 0.6);
        border-radius: 12px;
        backdrop-filter: blur(22.5px);
      }
    }
  }

  .step-advance {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 50vw;
    min-width: 740px;
    margin: 0 auto;

    .advance-item {
      position: relative;
      display: flex;
      width: 49%;
      height: 110px;
      padding: 15px;
      margin-bottom: 20px;
      cursor: pointer;
      background: transparent;
      border-radius: 8px;
      transition: all 0.3s;

      .advance-mask {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        border: 1px solid #fff;
        border-radius: 8px;
        opacity: 0;
        transition: all 0.3s;
      }

      .advance-item-logo {
        width: 40px;
        height: 40px;
        margin-right: 20px;

        img {
          width: 100%;
          height: 100%;
        }

        .default-logo {
          display: block;
        }

        .actived-logo {
          display: none;
        }
      }

      .advance-item-msg {
        flex: 1;

        .msg-title {
          margin-bottom: 4px;
          font-family: Montserrat-Bold, Montserrat;
          font-size: 18px;
          font-weight: bold;
          line-height: 22px;
          color: rgba(255, 255, 255, 0.7);
        }

        .msg-desc {
          font-family: Montserrat-Regular, Montserrat;
          font-size: 16px;
          font-weight: 400;
          line-height: 18px;
          color: rgba(255, 255, 255, 0.7);
        }
      }

      &.select-advance-item {
        background: rgba(255, 255, 255, 0.1);
        transition: all 0.3s;

        .advance-mask {
          opacity: 1;
          transition: all 0.3s;
        }

        .advance-item-logo {
          .default-logo {
            display: none;
          }

          .actived-logo {
            display: block;
          }
        }

        .advance-item-msg {
          flex: 1;

          .msg-title {
            color: rgba(255, 255, 255, 1);
          }

          .msg-desc {
            color: rgba(255, 255, 255, 1);
          }
        }
      }

      &:hover {
        .advance-mask {
          opacity: 1;
          transition: all 0.3s;
        }
      }
    }
  }
}
</style>
