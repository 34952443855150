<script setup lang="ts">
import { PropType } from 'vue';

interface IError {
  message: string;
  code: number;
}

const props = defineProps({
  data: {
    type: Object as PropType<IError>,
    default: () => ({
      message: 'Sorry, the server is wrong.',
    }),
  },
});

function refresh() {
  window.location.reload();
}
</script>

<template>
  <a-result status="error" :title="props.data.code" :sub-title="props.data.message">
    <template #extra>
      <a-button type="primary" @click="refresh">Refresh</a-button>
    </template>
  </a-result>
</template>
