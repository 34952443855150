const scroll = {
  mounted(el) {
    let t1 = 0;
    let t2 = 0;
    el.$timer = null;
    el.isScrollEnd = function () {
      t2 = el.scrollTop;
      if (t2 === t1) {
        clearTimeout(el.$timer);
        el.classList.add('c-no-scroll');
      }
    };
    el.$scroll = function () {
      el.classList.remove('c-no-scroll');
      clearTimeout(el.$timer);
      el.$timer = setTimeout(el.isScrollEnd, 1000);
      t1 = el.scrollTop;
    };
    el.addEventListener('scroll', el.$scroll);
  },
  unmounted(el) {
    el.removeEventListener('scroll', el.$scroll);
  },
};
export default scroll;
