import upgradeTip from '@/utils/upgradeTip';

const permission = {
  mounted(el, { value }, vnode) {
    // 没有权限，则增加没有权限的弹窗提示
    const params = {};
    if (typeof value === 'boolean') {
      Object.assign(params, { value });
    } else if (typeof value === 'object') {
      Object.assign(params, value);
    }
    if (!params.value) {
      el._setElStyleDestory = permission._setElStyle(el);
      el._maskDestory = permission._mask(el, vnode, params);
    }
  },
  unmounted(el) {
    if (el._setElStyleDestory) {
      el._setElStyleDestory();
    }
    if (el._maskDestory) {
      el._maskDestory();
    }
  },
  _handle(vnode, params, e) {
    e.stopPropagation();
    upgradeTip(params);
  },
  _setElStyle(el) {
    const timer = setTimeout(() => {
      if (!['relative', 'fixed'].includes(window.getComputedStyle(el).position)) {
        el.style.position = 'relative';
      }
    }, 0);
    return function () {
      clearTimeout(timer);
    };
  },
  _mask(el, vnode, params) {
    const maskDom = document.createElement('div');
    maskDom.style.position = 'absolute';
    maskDom.style.zIndex = '100';
    maskDom.style.left = '0';
    maskDom.style.top = '0';
    maskDom.style.width = '100%';
    maskDom.style.height = '100%';
    maskDom.style.cursor = 'no-drop';
    maskDom.addEventListener('click', permission._handle.bind(null, vnode, params));
    el.append(maskDom);
    return function () {
      maskDom.removeEventListener('click', permission._handle.bind(null, vnode, params));
      maskDom.remove();
    };
  },
};

export default permission;
