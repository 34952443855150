import request from '@/utils/request';

export interface ICouponDetail {
  couponId: string;
  name: string;
  describe: string;
  ruleContent: string;
  rate: number;
  expireTime: number;
  createdAt: number;
  receiveTimes: number;
  useTimes: number;
  status: 1 | 2; // 1:已上架 2: 已下架
}

/**
 *  获取优惠券详情
 */
export const getCouponDetail = (data: { couponId: string }) => {
  return request.get<ICouponDetail>(`/api/coupon/${data.couponId}`, data);
};

/**
 *  领取优惠券
 */
export const bindCoupon = (data: { couponId: string; email: string }) => {
  return request.post<{ isRegister: boolean }>(`/api/coupon/receive`, data);
};

/**
 *  获取我的优惠券详情
 */
export const getMyCouponDetail = () => {
  return request.get<ICouponDetail>(`/api/coupon/get-org-coupon`);
};
