import request from '@/utils/request';

export interface DocItem {
  activityDocId: string;
  activityId: string;
  docId: string;
  docImageUrl: string[];
  aliasName: string;
  docLink: string;
}

/**
 *  获取新手引导
 */
export const getGuide = () => {
  return request.get<{
    // 老引导数据
    doneMedia: 0 | 1;
    doneStreaming: 0 | 1;
    // 新引导数据
    doneWelcome: 0 | 1;
    doneTrial: 0 | 1;
    doneSource: 0 | 1;
    donePlatform: 0 | 1;
    doneActivity: 0 | 1;
    doneAll: 0 | 1;
  }>(`/api/guide`);
};

/**
 *  设置新手引导
 */
export const putGuide = (data: {
  // 老引导数据
  doneMedia?: 0 | 1;
  doneStreaming?: 0 | 1;
  // 新引导数据
  doneWelcome?: 0 | 1;
  doneTrial?: 0 | 1;
  doneSource?: 0 | 1;
  donePlatform?: 0 | 1;
  doneActivity?: 0 | 1;
  doneAll?: 0 | 1;
}) => {
  return request.put<boolean>(`/api/guide`, data);
};
