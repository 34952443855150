import useStore from '@/store/currentPlan';
import useResourceStore from '@/store/currentResource';
import { computed } from 'vue';
import { storeToRefs } from 'pinia';

export default function useCurrentPlan() {
  const store = useStore();
  const resourceStore = useResourceStore();
  const { loading, error, invitationCode, currentPlanData: planData } = storeToRefs(store);
  const { detail: currentPlanData } = storeToRefs(resourceStore);

  onMounted(async () => {
    if (!currentPlanData.value) {
      await resourceStore.fetchCurrentResource();
    }
    if (!planData.value) {
      await store.updateCurrentPlan();
    }
  });

  async function updateCurrentPlan() {
    resourceStore.fetchCurrentResource();
    store.updateCurrentPlan();
  }

  // 是否超出存储
  const overStorage = computed(() => {
    if (currentPlanData && currentPlanData.value && currentPlanData.value.storage) {
      if (currentPlanData.value.usedStorage < currentPlanData.value.storage) {
        return false;
      }
    }
    return true;
  });
  /** 已用存储 */
  const usageStorage = computed(() => {
    if (currentPlanData && currentPlanData.value && currentPlanData.value.usedStorage) {
      return currentPlanData.value.usedStorage;
    }
    return 0;
  });
  /** 总存储 */
  const totalStorage = computed(() => {
    if (currentPlanData && currentPlanData.value && currentPlanData.value.storage) {
      return currentPlanData.value.storage;
    }
    return 0;
  });

  async function updateInvitationCode() {
    await store.fetchInvitationCode();
  }

  return {
    loading,
    error,
    currentPlanData,
    updateCurrentPlan,
    overStorage,
    usageStorage,
    totalStorage,
    invitationCode,
    updateInvitationCode,
    planData,
  };
}
