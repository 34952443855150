import * as ConstantLabel from '@/constant/label';
import * as ConstantRouter from '@/constant/router';
import loadable from '@/utils/loadable';

const route = {
  path: 'settings',
  name: ConstantRouter.SETTINGS,
  component: loadable(
    () => import(/* webpackChunkName: "settings" */ '@/pages/settings/index.vue'),
  ),
  meta: {
    name: ConstantLabel.SETTINGS,
    showUploadBox: true,
  },
  children: [
    {
      path: 'plans',
      name: ConstantRouter.PLANS,
      meta: {
        name: ConstantLabel.PLANS,
        showUploadBox: true,
        upgrade: true,
      },
      component: loadable(
        () => import(/* webpackChunkName: "settings-plans" */ '@/pages/settings/plans/index.vue'),
      ),
    },
    {
      path: 'billing',
      name: ConstantRouter.BILLING,
      component: loadable(
        () =>
          import(/* webpackChunkName: "settings-billing" */ '@/pages/settings/billing/index.vue'),
      ),
      meta: {
        name: ConstantLabel.BILLING,
        showUploadBox: true,
      },
    },
    {
      path: 'account',
      name: ConstantRouter.ACCOUNT,
      component: loadable(
        () =>
          import(/* webpackChunkName: "settings-account" */ '@/pages/settings/account/index.vue'),
      ),
      meta: {
        name: ConstantLabel.ACCOUNT,
        showUploadBox: true,
      },
    },
    // {
    //   path: 'resources',
    //   name: ConstantRouter.RESOURCES,
    //   component: loadable(
    //     () =>
    //       import(
    //         /* webpackChunkName: "settings-resources" */ '@/pages/settings/resources/index.vue'
    //       ),
    //   ),
    //   meta: {
    //     name: ConstantLabel.RESOURCES,
    //     showUploadBox: true,
    //   },
    // },
    {
      path: 'preferences',
      name: ConstantRouter.APPEARANCE,
      component: loadable(
        () =>
          import(
            /* webpackChunkName: "settings-preferences" */ '@/pages/settings/preferences/index.vue'
          ),
      ),
      meta: {
        name: ConstantLabel.APPEARANCE,
        showUploadBox: true,
      },
    },
    {
      path: 'users',
      name: ConstantRouter.USERS,
      component: loadable(
        () => import(/* webpackChunkName: "settings-users" */ '@/pages/settings/users/index.vue'),
      ),
      meta: {
        name: ConstantLabel.USERS,
        showUploadBox: true,
      },
    },
    // {
    //   path: 'commission',
    //   name: ConstantRouter.COMMISSION,
    //   component: loadable(
    //     () =>
    //       import(/* webpackChunkName: "settings-users" */ '@/pages/settings/commission/index.vue'),
    //   ),
    //   meta: {
    //     name: ConstantLabel.COMMISSION,
    //     showUploadBox: true,
    //     new: false,
    //   },
    // },
  ],
};

export default route;
