import * as ConstantLabel from '@/constant/label';
import * as ConstantRouter from '@/constant/router';
import loadable from '@/utils/loadable';

const route = {
  path: 'reports',
  name: ConstantRouter.REPORTS,
  component: loadable(() => import(/* webpackChunkName: "reports" */ '@/pages/reports/index.vue')),
  meta: {
    name: ConstantLabel.REPORTS,
    showUploadBox: true,
  },
  children: [
    {
      path: 'detail',
      name: ConstantRouter.DETAILEDREPORTS,
      component: loadable(
        () => import(/* webpackChunkName: "detailed-reports" */ '@/pages/reports/index.vue'),
      ),
      meta: {
        name: ConstantLabel.DETAILEDREPORTS,
        showUploadBox: true,
      },
    },
    {
      path: 'new-detail/:id',
      name: ConstantRouter.NEW_DETAILED_REPORTS,
      component: loadable(
        () =>
          import(
            /* webpackChunkName: "detailed-reports" */ '@/pages/reports/tabular-report/index.vue'
          ),
      ),
      meta: {
        name: ConstantLabel.NEW_DETAILED_REPORTS,
        showUploadBox: true,
      },
    },
  ],
};

export default route;
