<script lang="ts" setup>
export interface Instance {
  show: () => void;
  hide: () => void;
}
const emits = defineEmits(['sure']);
const visible = ref(false);
const show = () => {
  visible.value = true;
};

const hide = () => {
  visible.value = false;
};

const sure = () => {
  visible.value = false;
  emits('sure');
};

defineExpose({
  show,
  hide,
});
</script>

<template>
  <a-modal
    v-model:visible="visible"
    :footer="null"
    :closable="false"
    :maskClosable="false"
    wrapClassName="video-fit"
    :width="800"
  >
    <div class="video-fit__main">
      <p class="video-fit__title">Which Source is Right for Me?</p>
      <p class="video-fit__desc">
        While YoloCast has the ability to connect with hundreds of devices, we're going to keep
        things simple and recommend two options to get started.
      </p>
      <div class="video-fit__compare">
        <ul>
          <li>
            <h4>YoloBox (Hardware)</h4>
            <p>
              The simplest multi-cam live streaming solution available with advanced built-in
              features.
            </p>
            <a href="https://yololiv.com" target="_blank">More Information></a>
          </li>
          <li>
            <h4>RTMP (Advanced)</h4>
            <p>
              Have your own streaming encoder? Connect to YoloCast and start streaming with your
              existing setup.
            </p>
          </li>
        </ul>
      </div>
      <a-button class="video-fit__sure" @click="sure">Continue</a-button>
    </div>
  </a-modal>
</template>

<style lang="scss">
.video-fit {
  .ant-modal-body {
    padding: 0;
  }
}

.video-fit__main {
  padding: 55px 80px 35px;
  text-align: center;
}

.video-fit__title {
  margin-bottom: 15px;
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  color: #1d2129;
}

.video-fit__desc {
  margin-bottom: 40px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #1d2129;
}

.video-fit__compare {
  margin-bottom: 50px;

  ul {
    display: flex;

    li + li {
      margin-left: 140px;
    }
  }

  h4 {
    font-size: 18px;
    font-weight: 600;
  }
}

.video-fit__sure {
  width: 205px;
  height: 40px;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  color: #4e5969;
  border: 1px solid #4e5969;
  border-radius: 20px;
}
</style>
