import { defineStore } from 'pinia';

import { getGuide, putGuide } from '@/api/guide';
interface IError {
  message: string;
  code: number;
}

const useNewGuideStore = defineStore('newGuide', {
  state: (): {
    loading: boolean;
    error: Nullable<IError>;
    guideDetail: Nullable<{
      // 老引导数据
      doneMedia?: 0 | 1;
      doneStreaming?: 0 | 1;
      // 新引导数据
      doneWelcome?: 0 | 1;
      doneTrial?: 0 | 1;
      doneSource?: 0 | 1;
      donePlatform?: 0 | 1;
      doneActivity?: 0 | 1;
      doneAll?: 0 | 1;
    }>;
  } => {
    return {
      loading: true,
      error: null,
      guideDetail: null,
    };
  },
  getters: {},
  actions: {
    async fetchGuideInfo() {
      try {
        const { data } = await getGuide();
        this.guideDetail = data;
        return true;
      } catch (error) {
        this.error = error as IError;
        return false;
      } finally {
        this.loading = false;
      }
    },
    async updateGuideInfo(params: {
      // 老引导数据
      doneMedia?: 0 | 1;
      doneStreaming?: 0 | 1;
      // 新引导数据
      doneWelcome?: 0 | 1;
      doneTrial?: 0 | 1;
      doneSource?: 0 | 1;
      donePlatform?: 0 | 1;
      doneActivity?: 0 | 1;
      doneAll?: 0 | 1;
    }) {
      this.guideDetail = {
        ...this.guideDetail,
        ...params,
      };
      await putGuide(params);
      // await this.fetchGuideInfo();
    },
  },
});

export default useNewGuideStore;
