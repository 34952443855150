import { defineStore } from 'pinia';

const usePackageTip = defineStore('packageTip', {
  state: () => {
    return {
      aboutExpireShow: false,
      hasExpireShow: false,
      hasCouponShow: false,
      notificationsShow: false,
    };
  },
  getters: {},
  actions: {
    async aboutExpireShowUpdate(isShow: boolean) {
      this.aboutExpireShow = isShow;
    },
    async hasExpireShowUpdate(isShow: boolean) {
      this.hasExpireShow = isShow;
    },
    async hasCouponShowUpdate(isShow: boolean) {
      this.hasCouponShow = isShow;
    },
    async notificationsShowUpdate(isShow: boolean) {
      this.notificationsShow = isShow;
    },
    dataUpdate(data: object) {
      Object.assign(this, data);
    },
  },
});

export default usePackageTip;
