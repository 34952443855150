<script setup lang="ts">
import { useRequest } from 'vue-request';

import { bindCoupon } from '@/api/coupon';
import { getAlertListPage, INotifications } from '@/api/notifications';
import useUserStore from '@/store/user';
import { formatTime } from '@/utils/tools';

const {
  loading: getAlertListPageLoading,
  data: getAlertListPageData,
  run: getAlertListPageRun,
  error: getAlertListPageError,
} = useRequest(getAlertListPage, {
  manual: true,
  formatResult(res) {
    return res.data;
  },
});

const { run: bindCouponRun, error: bindCouponError } = useRequest(bindCoupon, {
  manual: true,
  formatResult(res) {
    return res.data;
  },
});

const emits = defineEmits(['close']);
const userStore = useUserStore();
const { detail: userInfo } = toRefs(userStore);
const list = ref<Array<INotifications>>([]);
const finished = ref(false);
let pageNo = 1;
let pageSize = 20;

function onLoad() {
  let timer: any = setTimeout(async () => {
    clearTimeout(timer);
    timer = null;
    await getAlertListPageRun({ pageNo: pageNo, pageSize: pageSize, type: 1 });
    pageNo++;
    if (getAlertListPageError.value) {
      finished.value = true;
      return false;
    }
    if (getAlertListPageData.value && getAlertListPageData.value.items) {
      const listMessageIds = [...list.value, ...getAlertListPageData.value.items].map(
        item => item.messageId,
      );
      list.value = [...list.value, ...getAlertListPageData.value.items].filter((record, index) => {
        return listMessageIds.indexOf(record.messageId) === index;
      });
    }
    if (
      getAlertListPageData.value &&
      getAlertListPageData.value.paginator &&
      list.value.length >= getAlertListPageData.value.paginator.totalCount
    ) {
      finished.value = true;
    }
  }, 500);
}

const urlList = [
  'dashboard.yololiv.com',
  'dashboard-pre.yololiv.com',
  'yolo-dashboard-test.yololiv.com',
  'dashboard-dev.yololiv.com',
];

const sure = async (item: INotifications) => {
  if (item.link) {
    if (urlList.some((url: string) => new RegExp(url).test(item.link))) {
      if (item.link.split('?couponId=').length > 1) {
        const couponId = item.link.split('?couponId=')[1];
        await bindCouponRun({
          couponId: couponId as string,
          email: userInfo.value!.email,
        });
        if (bindCouponError.value) return;
      }
      window.location.href = item.link.split('?couponId=')[0];
    } else {
      window.open(item.link);
    }
  }
};

onMounted(() => {
  onLoad();
});
</script>

<template>
  <div class="page-notifi-popover">
    <div class="notifi-mask" @click="emits('close')"></div>
    <div class="notifi-content">
      <div class="notifi-title">
        <span>Notifications</span>
        <i @click="emits('close')"></i>
      </div>
      <div class="notifi-list">
        <van-list
          v-model:loading="getAlertListPageLoading"
          :finished="finished"
          finished-text="no more information"
          loading-text="loading..."
          offset="30"
          :immediate-check="false"
          @load="onLoad"
        >
          <div class="notifi-item" v-for="item in list" :key="item.messageId">
            <div class="item-time">
              <div class="time-value">{{ formatTime(item.startTime) }}</div>
              <div class="time-line"></div>
            </div>
            <div class="item-img" v-if="item.coverUrl">
              <a target="_blank" @click="sure(item)" v-if="item.link">
                <img :src="item.coverUrl" alt="" />
              </a>
              <img :src="item.coverUrl" alt="" v-else />
            </div>
            <div class="item-title" v-if="item.title">
              <a v-if="item.link" @click="sure(item)">{{ item.title }}</a>
              <span v-else>{{ item.title }}</span>
            </div>
            <div class="item-desc" v-if="item.description">
              <a v-if="item.link" @click="sure(item)">{{ item.description }}</a>
              <span v-else>{{ item.description }}</span>
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page-notifi-popover {
  width: 400px;

  .notifi-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 230;
  }

  .notifi-content {
    position: relative;
    z-index: 240;
    background: #fff;
  }

  .notifi-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    padding: 0 20px;
    font-size: 18px;
    font-weight: 600;
    line-height: 48px;
    color: #1d2129;
    background: #fff;
    box-shadow: 0 3px 8px 0 rgba(29, 33, 41, 0.04);

    i {
      display: block;
      width: 24px;
      height: 24px;
      cursor: pointer;
      background: url(@/assets/images/notifications/icon-close-notifi.png) no-repeat;
      background-size: 100%;
    }
  }

  .notifi-list {
    width: 100%;
    height: calc(100vh - 125px);
    padding: 5px 20px;
    overflow-y: auto;

    .notifi-item {
      .item-time {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;

        .time-value {
          margin-right: 5px;
          font-size: 12px;
          color: #4e5969;
        }

        .time-line {
          flex: 1;
          height: 1px;
          border: 1px solid #e5e6eb;
        }
      }

      .item-img {
        width: 100%;
        height: 180px;
        margin-bottom: 15px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .item-title {
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: #1d2129;
        word-break: break-word;

        a {
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          color: #1d2129;
          word-break: break-word;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .item-desc {
        margin-bottom: 15px;
        font-size: 14px;
        line-height: 16px;
        color: #1d2129;
        word-break: break-word;

        a {
          font-size: 14px;
          line-height: 16px;
          color: #1d2129;
          word-break: break-word;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>
