<script setup lang="ts">
import FirstStep from './coms/first-step.vue';
import SecondStep from './coms/second-step.vue';
import ThirdStep from './coms/third-step.vue';
const STEP = 3;
const currentStep = ref(1);
const loopleft = computed(() => {
  return `translate(${-(currentStep.value - 1) * 33.33 + '%'}, 0)`;
});
const firstStepRef = ref();
const secondStepRef = ref();
const thirdStepRef = ref();
const bannerRef = ref();
const loopactiontop = ref('0px');

function changeStep(type: 'prev' | 'next') {
  if (type === 'prev') {
    if (currentStep.value === 1) return;
    currentStep.value--;
  } else {
    if (currentStep.value === STEP) return;
    currentStep.value++;
  }
  firstStepRef.value.stopPlayer();
  secondStepRef.value.stopPlayer();
  thirdStepRef.value.stopPlayer();
  let timer: any = setTimeout(() => {
    if (currentStep.value === 1) {
      firstStepRef.value.startPlayer();
    }
    if (currentStep.value === 2) {
      secondStepRef.value.startPlayer();
    }
    if (currentStep.value === 3) {
      thirdStepRef.value.startPlayer();
    }
    clearTimeout(timer);
    timer = null;
  }, 100);
}

onMounted(async () => {
  await nextTick();
  loopactiontop.value = bannerRef.value.clientHeight - 70 + 'px';
});
window.onresize = () => {
  if (bannerRef.value && bannerRef.value.clientHeight) {
    loopactiontop.value = bannerRef.value.clientHeight - 70 + 'px';
  }
};
</script>

<template>
  <div class="layout-user">
    <div class="layout-user__main">
      <div class="layout-user__content">
        <div class="yololiv-login-logo">
          <img src="@/assets/images/login/icon-yoloLiv-login.png" alt="" />
          <i></i>
          <span>YoloCast</span>
        </div>
        <router-view></router-view>
      </div>
      <div class="layout-user__banner">
        <div class="banner-content" ref="bannerRef">
          <div class="content-loop-box">
            <div class="item-content first-content">
              <FirstStep ref="firstStepRef"></FirstStep>
            </div>
            <div class="item-content second-content">
              <SecondStep ref="secondStepRef"></SecondStep>
            </div>
            <div class="item-content third-content">
              <ThirdStep ref="thirdStepRef"></ThirdStep>
            </div>
          </div>
        </div>
        <div class="loop-action">
          <span>{{ currentStep }}/{{ STEP }}</span>
          <div
            class="loop-btn"
            :class="{
              'btn-prev-icon': currentStep !== 1,
              'btn-prev-icon-disabled': currentStep === 1,
            }"
            @click="changeStep('prev')"
          ></div>
          <div
            class="loop-btn"
            :class="{
              'btn-next-icon': currentStep !== STEP,
              'btn-next-icon-disabled': currentStep === STEP,
            }"
            @click="changeStep('next')"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.layout-user {
  height: 100vh;
  background: rgba(0, 0, 0, 0.04);
}

.layout-user__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 60px;

  img {
    height: 30px;
  }
}

.layout-user__main {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.layout-user__banner {
  position: relative;
  width: calc(100% - 560px);
  height: 100vh;
  overflow: auto;
  background: url(@/assets/images/logo-banner.png) no-repeat;
  background-size: cover;

  .loop-action {
    position: absolute;
    top: v-bind(loopactiontop);
    right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin-right: 20px;
      font-family: Montserrat-Regular, Montserrat;
      font-size: 24px;
      font-weight: 400;
      color: #fff;
      letter-spacing: 4px;
    }

    .loop-btn {
      width: 48px;
      height: 48px;
      cursor: pointer;
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 50%;
    }

    .btn-prev-icon {
      margin-right: 20px;
      background: url(@/assets/images/login-prev-icon.png) no-repeat center;
      background-size: 28px;

      &:hover {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }

    .btn-prev-icon-disabled {
      margin-right: 20px;
      background: url(@/assets/images/login-prev-icon-disabled.png) no-repeat center;
      background-size: 28px;
    }

    .btn-next-icon {
      background: url(@/assets/images/login-next-icon.png) no-repeat center;
      background-size: 28px;

      &:hover {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }

    .btn-next-icon-disabled {
      background: url(@/assets/images/login-next-icon-disabled.png) no-repeat center;
      background-size: 28px;
    }
  }

  .banner-content {
    position: relative;
    width: 100%;
    min-width: 740px;
    max-width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    overflow-x: hidden;

    .content-loop-box {
      display: flex;
      width: 300%;
      transition: all 0.3s;
      transform: v-bind(loopleft);

      .item-content {
        width: 33.333%;
      }
    }
  }
}

.layout-user__content {
  width: 560px;
  height: 100vh;
  padding: 40px 70px;
  overflow-y: auto;
  background: #fff;

  .yololiv-login-logo {
    display: flex;
    align-items: center;
    margin-bottom: 72px;

    img {
      width: 120px;
      height: 32px;
    }

    i {
      display: block;
      width: 1px;
      height: 14px;
      margin: 0 15px;
      background: #c9cdd4;
    }

    span {
      font-family: Montserrat-Bold, Montserrat;
      font-size: 24px;
      font-weight: bold;
      color: #142280;
    }
  }
}

.layout-user {
  :deep {
    .ant-form-item {
      margin-bottom: 18px;
    }

    .ant-form-item-label {
      padding-bottom: 0 !important;
      margin-bottom: 2px;

      ::before {
        font-weight: bold !important;
      }

      label {
        font-family: Montserrat-SemiBold, Montserrat;
        font-size: 16px !important;
        line-height: 20px;
        color: #1d2129 !important;
      }
    }

    .ant-form-item-control {
      .ant-form-item-control-input {
        input,
        .ant-select-selector,
        .ant-input-password {
          display: flex;
          align-items: center;
          font-size: 14px !important;
          line-height: 20px;
        }

        .ant-select-selector {
          height: 40px;
        }
      }

      .ant-input-password {
        padding: 0 10px 0 12px !important;
        line-height: 32px !important;

        input {
          padding-left: 1px !important;
        }
      }
    }

    .anticon-eye,
    .anticon-eye-invisible {
      width: 20px;
      height: 20px;

      svg {
        display: none;
      }
    }

    .anticon-eye {
      background: url('@/assets/images/icon-eyes3-hidden.png') no-repeat center/cover;
    }

    .anticon-eye-invisible {
      background: url('@/assets/images/icon-eyes3.png') no-repeat center/cover;
    }

    .ant-form-item-explain,
    .ant-form-item-extra {
      height: 0;
      min-height: unset;
      font-size: 12px;
      white-space: nowrap;
    }

    .submit-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      font-size: 16px;
      border-radius: 2px;
    }
  }
}
</style>
