<script setup lang="ts">
import throttle from 'lodash/throttle';

import { formatDuration, insertAfter } from '@/utils/tools';

export interface Instance {
  getPlayerInstance: () => any;
}

const STEP_SECOND = 5;
const STEP_VOLUME = 0.1;
const KEYCODE_BLANK = 32;
const KEYCODE_LEFT = 37;
const KEYCODE_UP = 38;
const KEYCODE_RIGHT = 39;
const KEYCODE_DOWN = 40;
const SHFIT_SHOW_TIME = 90;
const STEP_SECOND_SHIFT = 10;

const props = defineProps({
  id: String,
  live: {
    type: Boolean,
    default: false,
  },
  loop: {
    type: Boolean,
    default: false,
  },
  autoplay: {
    type: Boolean,
    default: false,
  },
  muted: {
    type: Boolean,
    default: false,
  },
  poster: {
    type: String,
    default: '',
  },
  subtitles: String,
  src: String,
  startTime: Number,
  timeShiftUrl: String,
});

const emits = defineEmits(['playing', 'error']);
const { id, live, loop, autoplay, muted, poster, src, subtitles, startTime, timeShiftUrl } =
  toRefs(props);
const player = ref();
const playRender = ref(false);
const timer = ref(); // 重新播放的轮训timer
const playerWrapId = `tcplayer-${+new Date()}`;
const isPlayerInner = ref(false);
const tcplayerRef = ref();
const volumeVisible = ref(false);
const volumeValue = ref(0);
const volumeVisibleTimer = ref(); // 声音动画的timer
const networkErrorDuration = ref(0); // 断网持续时间
const networkErrorDurationTimer = ref(); // 断网持续时间 timer
const liveCurrentTime = ref(0);
const liveCurrentTimeTimer = ref();
const isShift = ref(false); // 当前是否时移
const shiftCurrentTime = ref(0); // 当前开始时移时间(当前最后播放时间)
const shiftAllTimeDistance = ref(0); // 当前进度条 shiftCurrentTime 距离 开始时间 毫秒数
const shiftTimeDistance = ref(0); // 当前进度条点击处距离开始毫秒数 使用进度条百分比计算
const shiftDrag = ref(false);
const shiftDelay = ref(0);

onMounted(async () => {
  await nextTick();
  renderPlayer();
});

onUnmounted(() => {
  if (player.value) {
    player.value.dispose();
    player.value = null;
    document.getElementById(id!.value!)?.remove();
  }
  uninstallEvent();
});

watch(
  () => ({
    loop: loop.value,
    autoplay: autoplay.value,
    muted: muted.value,
    src: src?.value,
    subtitles: subtitles?.value,
    poster: poster?.value,
  }),
  async () => {
    await nextTick();
    renderPlayer();
  },
  {
    deep: true,
  },
);

const installEvent = () => {
  window.removeEventListener('keydown', KeyDownFn);
  window.addEventListener('keydown', KeyDownFn);
  window.removeEventListener('click', clickFn);
  window.addEventListener('click', clickFn);
};

const uninstallEvent = () => {
  window.removeEventListener('keydown', KeyDownFn);
  window.removeEventListener('click', clickFn);
};

function clickFn(event: any) {
  const e = event || window.event || arguments.callee.caller.arguments[0];
  const targetClassList = e.target?.classList;
  if (!targetClassList) {
    isPlayerInner.value = false;
    return;
  }
  isPlayerInner.value = Array.from(targetClassList).some(
    (item: any) => item.includes('vjs') || item.includes('custom'),
  );
}

function KeyDownFn(event: any) {
  if (!player.value) return;
  const e = event || window.event || arguments.callee.caller.arguments[0];
  const key = e.keyCode;
  if (['INPUT', 'TEXTAREA'].includes(e.target?.nodeName)) {
    return;
  }
  if ([KEYCODE_UP, KEYCODE_DOWN].includes(key)) {
    if (!isPlayerInner.value) return;
  }
  if ([KEYCODE_BLANK, KEYCODE_LEFT, KEYCODE_UP, KEYCODE_RIGHT, KEYCODE_DOWN].includes(key)) {
    e.preventDefault();
  }
  const duration = player.value.duration();
  const currentTime = player.value.currentTime();
  const paused = player.value.paused();
  const volume = player.value.volume();
  if (key == KEYCODE_LEFT) {
    //左
    if (live.value) {
      // 直播时键盘左键时移
      shiftKeyCodeLeft();
      return;
    }
    player.value.currentTime(Math.max(currentTime - STEP_SECOND, 0));
    if (paused) {
      player.value.play();
    }
  } else if (key == KEYCODE_RIGHT) {
    //右
    if (live.value) {
      // 直播时键盘右键时移
      shiftKeyCodeRight();
      return;
    }
    player.value.currentTime(Math.min(currentTime + STEP_SECOND, duration));
    if (paused) {
      player.value.play();
    }
  } else if (key == KEYCODE_UP) {
    // 上
    player.value.volume(Math.min(volume + STEP_VOLUME, 1));
    showVolume(player.value.volume());
  } else if (key == KEYCODE_DOWN) {
    // 下
    player.value.volume(Math.max(volume - STEP_VOLUME, 0));
    showVolume(player.value.volume());
  } else if (key == KEYCODE_BLANK) {
    // 空格
    if (paused) {
      player.value.play();
    } else {
      player.value.pause();
    }
  }
}

const shiftKeyCodeLeft = () => {
  if (timeShiftUrl?.value) {
    if (shiftDelay.value === 0) {
      isShift.value = true;
      insetReloadDom(src!.value!);
    }
    shiftDelay.value += STEP_SECOND_SHIFT;
    shiftCurrentTime.value = +new Date();
    shiftAllTimeDistance.value = shiftCurrentTime.value - (startTime?.value || 0);
    shiftTimeDistance.value = shiftAllTimeDistance.value - shiftDelay.value * 1000 || 0;
    if (shiftTimeDistance.value <= 0) {
      shiftTimeDistance.value = 0;
      return;
    }

    player.value.src(`${timeShiftUrl.value}delay=${shiftDelay.value}`);
    player.value.play();
  }
};

const shiftKeyCodeRight = () => {
  if (timeShiftUrl?.value) {
    if (isShift.value) {
      shiftDelay.value -= STEP_SECOND_SHIFT;
      shiftCurrentTime.value = +new Date();
      shiftAllTimeDistance.value = shiftCurrentTime.value - (startTime?.value || 0);
      shiftTimeDistance.value = shiftAllTimeDistance.value - shiftDelay.value * 1000 || 0;
      if (shiftDelay.value <= 0) {
        isShift.value = false;
        shiftDelay.value = 0;
        insetReloadDom(src!.value!);
        player.value.src(src?.value);
        player.value.play();
        const processPlayDom = document.querySelector('.custom-progress-play') as HTMLDivElement;
        if (processPlayDom) {
          processPlayDom.style.width = '100%';
          shiftTimeDistance.value = shiftAllTimeDistance.value;
        }
        return false;
      }
      player.value.src(`${timeShiftUrl.value}delay=${shiftDelay.value}`);
      player.value.play();
    }
  }
};

const showVolume = (volume: number) => {
  volumeVisible.value = true;
  volumeValue.value = Math.round(volume * 100);
  if (volumeValue.value === 0) {
    player.value.muted(true);
  } else {
    player.value.muted(false);
  }
  if (volumeVisibleTimer.value) clearTimeout(volumeVisibleTimer.value);
  volumeVisibleTimer.value = setTimeout(() => {
    volumeVisible.value = false;
  }, 1000);
};

const insetReloadDom = (palyUrl: string) => {
  const volumeDom = document.querySelector('.vjs-volume-panel');
  let reloadDom = document.querySelector('.vjs-reload') as HTMLButtonElement;
  if (!reloadDom) {
    reloadDom = document.createElement('button');
    reloadDom.classList.add('vjs-reload-control', 'vjs-control', 'vjs-button', 'vjs-reload');
  }
  const liveDom = document.querySelector('.vjs-live-display') as HTMLDivElement;
  if (isShift.value) {
    reloadDom.innerHTML = 'BACK TO LIVE';
    reloadDom.title = '';
    reloadDom.style.marginRight = '2.4em';
    reloadDom.style.marginLeft = '1.4em';
    if (liveDom) {
      liveDom.innerHTML = '<span class="vjs-control-text" >Stream Type</span>DVR';
    }
  } else {
    reloadDom.innerHTML = '<span aria-hidden="true" class="vjs-icon-placeholder"></span>';
    reloadDom.title = 'reload';
    reloadDom.style.marginRight = '0';
    reloadDom.style.marginLeft = '0';
    if (liveDom) {
      liveDom.innerHTML = '<span class="vjs-control-text">Stream Type</span>LIVE';
    }
  }
  insertAfter(reloadDom, volumeDom);
  const fn = () => {
    player.value.src(palyUrl);
    player.value.play();
    isShift.value = false;
    shiftDelay.value = 0;
    reloadDom.innerHTML = '<span aria-hidden="true" class="vjs-icon-placeholder"></span>';
    reloadDom.title = 'reload';
    reloadDom.style.marginRight = '0';
    reloadDom.style.marginLeft = '0';
    if (liveDom) {
      liveDom.innerHTML = '<span class="vjs-control-text">Stream Type</span>LIVE';
    }
    const processPlayDom = document.querySelector('.custom-progress-play') as HTMLDivElement;
    if (processPlayDom) {
      processPlayDom.style.width = '100%';
      shiftTimeDistance.value = shiftAllTimeDistance.value;
    }
  };
  reloadDom.removeEventListener('click', fn, false);
  reloadDom.addEventListener('click', fn, false);
};

const processPlayTipDomStyleFixed = (isMove: boolean) => {
  const processHolderDom = document.querySelector('.custom-progress-holder') as HTMLDivElement;
  const processPlayTipDom = document.querySelector(
    '.custom-progress-play .custom-time-tooltip',
  ) as HTMLDivElement;
  const { right: holderRight, left: holderLeft } = processHolderDom.getBoundingClientRect();
  const { right: playTipRight, left: playTipLeft } = processPlayTipDom.getBoundingClientRect();
  if (playTipRight > holderRight) {
    processPlayTipDom.classList.remove('custom-time-tooltip--start');
    processPlayTipDom.classList.add('custom-time-tooltip--end');
  } else {
    if (!isMove) processPlayTipDom.classList.remove('custom-time-tooltip--end');
  }
  if (playTipLeft < holderLeft) {
    processPlayTipDom.classList.remove('custom-time-tooltip--end');
    processPlayTipDom.classList.add('custom-time-tooltip--start');
  } else {
    if (!isMove) processPlayTipDom.classList.remove('custom-time-tooltip--start');
  }
};

const mouseDisplayTipDomStyleFied = (isMove: boolean) => {
  const processHolderDom = document.querySelector('.custom-progress-holder') as HTMLDivElement;
  const mouseDisplayTipDom = document.querySelector(
    '.custom-mouse-display .custom-time-tooltip',
  ) as HTMLDivElement;
  const { right: holderRight, left: holderLeft } = processHolderDom.getBoundingClientRect();
  const { right: mouseTipRight, left: mouseTipLeft } = mouseDisplayTipDom.getBoundingClientRect();
  if (mouseTipRight > holderRight) {
    mouseDisplayTipDom.classList.remove('custom-time-tooltip--start');
    mouseDisplayTipDom.classList.add('custom-time-tooltip--end');
  } else {
    if (!isMove) mouseDisplayTipDom.classList.remove('custom-time-tooltip--end');
  }
  if (mouseTipLeft < holderLeft) {
    mouseDisplayTipDom.classList.remove('custom-time-tooltip--end');
    mouseDisplayTipDom.classList.add('custom-time-tooltip--start');
  } else {
    if (!isMove) mouseDisplayTipDom.classList.remove('custom-time-tooltip--start');
  }
};

const processHolderDomAddEventListener = () => {
  if (!timeShiftUrl?.value) return;
  const processHolderDom = document.querySelector('.custom-progress-holder') as HTMLDivElement;
  const processPlayDom = document.querySelector('.custom-progress-play') as HTMLDivElement;
  const processPlayTipDom = document.querySelector(
    '.custom-progress-play .custom-time-tooltip',
  ) as HTMLDivElement;
  const mouseDisplayDom = document.querySelector('.custom-mouse-display') as HTMLDivElement;
  const mouseDisplayTipDom = document.querySelector(
    '.custom-mouse-display .custom-time-tooltip',
  ) as HTMLDivElement;
  if (
    [processHolderDom, processPlayDom, processPlayTipDom, mouseDisplayDom, mouseDisplayTipDom].some(
      item => !item,
    )
  ) {
    return;
  }
  const fn = (e?: MouseEvent) => {
    isShift.value = true;
    const left = processHolderDom.getBoundingClientRect().left;
    const right = processHolderDom.getBoundingClientRect().right;
    const clientX = e ? e.clientX : right;
    const clientW = processHolderDom.clientWidth;
    const offsetX = Math.min(Math.round(clientX - left), clientW);
    shiftCurrentTime.value = +new Date();
    shiftAllTimeDistance.value = shiftCurrentTime.value - (startTime?.value || 0);
    shiftTimeDistance.value = (offsetX / clientW) * shiftAllTimeDistance.value || 0;
    processPlayTipDom.textContent = formatDuration(Math.round(shiftTimeDistance.value / 1000));
    if (e) {
      shiftDelay.value = (shiftAllTimeDistance.value - shiftTimeDistance.value) / 1000;
      player.value.src(`${timeShiftUrl.value}delay=${shiftDelay.value}`);
      player.value.play();
      insetReloadDom(src!.value!);
    }
    processPlayDom.style.width = (offsetX / clientW) * 100 + '%';
  };
  const mousedownFn = () => {
    processPlayTipDomStyleFixed(false);
    mouseDisplayTipDomStyleFied(false);
    shiftDrag.value = true;
    processHolderDom.classList.add('custom-progress-holder--drag');
  };
  const restFn = (e?: MouseEvent) => {
    const left = processHolderDom.getBoundingClientRect().left;
    const right = processHolderDom.getBoundingClientRect().right;
    const clientX = e ? e.clientX : right;
    const clientW = processHolderDom.clientWidth;
    const offsetX = Math.min(Math.round(clientX - left), clientW);
    const restTimeDistance = (1 - offsetX / clientW) * (+new Date() - (startTime?.value || 0)) || 0;
    mouseDisplayTipDom.textContent = '-' + formatDuration(Math.round(restTimeDistance / 1000));
    mouseDisplayDom.style.left = offsetX + 'px';
    mouseDisplayTipDomStyleFied(false);
  };
  const mouseupFn = () => {
    shiftDrag.value = false;
    processHolderDom.classList.remove('custom-progress-holder--drag');
  };
  const mousemoveFn = throttle((event: MouseEvent) => {
    if (!shiftDrag.value) return;
    const e = event || window.event;
    fn(e);
  }, 500);
  processHolderDom.removeEventListener('mousedown', mousedownFn);
  window.removeEventListener('mouseup', mouseupFn);
  window.removeEventListener('mousemove', mousemoveFn);
  processHolderDom.removeEventListener('mousemove', restFn);
  processHolderDom.addEventListener('mousedown', mousedownFn);
  window.addEventListener('mouseup', mouseupFn);
  window.addEventListener('mousemove', mousemoveFn);
  processHolderDom.addEventListener('mousemove', restFn);
  processHolderDom.removeEventListener('click', fn);
  processHolderDom.addEventListener('click', fn, false);
  fn();
};

/** 时移 */
const insetLiveShift = () => {
  const controlDom = document.querySelector('.vjs-control-bar');
  if (!controlDom) return;
  const processDom = document.createElement('div');
  processDom.classList.add('custom-progress-control');
  processDom.innerHTML = `
    <div class="custom-progress-holder">
      <div class="custom-progress-play">
        <div class="custom-time-tooltip">--:--</div>
      </div>
      <div class="custom-mouse-display">
        <div class="custom-time-tooltip">- --:--</div>
      </div>
    </div>
  `;
  controlDom.appendChild(processDom);
  processDom.style.opacity = '0';
  processDom.style.visibility = 'hidden';
  setTimeout(() => {
    processHolderDomAddEventListener();
    processPlayTipDomStyleFixed(false);
  }, 200);
};

/** safria 第一次播放不能播放的问题 */
const loopPlay = (palyUrl: string) => {
  if (!player.value) return;
  player.value.src(palyUrl);
  player.value.play();
  if (playRender.value) return;
  clearTimeout(timer.value);
  timer.value = setTimeout(() => {
    loopPlay(palyUrl);
  }, 5000);
};

const liveNetworkError = (duration = 5) => {
  if (!player.value) return;
  if (networkErrorDuration.value >= duration) {
    if (networkErrorDurationTimer.value) {
      clearTimeout(networkErrorDurationTimer.value);
    }
    networkErrorDuration.value = 0;
    player.value.src(src?.value);
    player.value.play();
    return;
  }
  networkErrorDuration.value = networkErrorDuration.value + 1;
  clearTimeout(networkErrorDurationTimer.value);
  networkErrorDurationTimer.value = setTimeout(liveEndedHandle, 1000);
};

const liveErrorHandle = () => {
  liveNetworkError(2);
};

const liveEndedHandle = () => {
  liveNetworkError(5);
};

const liveTimeupdateHandle = () => {
  if (!player.value) return;
  if (player.value.paused()) {
    clearTimeout(liveCurrentTimeTimer.value);
    return;
  }
  liveCurrentTime.value = player.value.currentTime();
  clearTimeout(liveCurrentTimeTimer.value);
  liveCurrentTimeTimer.value = setTimeout(() => {
    if (!player.value) return;
    if (player.value.currentTime() === liveCurrentTime.value) {
      player.value.src(src?.value);
      player.value.play();
    }
  }, 5 * 1000);
};

const liveTimeupdateShiftHandle = () => {
  const processDom = document.querySelector('.custom-progress-control') as HTMLDivElement;
  const processPlayTipDom = document.querySelector(
    '.custom-progress-play .custom-time-tooltip',
  ) as HTMLDivElement;
  if (!processDom || !processPlayTipDom) {
    return;
  }
  const currentTime = +new Date();
  const runTimeDistance = currentTime - shiftCurrentTime.value;
  shiftTimeDistance.value = shiftTimeDistance.value + runTimeDistance || 0;
  if (shiftTimeDistance.value > SHFIT_SHOW_TIME * 1000 && shiftCurrentTime.value) {
    processDom.style.visibility = 'visible';
    processDom.style.opacity = '1';
  }
  shiftCurrentTime.value = currentTime;
  processPlayTipDom.textContent = formatDuration(Math.round(shiftTimeDistance.value / 1000));
  const shiftTimeDistancePercent = shiftTimeDistance.value / shiftAllTimeDistance.value;
  if (shiftTimeDistancePercent > 1) {
    isShift.value = false;
    shiftDelay.value = 0;
    return;
  }
  const processPlayDom = document.querySelector('.custom-progress-play') as HTMLDivElement;
  if (!processPlayDom) return;
  processPlayDom.style.width = shiftTimeDistancePercent * 100 + '%';
  processPlayTipDomStyleFixed(true);
};

const renderPlayer = async () => {
  if (!id?.value || !src?.value) return;
  player.value?.dispose();
  document.getElementById(id.value)?.remove();
  const video = document.createElement('video');
  video.id = id.value;
  playRender.value = false;
  video.setAttribute('playsinline', 'true');
  video.setAttribute('webkit-playsinline', 'true');
  video.classList.add('tcplayer-inner');
  document.getElementById(playerWrapId)?.appendChild(video);
  await nextTick();
  player.value = TCPlayer(id.value, {
    language: 'en',
    loop: loop.value,
    autoplay: autoplay.value,
    muted: muted.value,
    poster: poster.value,
  });
  player.value.on('playing', () => {
    playRender.value = true;
    clearTimeout(timer.value);
    emits('playing');
  });
  player.value.on('error', (error: any) => {
    if (!playRender.value) return;
    emits('error', error, { isShift: isShift.value });
    if (!live.value) return;
    liveErrorHandle();
  });
  /** 解决断流之后恢复网络没有重新拉流的问题 */
  player.value.on('timeupdate', () => {
    if (!live.value) return;
    liveTimeupdateHandle();
    if (timeShiftUrl?.value) {
      liveTimeupdateShiftHandle();
    }
  });
  player.value.on('ended', () => {
    console.log('ended');
    if (!live.value) return;
    liveEndedHandle();
  });
  if (live.value) {
    player.value.ready(() => {
      /** 直播的时候添加刷新按钮 */
      insetReloadDom(src.value!);
      /** 直播的时候添加时移进度条 */
      if (timeShiftUrl?.value) {
        insetLiveShift();
      }
      /** 解决直播偶尔第一次播放不能播放的问题 */
      loopPlay(src.value!);
      installEvent();
    });
    return;
  }
  player.value.ready(() => {
    installEvent();
    if (subtitles?.value) {
      player.value.addRemoteTextTrack(
        {
          src: subtitles.value,
          kind: 'subtitles',
          srclang: 'en',
          label: 'Caption',
          default: 'true',
        },
        true,
      );
    }
  });

  player.value.src(src.value);
};

const getPlayerInstance = () => {
  return player.value;
};

defineExpose({
  getPlayerInstance,
});
</script>

<template>
  <div class="tcplayer-wrap">
    <div class="tcplayer" :id="playerWrapId" ref="tcplayerRef"></div>
    <transition name="fade">
      <div class="volume-tip" v-show="volumeVisible">
        <img src="@/assets/images/icon-volume.png" alt="volume" /> {{ volumeValue }} %
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.tcplayer-wrap {
  position: relative;
  width: 100%;
  height: 100%;
}

.tcplayer {
  :deep {
    .tcplayer-inner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }

    .vjs-error-display {
      display: none !important;
    }

    .vjs-big-play-button {
      font-size: 15px !important;
    }

    .vjs-reload-control.vjs-reload {
      width: auto;
      min-width: 4em;
      cursor: pointer;
    }

    .vjs-menu-button-popup .vjs-menu {
      bottom: -4px;

      .vjs-menu-content {
        bottom: 1.3em;
      }
    }

    // todo
    // .vjs-control-bar {
    //   opacity: 1 !important;
    // }

    .custom-progress-control {
      position: absolute;
      top: -0.9em;
      left: 0;
      display: flex;
      flex: auto;
      align-items: center;
      width: 100%;
      min-width: 4em;
      height: 1.5em;
      cursor: pointer;

      &:hover {
        font-size: 1.666666666666666666em;
      }
    }

    .custom-progress-holder {
      position: relative;
      flex: auto;
      height: 0.3em;
      padding: 0;
      margin: 0 10px;
      cursor: pointer;
      user-select: none;
      background-color: rgba(115, 133, 159, 0.5);
      transition: all 0.2s;

      &:hover {
        .custom-progress-play {
          &::before {
            opacity: 1;
          }

          .custom-time-tooltip {
            opacity: 1;
          }
        }

        .custom-mouse-display {
          opacity: 1;

          .custom-time-tooltip {
            opacity: 1;
          }
        }
      }
    }

    .custom-progress-holder--drag {
      .custom-progress-play {
        &::before {
          opacity: 1;
        }

        .custom-time-tooltip {
          opacity: 1;
        }
      }
    }

    .custom-progress-play {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      font-family: VideoJS, Roboto-Regular, Roboto-Medium, Roboto-Bold, Roboto, -apple-system,
        BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      background: #f43;
      transition: all 0.05s;

      &::before {
        position: absolute;
        top: -0.333333333333333em;
        right: -0.5em;
        z-index: 2;
        box-sizing: inherit;
        font-size: 0.9em;
        content: '\f111';
        opacity: 0;
        transition: all 0.2s;
      }

      .custom-time-tooltip {
        color: #000;
        background: rgba(255, 255, 255, 0.8);
      }
    }

    .custom-mouse-display {
      position: absolute;
      right: 0;
      z-index: 1;
      width: 1px;
      height: 100%;
      font-family: VideoJS, Roboto-Regular, Roboto-Medium, Roboto-Bold, Roboto, -apple-system,
        BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
      background: #000;
      opacity: 0;
    }

    .custom-time-tooltip {
      position: absolute;
      top: -3.4em;
      right: 0;
      z-index: 1;
      padding: 4px 8px 7px 8px;
      font-size: 12px;
      line-height: 14px;
      pointer-events: none;
      background: rgba(0, 0, 0, 0.8);
      border-radius: 0.3em;
      opacity: 0;
      transition: all 0.2s;
      transform: translateX(50%);
    }

    .custom-time-tooltip--end {
      transform: translateX(0);
    }

    .custom-time-tooltip--start {
      transform: translateX(100%);
    }
  }
}

.volume-tip {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84px;
  height: 30px;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  transform: translate(-50%, -50%);

  img {
    width: 24px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
