/**
 *  媒体转码状态
 */
export const enum TranscodeStatus {
  /** 视频未转码 */
  noTranscode = 0,
  /** 视频转码中 */
  transcoding = 1,
  /** 视频转码失败 */
  transcodeFail = 2,
  /** 视频转码成功 */
  transcodeSuccess = 3,
}

/**
 *  媒体类型
 */
export enum MediaType {
  /** 图片 */
  Image = 'image',
  /** 视频 */
  Video = 'video',
  /** 文档 */
  Document = 'document',
  /** 视频回放 */
  LiveEvent = 'playback',
  /** 图层 */
  Overlays = 'overlay',
  /** 图层模板 */
  Templates = 'template',
  /** 字幕 */
  VTT = 'vtt',
  /** 高光 */
  Highlight = 'highLight',
  /** 回放 */
  Recording = 'clipPlayback',
  /** 其余图层 */
  Others = 'businessLayer',
  /** 音频 */
  Audio = 'audio',
}

/**
 *  上传错误提示
 */
export const fileError: any = {
  /** 资源格式不正确 */
  typeError: 'Invalid file type.',
  /** 资源大小超过设定值 */
  bigError: 'File too large, Max: ',
  /** 图片同时上传不能超过100张 */
  fileMoreError: 'Upload up to 100 images!',
  /** 上传失败，请重新上传 */
  uploadError: 'Upload failed, please try again!',
};

/**
 *  上传状态
 */
export enum UploadType {
  /** m默认 */
  DEFAULT = '',
  /** 上传成功 */
  SUCCESS = 'success',
  /** 上传失败 */
  FAIL = 'fail',
  /** 上传取消 */
  CANCEL = 'cancel',
  /** 上传暂停 */
  STOP = 'stop',
}

/**
 *  媒体中心tab列表
 */
export interface IMediaProps {
  /** 媒体类型。0：回放，1：图片 2：视频 3：文档 4：overlays 5 templates 6 audio */
  type: string; //
  /** 上传的云服务目录，目前无用，可忽略 */
  suffix: string;
  /** 上传的文件类型 */
  uploadType: string;
  /** 上传的文件大小限制 */
  maxSize: number;
  /** 是否允许多选 */
  multiSelection: boolean;
  /** 媒体中心上传按钮名称 */
  mainBtn: string;
  /** 媒体中心新建分组按钮 */
  secondBtn: string;
  /** 分组名称 */
  title: string;
  /** 文件校验类型 */
  validateFormat: Array<string>;
}

/**
 *  媒体分组列表
 */
export interface IGroups {
  /** 分组Id */
  groupId?: string | undefined;
  /** 分组名称 */
  groupName?: string;
  /** 媒体类型 1-图片，2-视频，3-文档， 4-回放 */
  materialType?: string; //
  /** 资源数量 */
  materialCount?: number;
}

/**
 *  媒体库列表
 */
export interface IMediaTable {
  /** 媒体Id */
  materialId: string;
  /** 媒体名称 */
  name: string;
  /** 媒体地址 */
  url: string;
  /** 大小 */
  size: number;
  /** 格式 */
  format: string;
  /** 上传时间 */
  uploadTime?: string;
  /** 视频时长（视频） */
  duration?: string;
  /** 封面 */
  coverUrl: string;
  /** 转码状态: 0-未转码，1-转码中，2-转码失败，3-转码完成 */
  transcodeStatus?: TranscodeStatus;
  /** 码率 */
  bitrate?: number;
  /** 高码率转码中 */
  inTranscoding?: 0 | 1;
  /** 下载地址 */
  downloadUrl: string;
  /** 预览地址 */
  viewUrl: string;
  /**
   * 包含select（素材选中的状态）
   * loading（素材下载、转码的状态）
   * transcoding（转码中）
   * generating（封面生成中）
   * index（选中的序号）
   */
  [propName: string]: any;
}

/**
 *  当前选中的媒体对象（合集， 包括多选）
 */
export interface ICurrentItem {
  /** 媒体Id */
  materialId?: string;
  /** 媒体名称 */
  name?: string;
  /** 媒体地址 */
  url?: string;
  /** 大小 */
  size?: number;
  /** 格式 */
  format?: string;
  /** 上传时间 */
  uploadTime?: string;
  /** 视频时长 */
  duration?: string;
  /** 封面 */
  coverUrl?: string;
  /** 转码状态: 0-未转码，1-转码中，2-转码失败，3-转码完成 */
  transcodeStatus?: TranscodeStatus;
  /** 是否选中 */
  select?: boolean;
  /** 当前选中的所有素材集合 */
  resultList?: Record<any, any>[];
  /** 全部的分组 */
  groups?: Record<any, any>[];
  /** 当前分组 */
  currentGroup?: Record<any, any>;
  /** 处理媒体增删改查时弹窗的标题 */
  title?: string;
  /** 新增或者编辑 */
  mediaType?: string;
  /** 分组的序号 */
  index?: number;
  /** 删除或移除媒体 delete / remove */
  deleteType?: string;
  /** 删除或移除时给用户的描述 */
  deleteWord?: string;
  /** 分组Id */
  groupId?: string;
  /** 分组名称 */
  groupName?: string;
  /** 图片或pdf下载时的下载状态 */
  loading?: boolean;
  /** 媒体类型0，1,2,3 */
  type?: string;
  /** 码率 */
  bitrate?: number;
  /** 高码率转码中 */
  inTranscoding?: 0 | 1;
  /** 下载地址 */
  downloadUrl: string;
  /** 预览地址 */
  viewUrl: string;
  /**
   * transcoding（转码中）
   * generating（封面生成中）
   * transcodeVideoUrl（回放转码后的视频地址）
   */
  [propName: string]: any;
}

/**
 *  批量上传的失败图片
 */
export interface IErrorList {
  /** 图片上传的状态，fail / success */
  type: string;
  /** 图片地址 */
  url?: string;
  /** 图片名称 */
  fileName: string;
}

/**
 *  批量上传的成功图片
 */
export interface ISuccessList {
  /** 图片上传的状态，fail / success */
  type: string;
  /** 图片地址 */
  url: string;
  /** 图片名称 */
  fileName: string;
  /** 图片实际的格式 */
  format: string;
  /** 图片materialId */
  materialId: string;
}
