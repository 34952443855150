<script setup lang="ts">
import { LoadingOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { useRequest } from 'vue-request';

import {
  newPlanSubscription,
  PlanSubPayStatusEnum,
  planSubscription,
  SubscriptionPaypalInfo,
} from '@/api/plan';

const emits = defineEmits(['complete']);
const visible = ref<boolean>(false);
const subscriptionPaypalData = ref<SubscriptionPaypalInfo | null>(null);
const {
  loading: planSubscriptionLoading,
  data: planSubscriptionData,
  run: planSubscriptionRun,
  error: planSubscriptionError,
} = useRequest(planSubscription, {
  manual: true,
  formatResult(res) {
    return res.data;
  },
});
const {
  loading: newPlanSubscriptionLoading,
  data: newPlanSubscriptionData,
  run: newPlanSubscriptionRun,
  error: newPlanSubscriptionError,
} = useRequest(newPlanSubscription, {
  manual: true,
  formatResult(res) {
    return res.data;
  },
});

const indicator = h(LoadingOutlined, {
  style: {
    fontSize: '70px',
  },
  spin: true,
});

function show(data: SubscriptionPaypalInfo) {
  subscriptionPaypalData.value = data;
  visible.value = true;
}

function hide() {
  visible.value = false;
}

async function doPlanSubscription() {
  if (subscriptionPaypalData.value?.id) {
    // 购买套餐结果查询
    await newPlanSubscriptionRun({
      id: subscriptionPaypalData.value?.id,
    });
    if (newPlanSubscriptionError.value) return;
    if (newPlanSubscriptionData.value?.status === PlanSubPayStatusEnum.Wait) {
      message.info('Pay Wait');
    }
    if (newPlanSubscriptionData.value?.status === PlanSubPayStatusEnum.Fail) {
      message.warn('Pay Fail');
    }
    if (newPlanSubscriptionData.value?.status === PlanSubPayStatusEnum.Success) {
      message.success('Pay Success');
      emits('complete');
    }
  } else {
    // 购买资源包结果查询
    await planSubscriptionRun({
      subscriptionId: subscriptionPaypalData.value!.subscriptionId,
    });
    if (planSubscriptionError.value) return;
    if (planSubscriptionData.value?.planSubPayStatus === PlanSubPayStatusEnum.Wait) {
      message.info('Pay Wait');
    }
    if (planSubscriptionData.value?.planSubPayStatus === PlanSubPayStatusEnum.Cancel) {
      message.info('Pay Cancel');
    }
    if (planSubscriptionData.value?.planSubPayStatus === PlanSubPayStatusEnum.Fail) {
      message.warn('Pay Fail');
    }
    if (planSubscriptionData.value?.planSubPayStatus === PlanSubPayStatusEnum.Success) {
      message.success('Pay Success');
      emits('complete');
    }
  }
  hide();
}

defineExpose({
  show,
  hide,
});
</script>

<template>
  <a-modal
    v-model:visible="visible"
    width="520px"
    wrapClassName="modal-setting-pay-result g-noborder-header-modal g-notoppadding-modal"
    @cancel="hide"
    :footer="null"
    :mask-closable="false"
  >
    <template #title>
      <div class="setting-plans-modal-title">
        <i></i>
        {{ subscriptionPaypalData.bizType === 2 ? 'Upgrade' : 'Upgrade Plan' }}
      </div>
    </template>
    <div class="loading-box">
      <a-spin :indicator="indicator" />
    </div>
    <div class="loading-text">Loading···</div>
    <div class="loading-btn">
      <a-button @click="hide" style="margin-right: 20px">Cancel</a-button>
      <a-button
        type="primary"
        @click="doPlanSubscription"
        :loading="planSubscriptionLoading || newPlanSubscriptionLoading"
        >Complete</a-button
      >
    </div>
  </a-modal>
</template>

<style lang="scss">
.modal-setting-pay-result {
  .setting-plans-modal-title {
    display: flex;
    align-items: center;

    i {
      display: block;
      width: 20px;
      height: 20px;
      margin-right: 5px;
      background: url(@/assets/images/setting/icon-find-yourself.png) no-repeat;
      background-size: 100%;
    }
  }

  .loading-box {
    margin-top: 40px;
    margin-bottom: 10px;
    text-align: center;
  }

  .loading-text {
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    color: #1d2129;
    text-align: center;
  }

  .loading-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 58px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 32px;
      padding: 0;
    }
  }
}
</style>
