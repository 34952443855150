export const HOME = 'home';
export const BROADCASTS = 'broadcasts';
export const BROADCASTS_LIST = 'broadcasts_list';
export const BROADCASTSADD = 'broadcasts-add';
export const BROADCASTSUPDATE = 'broadcasts-update';

export const EMBED = 'embed';
export const EMBED_DETAIL = 'embed-detail';
export const EMBED_GENERAL = 'embed-general';
export const EMBED_MAIN = 'embed-main';

export const MEDIA = 'media';
export const REPORTS = 'reports';
export const DETAILEDREPORTS = 'Detailed Reports';
export const NEW_DETAILED_REPORTS = 'New Detailed Reports';
export const SOURCES = 'sources';
export const SETTINGS = 'settings';
export const ACCOUNT = 'settings-account';
export const PERFERENCES = 'settings-perferences';
export const BILLING = 'settings-billing';
export const PLANS = 'settings-plans';
export const PLANS_ADD = 'settings-plans-add';
export const RESOURCES = 'settings-resources';
export const USERS = 'settings-users';
export const COMMISSION = 'settings-commission';
export const PREFERENCES = 'settings-preferences';
export const INTEGRATIONS = 'settings-integrations';
export const PAYMENT = 'settings-payment';
export const STORAGE = 'settings-storage';
export const APIKEYS = 'settings-api-keys';
export const APPEARANCE = 'settings-appearance';
export const USER = 'user';
export const LOGIN = 'login';
export const REGISTER = 'register';
export const PASSWORD_RESET = 'password-reset';
export const DESTINATIONS = 'destinations';
export const THEMES = 'themes';
export const THEMES_DETAIL = 'themes-detail';

export const BROADCAST_DETAIL = 'broadcasts-detail';
export const BROADCAST_SOURCES = 'detail-sources';
export const BROADCAST_OVERVIEW = 'detail-overview';
export const BROADCAST_DESTINATIONS = 'detail-destinations';
export const BROADCAST_DIAGNOSTICS = 'detail-diagnostics';
export const BROADCAST_ANALYTICS = 'detail-analytics';
export const BROADCAST_DOCUMENTS = 'detail-documents';
export const BROADCAST_ADS = 'detail-ads';
export const BROADCAST_CHAT = 'detail-chat';
export const BROADCAST_CHAT_COMMENT = 'detail-chat-comment';
export const BROADCAST_CHAT_HIDDEN = 'detail-chat-hidden';
export const BROADCAST_REPORT = 'detail-report';
export const BROADCAST_REPORT_LIVE_DATA = 'detail-report-live-data';
export const BROADCAST_REPORT_VIEWERS_DATA = 'detail-report-viewers-data';
export const BROADCAST_REPORT_STREAM_DATA = 'detail-report-stream-data';
export const BROADCAST_TRIM = 'detail-trim';
export const BROADCAST_CLIPS = 'detail-clips';
export const BROADCAST_OVERLAYS = 'detail-overlays';
export const BROADCAST_SCOREBOARD = 'detail-scoreboard';
export const RECEIPT = 'receipt';

export const BROADCAST_ALWAYS = 'broadcasts-always';
export const BROADCAST_ALWAYS_SOURCES = 'detail-always-sources';
export const BROADCAST_ALWAYS_OVERVIEW = 'detail-always-overview';
export const BROADCAST_ALWAYS_DESTINATIONS = 'detail-always-destinations';
export const BROADCAST_ALWAYS_DIAGNOSTICS = 'detail-always-diagnostics';
export const BROADCAST_ALWAYS_ANALYTICS = 'detail-always-analytics';
export const BROADCAST_ALWAYS_ADS = 'detail-always-ads';
export const BROADCAST_ALWAYS_DOCUMENTS = 'detail-always-documents';
export const BROADCAST_ALWAYS_CHAT = 'detail-always-chat';
export const BROADCAST_ALWAYS_CHAT_COMMENT = 'detail-always-chat-comment';
export const BROADCAST_ALWAYS_CHAT_HIDDEN = 'detail-always-chat-hidden';
export const BROADCAST_ALWAYS_REPORT = 'detail-always-report';
export const BROADCAST_ALWAYS_REPORT_LIVE_DATA = 'detail-always-report-live-data';
export const BROADCAST_ALWAYS_REPORT_VIEWERS_DATA = 'detail-always-report-viewers-data';
export const BROADCAST_ALWAYS_REPORT_STREAM_DATA = 'detail-always-report-stream-data';
export const BROADCAST_ALWAYS_TRIM = 'detail-always-trim';
export const BROADCAST_ALWAYS_CLIPS = 'detail-always-clips';
export const BROADCAST_ALWAYS_OVERLAYS = 'detail-always-overlays';
export const BROADCAST_ALWAYS_SCOREBOARD = 'detail-always-scoreboard';

export const OUTPUT = 'output';
export const CONTROL = 'control';
export const COMPOSITION = 'composition';
export const URL_OVERLAY = 'overlay-lab';
export const OVERLAYS_LIBRARY = 'overlays-library';
export const OVERLAYS_LIBRARY_OVERVIEWS = 'overlays-overlays';
export const OVERLAYS_LIBRARY_LIST = 'overlays-list';
export const MY_OVERLAYS = 'my-overlays';
export const MY_THIRD_OVERLAYS = 'third-overlays';
export const MY_COMPOSITIONS = 'my-compositions';
export const COUPON = 'coupon';
export const VIDEO_TUTORIALS = 'video-tutorials';
export const YOUTUBE = 'youtube';

export const YOLOCUT_LIST = 'yolocut-list';
export const YOLOCUT_DETAIL = 'yolocut-detail';

export const SUB_EXCLUSIVE = 'sub-exclusive';
export const DEALER = 'dealer';
export const PLAN = 'plan';

export const SUGGESTIONS = 'suggestions';

export const REPORT = 'report';

export const WEB_CONTROL = 'web-control';
export const WEB_CONTROL_LINK = 'web-control-link';
export const WEB_CONTROL_DETAIL = 'web-control-detail';
