import UserLayout from '@/layout/user/index.vue';
import * as ConstantRouter from '@/constant/router';

const route = {
  path: '/user',
  component: UserLayout,
  name: ConstantRouter.USER,
  redirect: '/user/login',
  children: [
    {
      path: 'login',
      name: ConstantRouter.LOGIN,
      component: () => import(/* webpackChunkName: "user-login" */ '@/pages/login/index.vue'),
    },
    {
      path: 'register',
      name: ConstantRouter.REGISTER,
      component: () => import(/* webpackChunkName: "user-register" */ '@/pages/register/index.vue'),
    },
    {
      path: 'password-reset',
      name: ConstantRouter.PASSWORD_RESET,
      component: () =>
        import(/* webpackChunkName: "user-password-reset" */ '@/pages/password-reset/index.vue'),
    },
  ],
};

export default route;
