import RouterHolder from '@/components/router-holder/index.vue';
import loadable from '@/utils/loadable';

const route = {
  path: '/callback',
  component: RouterHolder,
  children: [
    {
      path: 'pay',
      component: loadable(
        () => import(/* webpackChunkName: "callback-pay" */ '@/pages/callback/pay.vue'),
      ),
    },
    {
      path: 'email-verify',
      component: loadable(
        () => import(/* webpackChunkName: "email-verify" */ '@/pages/callback/email-verify.vue'),
      ),
      meta: {
        noVerify: true,
      },
    },
  ],
};

export default route;
