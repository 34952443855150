import { defineStore } from 'pinia';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import '@/assets/styles/introjs.rewrite.scss';

const generateOneIntro = () => {
  const intro = introJs();
  intro.setOptions({
    highlightClass: 'one-highlight',
    tooltipClass: 'one-tooltip',
    showBullets: false,
    showProgress: false,
    exitOnEsc: false,
    exitOnOverlayClick: false,
    showStepNumbers: true,
    disableInteraction: true,
    // @ts-ignore
    stepNumbersOfLabel: '/',
    doneLabel: 'Finish',
  });

  return intro;
};

const generateTwoIntro = () => {
  const intro = introJs();
  intro.setOptions({
    highlightClass: 'two-highlight',
    tooltipClass: 'two-tooltip',
    showBullets: false,
    showProgress: false,
    exitOnEsc: false,
    exitOnOverlayClick: false,
    showStepNumbers: true,
    disableInteraction: true,
    // @ts-ignore
    stepNumbersOfLabel: '/',
    doneLabel: 'Finish',
  });

  return intro;
};

const generateThreeIntro = () => {
  const intro = introJs();
  intro.setOptions({
    highlightClass: 'two-highlight',
    tooltipClass: 'two-tooltip',
    showBullets: false,
    showProgress: false,
    exitOnEsc: false,
    exitOnOverlayClick: false,
    showStepNumbers: true,
    disableInteraction: true,
    doneLabel: 'Next',
    // @ts-ignore
    stepNumbersOfLabel: '/',
  });

  return intro;
};

const useIntro = defineStore('intro', {
  state: () => {
    return {
      one: {
        intro: generateOneIntro(),
        steps: [],
        count: 7,
      },
      two: {
        intro: generateTwoIntro(),
        steps: [],
        count: 2,
      },
      three: {
        intro: generateThreeIntro(),
        steps: [],
        count: 1,
      },
    };
  },
  actions: {
    // @ts-ignore
    oneStepsUpdate(index, stepDefinition) {
      // @ts-ignore
      this.one.steps[index] = stepDefinition;
    },
    // @ts-ignore
    twoStepsUpdate(index, stepDefinition) {
      // @ts-ignore
      this.two.steps[index] = stepDefinition;
    },
    // @ts-ignore
    threeStepsUpdate(index, stepDefinition) {
      // @ts-ignore
      this.three.steps[index] = stepDefinition;
    },
    allInit() {
      this.one.intro = generateOneIntro();
      this.two.intro = generateTwoIntro();
      this.three.intro = generateThreeIntro();
      // 有个帮助dom在每个页面都有 所以不需要删除那个dom
      this.one.steps.fill(undefined, 0, 6);
      this.two.steps = [];
      this.three.steps = [];
    },
  },
});

export default useIntro;
