import { defineStore } from 'pinia';

import { getCurrentPlan, IInvitationCode, PlanItem } from '@/api/plan';
import { getAppliedCode } from '@/api/user';

interface IError {
  message: string;
  code: number;
}

const useStore = defineStore('currentPlan', {
  state: (): {
    loading: boolean;
    error: Nullable<IError>;
    currentPlanData: Nullable<PlanItem>;
    invitationCode: Nullable<IInvitationCode>;
  } => {
    return {
      loading: true,
      error: null,
      currentPlanData: null,
      invitationCode: null,
    };
  },
  getters: {
    isExpired(state) {
      if (!state.currentPlanData) return false;
      return +new Date(state.currentPlanData.expireDate) < +new Date();
    },
    isDefault(state) {
      if (!state.currentPlanData) return false;
      return state.currentPlanData.planType === 2;
    },
  },
  actions: {
    async setCurrentPlan(planData: PlanItem) {
      this.currentPlanData = planData;
      this.loading = false;
    },
    async fetchCurrentPlan() {
      try {
        const { data } = await getCurrentPlan();
        // MOCK 模拟套餐到期
        // data.expireDate = '2022-07-22T15:59:59.000+00:00';
        this.currentPlanData = data;
        return true;
      } catch (error) {
        this.error = error as IError;
        return false;
      } finally {
        this.loading = false;
      }
    },
    async updateCurrentPlan() {
      await this.fetchCurrentPlan();
    },
    async fetchInvitationCode() {
      try {
        const { data } = await getAppliedCode();
        this.invitationCode = data;
      } catch (error) {
        // todo
        // this.error = error as IError;
      }
    },
  },
});

export default useStore;
