import { IGroups, IMediaTable } from '@/pages/media/type';
import request from '@/utils/request';

interface groupReName {
  groupId: string;
}

interface IMediaReName {
  materialId: string;
}

/**
 *  媒体类型
 */
export enum MediaType {
  /** 图片 */
  Image = 1,
  /** 视频 */
  Video = 2,
  /** 文档 */
  Document = 3,
  /** 视频回放 */
  LiveEvent = 4,
  /** 图层 */
  Overlays = 5,
  /** 图层模板 */
  Templates = 6,
}

export enum TranscodeStatus {
  NotStart = 0,
  Transcoding = 1,
  Fail = 2,
  Success = 3,
}

/**
 * 资源数据
 */
export interface MediaInfo {
  coverUrl: string;
  duration: number;
  endTimeOffset: number;
  format: string;
  materialId: string;
  name: string;
  playbackFileId: string;
  playbackUrl: string;
  size: string;
  startTimeOffset: number;
  audioTranscodeUrl: string;
  videoTranscodeUrl: string;
  transcodeStatus: TranscodeStatus;
  videoTranscodeStatus: TranscodeStatus;
  audioTranscodeStatus: TranscodeStatus;
  url: string;
  downloadUrl: string;
}

/**
 *  获取分组列表
 */
export const getMediaGroupList = (data: { type: string }) => {
  return request.get<{ materialTotalCount: number; materialGroupResponseList: Array<IGroups> }>(
    '/api/material/group',
    data,
  );
};

/**
 *  修改媒体分组名字
 */
export const mediaGroupReName = (data: {
  groupId: string | undefined;
  groupName: string;
  type: string;
}) => {
  return request.put<groupReName>('/api/material/group', data);
};

/**
 *  新增媒体素材分组
 */
export const addNewMediaGroup = (data: { groupName: string; type: string }) => {
  return request.post<groupReName>('/api/material/group', data);
};

/**
 *  删除媒体分组
 */
export const groupDelete = (data: { groupId: string }) => {
  return request.delete<any>(`/api/material/group/${data.groupId}`);
};

/**
 *  移动素材分组信息
 */
export const mediaMove = (data: {
  materialIdList: string[];
  targetGroupIdList: string[];
  materialType: string;
}) => {
  return request.post<IMediaReName>('/api/material/group/batchCopy', data);
};

/**
 *  移除素材所在分组
 */
export const mediaRemove = (data: {
  materialIdList: Array<string>;
  groupId: string | undefined;
  materialType: string;
}) => {
  return request.post<any>('/api/material/group/batchRemove', data);
};

interface IMaterialList {
  materialId: string;
  url: string;
  format: string;
  fileName: string;
  fileId?: string;
}

/**
 *  获取文档
 */
export const getDoc = (activityId: string) => {
  return request.get<{ name: string }>(`/api/activity/${activityId}/material/get-doc`);
};

/**
 *  替换回放素材
 */
export const replaceMedia = ({
  activityId,
  materialId,
}: {
  activityId: string;
  materialId: string;
}) => {
  return request.put<boolean>(`/api/activity/${activityId}/material/replace`, { materialId });
};

/**
 *  偷懒了，该接口只能通过id获取视频信息
 *  根据素材,id类型获取素材信息
 */
export const getMaterialById = ({ materialId }: { materialId: string }) => {
  return request.get<MediaInfo>(`/api/material/video`, { materialId });
};

export enum TranscodeTaskType {
  video = 1,
  audio = 2,
  videoNew = 6, // 将视频转格式和码率统一
}

/**
 *  执行音视频转码任务
 */
export const invokeTranscodeVideoAndAudio = ({
  materialId,
  transcodeTaskType,
}: {
  materialId: string;
  transcodeTaskType: TranscodeTaskType;
}) => {
  return request.post<boolean>(`/api/material/invokeTranscodeVideoAndAudio`, {
    materialId,
    transcodeTaskType,
  });
};

/**
 *  获取转码任务进度
 */
export const getTaskProcess = ({
  materialId,
  transcodeTaskType,
}: {
  materialId: string;
  transcodeTaskType: TranscodeTaskType;
}) => {
  return request.get<{ process: number }>(
    `/api/material/getTaskProcess/${materialId}/type/${transcodeTaskType}`,
  );
};

// todo 媒体库改造开始
/**
 *  根据分组ID获取图片列表
 */
export const getImageMediaByGroupId = (data: {
  pageNo: number;
  pageSize: number;
  groupId?: string;
  type: string;
  transcodeStatus?: any;
  name?: string;
}) => {
  return request.post<Pagination<IMediaTable>>('/api/material/image/page', data);
};
/**
 *  根据分组ID获取视频列表
 */
export const getVideoMediaByGroupId = (data: {
  pageNo: number;
  pageSize: number;
  groupId?: string;
  type: string;
  transcodeStatus?: any;
  name?: string;
}) => {
  return request.post<Pagination<IMediaTable>>('/api/material/video/page', data);
};

/**
 *  根据分组ID获取文档列表
 */
export const getDocumentMediaByGroupId = (data: {
  pageNo: number;
  pageSize: number;
  groupId?: string;
  type: string;
  transcodeStatus?: any;
  name?: string;
}) => {
  return request.post<Pagination<IMediaTable>>('/api/material/document/page', data);
};
/**
 *  根据分组ID获取音频列表
 */
export const getAudioMediaByGroupId = (data: {
  pageNo: number;
  pageSize: number;
  groupId?: string;
  type: string;
  transcodeStatus?: any;
  name?: string;
}) => {
  return request.post<Pagination<IMediaTable>>('/api/material/audio/page', data);
};

/**
 *  修改图片信息
 */
export const imageMediaReName = (data: { materialId: string; name: string }) => {
  return request.put<IMediaReName>('/api/material/image', data);
};
/**
 *  修改视频信息
 */
export const videoMediaReName = (data: { materialId: string; name: string }) => {
  return request.put<IMediaReName>('/api/material/video', data);
};
/**
 *  修改文档信息
 */
export const videoDocumentReName = (data: { materialId: string; name: string }) => {
  return request.put<IMediaReName>('/api/material/document', data);
};
/**
 *  保存上传图片素材地址
 */
export const saveImageMediaUrl = (data: {
  materialList: Array<IMaterialList>;
  groupId?: string;
}) => {
  return request.post<IMediaTable>('/api/material/image', data);
};
/**
 *  保存上传视频素材地址
 */
export const saveVideoMediaUrl = (data: {
  materialList: Array<IMaterialList>;
  groupId?: string;
}) => {
  return request.post<IMediaTable>('/api/material/video', data);
};
/**
 *  保存上传文档素材地址
 */
export const saveDocumentMediaUrl = (data: {
  materialList: Array<IMaterialList>;
  groupId?: string;
}) => {
  return request.post<IMediaTable>('/api/material/document', data);
};
/**
 *  保存上传文档素材地址
 */
export const saveAudioMediaUrl = (data: {
  materialList: Array<IMaterialList>;
  groupId?: string;
}) => {
  return request.post<IMediaTable>('/api/material/audio', data);
};
/**
 *  删除图片
 */
export const mediaDeleteImage = (data: { materialIds: Array<string> }) => {
  return request.post<groupReName>('/api/material/image/batch-delete', data);
};
/**
 *  删除视频
 */
export const mediaDeleteVideo = (data: { materialIds: Array<string> }) => {
  return request.post<groupReName>('/api/material/video/batch-delete', data);
};
/**
 *  删除文档
 */
export const mediaDeleteDocument = (data: { materialIds: Array<string> }) => {
  return request.post<groupReName>('/api/material/document/batch-delete', data);
};
/**
 *  删除音频
 */
export const mediaDeleteAudio = (data: { materialIds: Array<string> }) => {
  return request.post<groupReName>('/api/material/audio/batch-delete', data);
};
/**
 *  降低码率，视频转mp4
 */
export const mediaLowBitrate = (data: { materialIds: Array<string> }) => {
  return request.post('/api/material/video/create-video-transcode-task', data);
};
/**
 *  音频转码
 */
export const invokeTranscodeAudio = (data: { materialIds: Array<string> }) => {
  return request.post('/api/material/video/create-audio-transcode-task', data);
};
