<template>
  <div v-show="route.meta.showUploadBox" class="media-upload-modal">
    <a-spin :spinning="loading">
      <div class="uploading-title-box">
        <div v-if="count === uploadList.length" class="result-complete"></div>
        <div v-else class="result-loading"></div>
        <div class="upload-num">Uploading {{ count }} of {{ uploadList.length }} resources</div>
        <div class="toggle-btn">
          <div v-show="count === uploadList.length" class="close-modal" @click="closeModal"></div>
          <div
            :class="{ 'toggle-close': showAll, 'toggle-open': !showAll }"
            @click="showAllFn"
          ></div>
        </div>
      </div>
      <div ref="uploadContent" class="overflow-box" :class="{ shrink: !showAll }">
        <div class="upload-content-box">
          <div v-for="(item, index) in uploadList" :key="item.uid" class="upload-item">
            <div class="file-cover-box">
              <img v-if="item.coverUrl" :src="item.coverUrl" alt="" />
              <img
                v-else-if="item.materialType === MediaType.Video"
                src="@/assets/images/media/icon-media-video.png"
                alt=""
              />
              <img
                v-else-if="item.materialType === MediaType.Audio"
                src="@/assets/images/media/icon-media-audio.png"
                alt=""
              />
              <img v-else src="@/assets/images/media/icon-media-document.png" alt="" />
            </div>
            <div class="file-msg">
              <div class="name c-overout">{{ item.name }}</div>
              <div v-show="!item.uoloadtype && count === index" class="status">Uploading</div>
              <div v-show="!item.uoloadtype && count !== index" class="status">Waiting</div>
              <div v-show="item.uoloadtype === UploadType.FAIL" class="status">Failed</div>
              <div v-show="item.uoloadtype === UploadType.SUCCESS" class="status">Complete</div>
              <div v-show="item.uoloadtype === UploadType.STOP" class="status">Stop</div>
              <div v-show="item.uoloadtype === UploadType.CANCEL" class="status">Cancel</div>
            </div>
            <div class="file-status">
              <!-- 文件上传中-->
              <div
                class="file-uploading"
                v-if="(!item.uoloadtype || item.uoloadtype === UploadType.STOP) && count === index"
              >
                <span
                  class="status-text"
                  v-show="progressList.length && progressList[index]?.percentage > 0"
                  >{{
                    progressList.length && progressList[index]?.percentage === 100
                      ? '99'
                      : progressList[index]?.percentage
                  }}%</span
                >
                <div class="icon-status status-uploading">
                  <a-progress
                    type="circle"
                    :percent="
                      progressList.length && progressList[index]?.percentage === 100
                        ? '99'
                        : progressList[index]?.percentage
                    "
                    :width="24"
                    :show-text="false"
                    :stroke-width="8"
                    stroke-color="#FF0043"
                  ></a-progress>
                </div>
                <div
                  class="icon-status status-close"
                  @click="deleteItem(item, index, count === index)"
                ></div>
                <div
                  v-if="!item.uoloadtype"
                  class="icon-status status-stop"
                  @click="stopUpload(item)"
                ></div>
                <div
                  v-if="item.uoloadtype === UploadType.STOP"
                  class="icon-status status-start"
                  @click="reStartUpload(item)"
                ></div>
              </div>
              <!-- 文件等待上传中-->
              <div class="file-wating" v-if="!item.uoloadtype && count !== index">
                <div
                  class="icon-status status-close"
                  @click="deleteItem(item, index, count === index)"
                ></div>
                <div class="icon-status status-wating"></div>
              </div>
              <!-- 文件上传失败-->
              <div v-if="item.uoloadtype === UploadType.FAIL" class="icon-status status-fail"></div>
              <!-- 文件上传成功-->
              <div
                v-if="item.uoloadtype === UploadType.SUCCESS"
                class="icon-status status-success"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script setup lang="ts">
import cloneDeep from 'lodash/cloneDeep';
import SnowflakeId from 'snowflake-id';
import TcVod from 'vod-js-sdk-v6';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { getVodToken } from '@/api/common';
import { saveAudioMediaUrl, saveVideoMediaUrl } from '@/api/media';
import useCurrentPlan from '@/hooks/useCurrentPlan';
import { MediaType, UploadType } from '@/pages/media/type';
import { uploadModal } from '@/store/main';

const { updateCurrentPlan } = useCurrentPlan();
const route = useRoute();
const store = uploadModal();
const emit = defineEmits(['close']);
const uploadContent = ref();

const showAll = ref<boolean>(true);
const uploadList = ref<any[]>([]); // 上传的资源列表
const progressList = ref<any[]>([]); // 上传进度数组，独立放置
const deleteResource = ref<boolean>(false); // 删除时的状态
const loading = ref<boolean>(false);

let uploader: any = null; // 上传变量赋值
let tcVod: any = null; // VOD实例
let currentMaterialId = ''; // 当前上传中的媒体Id
let bizId = '';

const count = computed(() => {
  return store.uploadModal.count || 0;
});

const uploadOneByOne = async (val: any) => {
  if (val) {
    const file = val[count.value];
    // 如果用户选择的文件格式不正确或者超过大小限制，则无法上传并直接失败
    if (file && file.uoloadtype === UploadType.FAIL) {
      deleteResource.value = false;
      getNext(undefined);
      return false;
    }
    if (file && (file.materialType === MediaType.Video || file.materialType === MediaType.Audio)) {
      uploader = null;
      tcVod = null;
      currentMaterialId = '';
      await newVod(file);
    }
    if (count.value === uploadList.value.length) {
      window.onbeforeunload = null;
    } else {
      const msg = 'Uploading not ended. Are you sure you want to leave?';
      window.onbeforeunload = () => {
        return msg;
      };
    }
  }
};

// 创建VOD实例
async function newVod(file: any) {
  bizId = new SnowflakeId().generate();
  const { data } = await getVodToken({
    bizId: bizId,
    bizType: file.materialType,
  });
  currentMaterialId = data.materialId;
  tcVod = new TcVod({
    getSignature: () => {
      return data.signature;
    },
  });
  vodUpload(file);
}
const vodUpload = async (file: any) => {
  uploader = tcVod.upload({
    mediaFile: file,
    mediaName: currentMaterialId,
  });
  uploader.on('media_progress', (info: { percent: number }) => {
    progressList.value[count.value].percentage = Math.floor(info.percent * 100);
  });
  uploader.done().then(async (res: any) => {
    const url = res?.video?.url;
    if (url) {
      const type: Array<string> = file.type.split('/');
      const params: any = {
        materialList: [
          {
            url,
            fileName: file.name,
            format: type[type.length - 1],
            materialId: currentMaterialId,
            fileId: res.fileId,
          },
        ],
        groupId: file.groupId || undefined,
      };
      if (file.materialType === MediaType.Video) {
        await saveVideoMediaUrl(params);
      }
      if (file.materialType === MediaType.Audio) {
        await saveAudioMediaUrl(params);
      }
      updateCurrentPlan();
      uploadList.value[count.value].uoloadtype = UploadType.SUCCESS;
      deleteResource.value = false;
      getNext(undefined);
      return;
    }
    uploadList.value[count.value].uoloadtype = UploadType.FAIL;
    deleteResource.value = false;
    getNext(undefined);
  });
};

const getNext = (type: 'minus' | undefined) => {
  store.uploadModalUpdate({
    visible: true,
    count: type ? count.value : count.value + 1,
    uploadList: uploadList.value,
  });
  uploadOneByOne(uploadList.value);
};
const deleteItem = (item: any, index: number, deleteBol: boolean) => {
  if (!deleteBol) {
    uploadList.value.splice(index, 1);
    progressList.value.splice(index, 1);
    deleteResource.value = true;
    store.uploadModalUpdate({
      visible: true,
      uploadList: uploadList.value,
      count: count.value,
    });
    if (uploadList.value.length === 0) {
      closeModal();
    }
  } else {
    loading.value = true;
    let timer: any = setTimeout(() => {
      clearTimeout(timer);
      timer = null;
      loading.value = false;
      uploader.cancel();

      uploadList.value.splice(index, 1);
      progressList.value.splice(index, 1);
      deleteResource.value = true;
      getNext('minus');
      if (uploadList.value.length === 0) {
        closeModal();
      }
    }, 2000);
  }
};
const closeModal = () => {
  emit('close');
  let timer: any = setTimeout(() => {
    clearTimeout(timer);
    timer = null;
    deleteResource.value = true;
    store.uploadModalUpdate({
      visible: false,
      count: 0,
      uploadList: [],
    });
  }, 2000);
};
const showAllFn = () => {
  showAll.value = !showAll.value;
  const height = 56 * uploadList.value.length + 16 + 'px';
  uploadContent.value.style.height = showAll.value ? height : 0;
};
const uploadModalStore = computed(() => {
  return store.uploadModal.uploadList;
});

watch(
  uploadModalStore,
  (newV, oldV) => {
    if (newV && newV.length !== uploadList.value.length) {
      uploadList.value = cloneDeep(newV || []);
      if (uploadList.value && uploadList.value.length > 0) {
        uploadList.value.forEach(item => {
          if (JSON.stringify(progressList.value).indexOf(item.uid) === -1) {
            progressList.value.push({
              percentage: 0,
              uid: item.uid,
            });
          }
        });
        nextTick(() => {
          // 计算弹窗高度
          if (showAll.value) {
            uploadContent.value.style.height = 56 * newV.length + 16 + 'px';
          }
        });
      }
      if (oldV && newV.length > oldV.length) {
        if (!window.onbeforeunload) {
          uploadOneByOne(uploadList.value);
        }
      }
    }
  },
  {
    immediate: true,
  },
);
watch(route, val => {
  if (val.name === 'user') {
    deleteResource.value = true;
    store.uploadModalUpdate({
      visible: false,
      count: 0,
      uploadList: [],
    });
  }
});

onMounted(() => {
  uploadList.value = cloneDeep(store.uploadModal.uploadList || []);
  uploadList.value.forEach(item => {
    if (JSON.stringify(progressList.value).indexOf(item.uid) === -1) {
      progressList.value.push({
        percentage: 0,
        uid: item.uid,
      });
    }
  });
  uploadOneByOne(uploadList.value);
});

const stopUpload = (item: any) => {
  loading.value = true;
  item.uoloadtype = UploadType.STOP;
  let timer: any = setTimeout(() => {
    clearTimeout(timer);
    timer = null;
    loading.value = false;
    uploader.cancel();
  }, 2000);
};
const reStartUpload = (item: any) => {
  loading.value = true;
  item.uoloadtype = UploadType.DEFAULT;
  let timer: any = setTimeout(() => {
    clearTimeout(timer);
    timer = null;
    loading.value = false;
    vodUpload(item);
  }, 2500);
};

function updateOnline() {
  if (count.value !== uploadList.value.length) {
    if (navigator.onLine) {
      reStartUpload(uploadList.value[count.value]);
    } else {
      stopUpload(uploadList.value[count.value]);
    }
  }
}
window.addEventListener('online', updateOnline);
window.addEventListener('offline', updateOnline);
onBeforeUnmount(() => {
  window.removeEventListener('online', updateOnline);
  window.removeEventListener('offline', updateOnline);
});
</script>

<style lang="scss" scoped>
.media-upload-modal {
  width: 450px;
  max-height: 288px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08);
  @keyframes roundAnimation {
    0% {
      transform: rotate(0);
    }

    25% {
      transform: rotate(90deg);
    }

    50% {
      transform: rotate(180deg);
    }

    75% {
      transform: rotate(270deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .uploading-title-box {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 16px;
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.06);
  }

  .result-loading {
    width: 24px;
    height: 24px;
    background: url(@/assets/images/media/icon-doing.png) no-repeat;
    background-size: 100%;
    animation: roundAnimation 1s linear infinite;
  }

  .result-complete {
    width: 24px;
    height: 24px;
    background: url(@/assets/images/media/icon-complete.png) no-repeat;
    background-size: 100%;
  }

  .upload-num {
    margin-left: 12px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);
  }

  .toggle-btn {
    display: flex;
    margin-left: auto;

    .toggle-open {
      width: 24px;
      height: 24px;
      cursor: pointer;
      background: url(@/assets/images/media/icon-open.png) no-repeat;
      background-size: 100%;
    }

    .toggle-close {
      width: 24px;
      height: 24px;
      cursor: pointer;
      background: url(@/assets/images/media/icon-close.png) no-repeat;
      background-size: 100%;
    }

    .close-modal {
      width: 24px;
      height: 24px;
      margin-right: 12px;
      cursor: pointer;
      background: url(@/assets/images/media/icon-delete-media.png) no-repeat;
      background-size: 100%;
    }
  }

  .overflow-box {
    max-height: 224px;
    overflow-y: auto;
    opacity: 1;
    transition: all 0.5s ease 0s;
  }

  .shrink {
    opacity: 0;
  }

  .upload-content-box {
    padding-bottom: 16px;
    margin: 0 16px;

    .upload-item {
      display: flex;
      align-items: center;
      height: 56px;

      .file-cover-box {
        width: 70px;
        height: 40px;
        margin-right: 12px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .file-msg {
        width: 265px;
        word-break: keep-all;

        .name {
          height: 16px;
          margin-bottom: 2px;
          font-size: 14px;
          font-weight: 600;
          line-height: 16px;
          color: rgba(0, 0, 0, 0.8);
        }

        .status {
          height: 14px;
          font-size: 12px;
          line-height: 14px;
          color: rgba(0, 0, 0, 0.6);
        }
      }

      .file-status {
        height: 24px;
        margin-left: auto;

        .file-uploading {
          display: flex;
          align-items: center;

          .status-text {
            margin-right: 10px;
            font-size: 12px;
            color: #ff0043;
          }
        }

        &:hover {
          .status-wating {
            display: none;
          }

          .status-close {
            display: block;
          }

          .status-uploading {
            display: none;
          }

          .status-stop {
            display: block;
            cursor: pointer;
          }

          .status-start {
            display: block;
            cursor: pointer;
          }
        }

        .icon-status {
          width: 24px;
          height: 24px;
        }

        .status-uploading {
          display: block;
        }

        .status-wating {
          display: block;
          background: url(@/assets/images/media/icon-wating.png) no-repeat;
          background-size: 100%;
        }

        .status-close {
          display: none;
          cursor: pointer;
          background: url(@/assets/images/media/icon-delete-media.png) no-repeat;
          background-size: 100%;
        }

        .status-fail {
          background: url(@/assets/images/media/icon-error.png) no-repeat;
          background-size: 100%;
        }

        .status-success {
          background: url(@/assets/images/media/icon-complete.png) no-repeat;
          background-size: 100%;
        }

        .status-stop {
          display: none;
          margin-left: 10px;
          background: url(@/assets/images/media/icon-stop-media.png) no-repeat;
          background-size: 100%;
        }

        .status-start {
          display: none;
          margin-left: 10px;
          background: url(@/assets/images/media/icon-play-media.png) no-repeat;
          background-size: 100%;
        }
      }
    }
  }

  :deep(.ant-progress-text) {
    display: none !important;
    font-size: 12px;
  }
}
</style>
