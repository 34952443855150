<script setup lang="ts">
import { useRequest } from 'vue-request';

import { bindCoupon } from '@/api/coupon';
import { INotifications, NOTIFIS } from '@/api/notifications';
import useNotificationsStore from '@/store/notifications';
import usePackageTip from '@/store/packageTip';
import useUserStore from '@/store/user';

import HasCoupon from './has-coupon.vue';

const packageStore = usePackageTip();
const NotificationsStore = useNotificationsStore();
const userStore = useUserStore();
const { detail: userInfo } = toRefs(userStore);
const urlList = [
  'dashboard.yololiv.com',
  'dashboard-pre.yololiv.com',
  'yolo-dashboard-test.yololiv.com',
  'dashboard-dev.yololiv.com',
];

const { run: bindCouponRun, error: bindCouponError } = useRequest(bindCoupon, {
  manual: true,
  formatResult(res) {
    return res.data;
  },
});
const sure = async (item: INotifications) => {
  if (item.link) {
    if (urlList.some((url: string) => new RegExp(url).test(item.link))) {
      if (item.link.split('?couponId=').length > 1) {
        const couponId = item.link.split('?couponId=')[1];
        await bindCouponRun({
          couponId: couponId as string,
          email: userInfo.value!.email,
        });
        if (bindCouponError.value) return;
      }
      window.location.href = item.link.split('?couponId=')[0];
      packageStore.notificationsShowUpdate(false);
    } else {
      window.open(item.link);
    }
  } else {
    packageStore.notificationsShowUpdate(false);
  }
};
const close = () => {
  packageStore.notificationsShowUpdate(false);
};
</script>

<template>
  <a-modal
    :visible="packageStore.notificationsShow"
    :footer="null"
    width="710px"
    wrapClassName="modal-notifications-confirm"
    @cancel="packageStore.notificationsShowUpdate(false)"
  >
    <div class="modal-notifications-content">
      <a-carousel arrows dotsClass="notifications-dots">
        <template #prevArrow v-if="NotificationsStore.alertList?.length > 1">
          <div class="custom-slick-arrow">
            <img
              src="@/assets/images/notifications/icon-notifi-prev.png"
              style="left: 10px; z-index: 1"
            />PREV
          </div>
        </template>
        <template #nextArrow v-if="NotificationsStore.alertList?.length > 1">
          <div class="custom-slick-arrow">
            NEXT
            <img src="@/assets/images/notifications/icon-notifi-next.png" style="right: 10px" />
          </div>
        </template>
        <div
          class="notifications-modal"
          :class="[{ 'notifications-modal-single': NotificationsStore.alertList.length === 1 }]"
          v-for="item in NotificationsStore.alertList"
          :key="item.messageId"
        >
          <template v-if="item.scene !== NOTIFIS.COUPON_NOTIFI">
            <p v-if="item.title" class="about-expire__title">
              {{ item.title }}
            </p>
            <div class="notifications-content-box">
              <img v-if="item.coverUrl" :src="item.coverUrl" alt="expired" />
              <p class="about-expire__content" v-if="item.description">
                <span v-for="descs in item.description.split('\n')" :key="descs">
                  {{ descs }}
                </span>
              </p>
            </div>
            <div class="about-expire__actions">
              <a-button class="about-expire__sure" type="primary" @click="sure(item)">
                {{ item.btnText }}
              </a-button>
            </div>
          </template>
          <template v-else>
            <HasCoupon :detail="item" @close="close"></HasCoupon>
          </template>
        </div>
      </a-carousel>
      <div class="modal-notifications-footer" v-if="NotificationsStore.alertList?.length > 1">
        <div class="modal-notifications-footer-line"></div>
      </div>
    </div>
  </a-modal>
</template>

<style lang="scss">
.modal-notifications-confirm {
  .modal-notifications-content {
    .notifications-modal {
      position: relative;
      height: 535px;
      padding: 6px 6px 26px;
      background-color: #fff;

      &.notifications-modal-single {
        padding: 6px 6px 6px !important;
      }

      p {
        margin: 0;
      }

      .about-expire__title {
        margin-bottom: 20px;
        font-family: Roboto-Medium, Roboto, Helvetica, Arial, Verdana, Tahoma, sans-serif;
        font-size: 22px;
        font-weight: 500;
        line-height: 25px;
        color: #1d2129;
        word-break: break-word;
      }

      .notifications-content-box {
        height: 390px;
        overflow-y: auto;

        img {
          display: block;
          width: 100%;
          height: 244px;
          object-fit: contain;
        }

        .about-expire__content {
          margin-top: 15px;
          font-family: Roboto-Regular, Roboto, Helvetica, Arial, Verdana, Tahoma, sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          color: #1d2129;
          word-break: break-word;

          span {
            display: flex;
            align-items: center;
            margin-bottom: 15px !important;
            font-family: Roboto-Medium, Roboto, Helvetica, Arial, Verdana, Tahoma, sans-serif;
            font-size: 16px;
            font-weight: 500;
            color: #4e5969;

            i {
              display: block;
              width: 20px;
              height: 20px;
              margin-right: 10px;
              background: url(@/assets/images/coupon/icon-condition.png) no-repeat;
              background-size: 100%;
            }
          }
        }
      }

      .about-expire__actions {
        position: absolute;
        right: 0;
        bottom: 20px;
        left: 0;
        text-align: right;

        button {
          min-width: 120px;
          padding: 0 15px;
        }
      }
    }

    .ant-carousel {
      .slick-slide {
        overflow: hidden;
        background: #364d79;
      }

      .slick-next {
        right: 30px !important;
      }

      .slick-prev {
        left: -10px;
      }

      .slick-dots-bottom {
        bottom: -28px !important;

        li {
          width: 8px !important;
          height: 8px !important;
          background: #e5e6eb !important;
          border-radius: 50%;

          button {
            display: none !important;
          }

          &.slick-active {
            width: 8px !important;
            height: 8px !important;
            background: #4e5969 !important;
            border-radius: 50%;
          }
        }
      }

      .slick-arrow.custom-slick-arrow {
        top: auto !important;
        bottom: -34px !important;
        z-index: 1;
        display: flex !important;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        color: #86909c;

        img {
          width: 24px;
          height: 24px;
        }
      }

      .custom-slick-arrow:before {
        display: none;
      }
    }

    .modal-notifications-footer {
      position: relative;
      width: 100%;
      height: 24px;

      .modal-notifications-footer-line {
        position: absolute;
        top: 0;
        left: -24px;
        width: 710px;
        height: 1px;
        border: 1px solid #e5e6eb;
      }
    }
  }
}
</style>
