<template>
  <router-view></router-view>
  <div class="common-doing-box" :class="{ 'close-box': closed }" v-if="visible" v-drag>
    <UploadModal @close="closeModal"></UploadModal>
  </div>
</template>

<script setup lang="ts">
import UploadModal from '@/components/upload-modal/index.vue';
import { uploadModal } from '@/store/main';
import { computed, ref } from 'vue';
const store = uploadModal();
const closed = ref<boolean>(false);

const visible = computed(() => {
  return store.$state.uploadModal.visible;
});

const closeModal = () => {
  closed.value = true;
  let timer: any = setTimeout(() => {
    clearTimeout(timer);
    timer = null;
    closed.value = false;
  }, 2000);
};
</script>

<style lang="scss" scoped>
.common-doing-box {
  position: fixed;
  right: 16px;
  bottom: 24px;
  z-index: 1000;
  overflow: hidden;
  cursor: all-scroll;
  opacity: 1;
}

.close-box {
  opacity: 0;
  transition: all 1s ease 0s;
}
</style>
