import { dotReport } from '@/api/view';
import useUserStore from '@/store/user';

const ga = {
  mounted(el, bindData) {
    const { arg, value } = bindData;
    if (arg === 'page') {
      el.pageDotReportDestory = ga.pageDotReport(value);
    }
    if (arg === 'action') {
      el.actioneDotReportDestory = ga.actionDotReport(el, value);
    }
  },
  // unmounted(el) {
  //   el.pageDotReportDestory && el.pageDotReportDestory();
  //   el.actioneDotReportDestory && el.actioneDotReportDestory();
  // },
  pageDotReport(data) {
    const { detail } = useUserStore();
    const timer = setTimeout(() => {
      dotReport(
        Object.assign(
          {},
          { orgId: detail.orgId, userId: detail.userId, module: 'web-dashboard' },
          data,
        ),
      );
    }, 0);
    return function () {
      clearTimeout(timer);
    };
  },
  actionDotReport(el, data) {
    const { detail } = useUserStore();
    const fn = () => {
      // 是否需要上报 默认上报
      const toga = el.dataset.toga ? JSON.parse(el.dataset.toga) : true;
      if (!toga) return;
      timer = setTimeout(() => {
        try {
          dotReport(
            Object.assign(
              {},
              { orgId: detail.orgId, userId: detail.userId, module: 'web-dashboard' },
              data,
              {
                markData: el.dataset.ga ? JSON.parse(el.dataset.ga) : {},
              },
            ),
          );
        } catch (error) {
          console.log(error, 'ga');
        }
      }, 0);
    };
    let timer = null;
    el.addEventListener('click', fn, false);
    return function () {
      clearTimeout(timer);
      el.removeEventListener('click', fn);
    };
  },
};

export default ga;
