import * as ConstantLabel from '@/constant/label';
import * as ConstantRouter from '@/constant/router';
import loadable from '@/utils/loadable';

const route = {
  path: 'broadcasts-always',
  name: ConstantRouter.BROADCAST_ALWAYS,
  component: loadable(
    () =>
      import(
        /* webpackChunkName: "broadcasts-detail" */ '@/pages/broadcasts/detail-always/index.vue'
      ),
  ),
  redirect: { name: ConstantRouter.BROADCAST_ALWAYS_DESTINATIONS },
  children: [
    {
      path: 'sources',
      name: ConstantRouter.BROADCAST_ALWAYS_SOURCES,
      meta: {
        name: ConstantLabel.BROADCAST_SOURCES,
      },
      component: loadable(
        () =>
          import(
            /* webpackChunkName: "broadcasts-detail-sources" */ '@/pages/broadcasts/detail-always/sources/index.vue'
          ),
      ),
    },
    {
      path: 'destinations',
      name: ConstantRouter.BROADCAST_ALWAYS_DESTINATIONS,
      meta: {
        name: ConstantLabel.BROADCAST_DESTINATIONS,
      },
      component: loadable(
        () =>
          import(
            /* webpackChunkName: "broadcasts-detail-destinations" */ '@/pages/broadcasts/detail-always/destinations/index.vue'
          ),
      ),
    },
    {
      path: 'diagnostics',
      name: ConstantRouter.BROADCAST_ALWAYS_DIAGNOSTICS,
      meta: {
        name: ConstantLabel.BROADCAST_DIAGNOSTICS,
      },
      component: loadable(
        () =>
          import(
            /* webpackChunkName: "broadcasts-detail-diagnostics" */ '@/pages/broadcasts/detail-always/diagnostics/index.vue'
          ),
      ),
    },
    {
      path: 'analytics',
      name: ConstantRouter.BROADCAST_ALWAYS_ANALYTICS,
      meta: {
        name: ConstantLabel.BROADCAST_ANALYTICS,
      },
      component: loadable(
        () =>
          import(
            /* webpackChunkName: "broadcasts-detail-analytics" */ '@/pages/broadcasts/detail-always/analytics/index.vue'
          ),
      ),
    },
    {
      path: 'documents',
      name: ConstantRouter.BROADCAST_ALWAYS_DOCUMENTS,
      meta: {
        name: ConstantLabel.BROADCAST_DOCUMENTS,
      },
      component: loadable(
        () =>
          import(
            /* webpackChunkName: "broadcasts-detail-documents" */ '@/pages/broadcasts/detail-always/documents/index.vue'
          ),
      ),
    },
    {
      path: 'chat',
      name: ConstantRouter.BROADCAST_ALWAYS_CHAT,
      meta: {
        name: ConstantLabel.BROADCAST_CHAT,
      },
      component: loadable(
        () =>
          import(
            /* webpackChunkName: "broadcasts-detail-chat" */ '@/pages/broadcasts/detail-always/chat/index.vue'
          ),
      ),
      redirect: { name: ConstantRouter.BROADCAST_ALWAYS_CHAT_COMMENT },
      children: [
        {
          path: 'comment',
          name: ConstantRouter.BROADCAST_ALWAYS_CHAT_COMMENT,
          meta: {
            name: ConstantLabel.BROADCAST_CHAT_COMMENT,
          },
          component: loadable(
            () =>
              import(
                /* webpackChunkName: "broadcasts-detail-chat-comment" */ '@/pages/broadcasts/detail-always/chat/comment/index.vue'
              ),
          ),
        },
        {
          path: 'hidden',
          name: ConstantRouter.BROADCAST_ALWAYS_CHAT_HIDDEN,
          meta: {
            name: ConstantLabel.BROADCAST_CHAT_HIDDEN,
          },
          component: loadable(
            () =>
              import(
                /* webpackChunkName: "broadcasts-detail-chat-hidden" */ '@/pages/broadcasts/detail-always/chat/hidden/index.vue'
              ),
          ),
        },
      ],
    },
    {
      path: 'report',
      name: ConstantRouter.BROADCAST_ALWAYS_REPORT,
      meta: {
        name: ConstantLabel.BROADCAST_REPORT,
      },
      redirect: { name: ConstantRouter.BROADCAST_ALWAYS_REPORT_LIVE_DATA },
      component: loadable(
        () =>
          import(
            /* webpackChunkName: "broadcasts-detail-report" */ '@/pages/broadcasts/detail-always/report/index.vue'
          ),
      ),
      children: [
        {
          path: 'live-data',
          name: ConstantRouter.BROADCAST_ALWAYS_REPORT_LIVE_DATA,
          meta: {
            name: ConstantLabel.BROADCAST_REPORT_LIVE_DATA,
          },
          component: loadable(
            () =>
              import(
                /* webpackChunkName: "broadcasts-detail-report-live-data" */ '@/pages/broadcasts/detail-always/report/live-data/index.vue'
              ),
          ),
        },
        {
          path: 'view-data',
          name: ConstantRouter.BROADCAST_ALWAYS_REPORT_VIEWERS_DATA,
          meta: {
            name: ConstantLabel.BROADCAST_REPORT_VIEWERS_DATA,
          },
          component: loadable(
            () =>
              import(
                /* webpackChunkName: "broadcasts-detail-report-view-data" */ '@/pages/broadcasts/detail-always/report/view-data/index.vue'
              ),
          ),
        },
        {
          path: 'stream-analytics',
          name: ConstantRouter.BROADCAST_ALWAYS_REPORT_STREAM_DATA,
          meta: {
            name: ConstantLabel.BROADCAST_REPORT_STREAM_DATA,
          },
          component: loadable(
            () =>
              import(
                /* webpackChunkName: "broadcasts-detail-report-stream-analytics" */ '@/pages/broadcasts/detail-always/report/stream-analytics/index.vue'
              ),
          ),
        },
      ],
    },
    {
      path: 'trim',
      name: ConstantRouter.BROADCAST_ALWAYS_TRIM,
      meta: {
        name: ConstantLabel.BROADCAST_TRIM,
      },
      component: loadable(
        () =>
          import(
            /* webpackChunkName: "broadcasts-detail-trim" */ '@/pages/broadcasts/detail-always/media/trim/index.vue'
          ),
      ),
    },
    {
      path: 'clips',
      name: ConstantRouter.BROADCAST_ALWAYS_CLIPS,
      meta: {
        name: ConstantLabel.BROADCAST_CLIPS,
      },
      component: loadable(
        () =>
          import(
            /* webpackChunkName: "broadcasts-detail-highlight" */ '@/pages/broadcasts/detail-always/media/clips/index.vue'
          ),
      ),
    },
    {
      path: 'overlays',
      name: ConstantRouter.BROADCAST_ALWAYS_OVERLAYS,
      meta: {
        name: ConstantLabel.BROADCAST_OVERLAYS,
      },
      component: loadable(
        () =>
          import(
            /* webpackChunkName: "broadcasts-detail-overlays" */ '@/pages/broadcasts/detail-always/overlays/index.vue'
          ),
      ),
    },
    {
      path: 'scoreboard',
      name: ConstantRouter.BROADCAST_ALWAYS_SCOREBOARD,
      meta: {
        name: ConstantLabel.BROADCAST_SCOREBOARD,
      },
      component: loadable(
        () =>
          import(
            /* webpackChunkName: "broadcasts-detail-overlays" */ '@/pages/broadcasts/detail-always/scoreboard/index.vue'
          ),
      ),
    },
    {
      path: 'ads',
      name: ConstantRouter.BROADCAST_ALWAYS_ADS,
      meta: {
        name: ConstantLabel.BROADCAST_ADS,
      },
      component: loadable(
        () =>
          import(
            /* webpackChunkName: "broadcasts-detail-documents" */ '@/pages/broadcasts/detail-always/coms/ads.vue'
          ),
      ),
    },
  ],
};

export default route;
