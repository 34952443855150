import RouterHolder from '@/components/router-holder/index.vue';
import * as ConstantLabel from '@/constant/label';
import * as ConstantRouter from '@/constant/router';
import loadable from '@/utils/loadable';

const route = {
  path: 'web-control',
  name: ConstantRouter.WEB_CONTROL,
  component: RouterHolder, // () => import(/* webpackChunkName: "sources" */ '@/pages/web-control/index.vue'),
  meta: {
    name: ConstantLabel.WEB_CONTROL,
    showUploadBox: true,
  },
  redirect: { name: ConstantRouter.WEB_CONTROL_LINK },
  children: [
    {
      path: '',
      name: ConstantRouter.WEB_CONTROL_LINK,
      component: loadable(
        () => import(/* webpackChunkName: "sources" */ '@/pages/web-control/index.vue'),
      ),
      meta: {
        name: ConstantLabel.WEB_CONTROL_LINK,
        showUploadBox: true,
      },
    },
  ],
};

export default route;
