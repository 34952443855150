import { PlanItem } from '@/api/plan';
import { ResourceItem } from '@/api/plan';
import {
  AccountType,
  ILoginInfo,
  IOrgInfo,
  IOrgTypes,
  ISubAccountInfo,
  ITimeZone,
  IUserInfo,
} from '@/types/account';
import { PermissionListResponse } from '@/types/api';
import request from '@/utils/request';

/**
 * 获取行业列表
 */
export const getOrgTypes = () => {
  return request.get<IOrgTypes[]>('/api/passport/org-types');
};

/**
 * 用户注册
 */
export const sign = (data: {
  firstName?: string;
  lastName?: string;
  phone?: string;
  email: string;
  password: string;
  code?: string;
  channelId?: string;
  agentId?: string;
}) => {
  return request.post<boolean>('/api/passport/sign', data);
};

/**
 * 认证注册邮箱
 */
export const emailVerify = (token: string) => {
  if (!token) return Promise.reject();
  return request.get<boolean>('/api/passport/email-verify', { token });
};

/**
 * 认证注册邮箱
 */
export const forgotPassword = (data: { email: string }) => {
  return request.post<boolean>('/api/passport/forgot-password', data);
};

/**
 * 修改密码-通过token
 */
export const tokenPassword = (data: { newPassword: string; token: string }) => {
  return request.put<boolean>('/api/passport/token-password', data);
};

/**
 * 检查邮箱是否被使用过
 */
export const checkEmail = (data: { email: string }) => {
  return request.post<boolean>('/api/passport/email-exist', data);
};

/**
 * 发送校验码
 */
export const verificationCode = (data: { email: string }) => {
  return request.post<boolean>('/api/passport/verificationCode', data);
};

/**
 * 重新发送验证码
 */
export const resendEmail = (data: { email: string }) => {
  return request.post<boolean>('/api/passport/resend-email', data);
};

/**
 *  用户登录
 */
export const login = (data: { email: string; password: string }) => {
  return request.post<{ token: string }>('/api/passport/login', data);
};

/**
 *  获取用户信息
 */
export const queryInfo = () => {
  return request.get<IUserInfo>('/api/user');
};

/**
 *  更新用户信息
 */
export const updateInfo = (data: { name: string; email: string; phone: string }) => {
  return request.put('/api/user', data);
};

/**
 *  修改组织信息
 */
export const updataOrgInfo = (data: {
  name?: string;
  type: string;
  location?: string;
  timezone?: string;
  lang?: string;
}) => {
  return request.put('/api/user/org', data);
};
/**
 *  获取组织信息
 */
export const queryOrgInfo = () => {
  return request.get<IOrgInfo>('/api/user/org');
};

/**
 *  获取时区集合
 */
export const queryTimeZone = () => {
  return request.get<Array<ITimeZone>>('/api/user/timezone');
};

/**
 *  三方账号登录
 */
export const loginWithSocialMeida = (data: { type: AccountType; credential: string }) => {
  return request.post<{ token: string }>('/api/passport/login-with-social-media', data);
};

/**
 *  获取用户的所有登录信息
 */
export const queryLoginInfoList = () => {
  return request.get<ILoginInfo[]>('/api/passport/login-info');
};

/**
 *  绑定三方账号
 */
export const addLogin = (data: { type: AccountType; credential: string }) => {
  return request.post<boolean>('/api/passport/add-login', data);
};

/**
 *  删除添加的登录
 */
export const deleteLoginInfo = (data: { id: number; type: AccountType }) => {
  return request.delete<boolean>(`/api/passport/delete-login`, data);
};

/**
 *  获取子账号列表
 */
export const getSubAccountList = () => {
  return request.get<ISubAccountInfo[]>('/api/user/list');
};

/**
 * 创建子账号
 */
export const subAccountCreate = (data: { name: string; email: string; password: string }) => {
  return request.post<boolean>('/api/user/sub-user', data);
};

/**
 * 编辑子账号
 */
export const subAccountEdit = (data: { userId: string; name: string }) => {
  return request.put<boolean>(`/api/user/sub-user`, data);
};

// 删除子账号
export const subAccountDelete = (data: { userId: string }) => {
  return request.delete<boolean>(`/api/user/sub-user`, data);
};

// 更新用户头像
export const updateAvatar = (data: { avatar: string }) => {
  return request.put<boolean>(`/api/user/change-avatar`, data);
};

/**
 * 检查邀请码是否被使用过
 */
export const checkCode = (data: { code: string }) => {
  return request.post<boolean>('/api/passport/code-verify', data);
};

/**
 * 使用邀请码
 */
export const applyCode = (data: { code: string }) => {
  return request.post<boolean>('/api/user/receive-code', data);
};

/**
 * 获取当前账号已使用的邀请码
 */
export const getAppliedCode = () => {
  return request.get<{
    code: string;
    planId: number;
    dayNum: number;
  }>('/api/user/get-invite-code');
};

/**
 * 获取权限列表
 */
export const getPermissionList = () => {
  return request.get<Required<PermissionListResponse>[]>('/api/user/permission-list');
};

/**
 *  获取全部的信息用户信息、权限信息、套餐信息、存储信息
 *  保留之前的接口，对初始化页面做性能优化
 */
export const queryAllInfo = () => {
  return request.get<{
    permissionList: Array<PermissionListResponse>;
    planInfo: PlanItem;
    resourceInfo: ResourceItem;
    userInfo: IUserInfo;
  }>('/api/user/union-data');
};

/**
 *  sso登录
 */
export const ssoLogin = (data: { userKey: string }) => {
  return request.post('/api/passport/box-sso', data);
};
