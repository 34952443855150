<script setup lang="ts">
import TcPlayer from '@/components/tcplayer/index.vue';

const changedTcPlayer = ref(true);
const tcPlayerRef = ref();
const typeList = [
  'Church',
  'Sports',
  'Enterprise',
  'Event',
  'Education',
  'Government',
  'Videographer',
];
const advanceList = ['SMART', 'RESILENT', 'RELIABLE', 'INTUITIVE'];
const playUrl = ref(
  'https://static.yololiv.com/static/videos/a68d7107-402f-4b8c-9b91-f00bd1304f77_1.mp4',
);
const playOption = computed(() => {
  if (playUrl.value) {
    return {
      src: playUrl.value,
      poster:
        'https://static.yololiv.com/static/imgs/83385c29-afc7-4ab5-85ba-0658d6af477e_login-1-quick-live.jpg',
    };
  }
  return null;
});

function stopPlayer() {
  if (tcPlayerRef.value && tcPlayerRef.value.getPlayerInstance()) {
    tcPlayerRef.value.getPlayerInstance().pause();
    changedTcPlayer.value = false;
  }
}

function startPlayer() {
  changedTcPlayer.value = true;
}

defineExpose({
  stopPlayer,
  startPlayer,
});
</script>

<template>
  <div class="page-first-step">
    <div class="step-title">Make stunning live events & drive impacts<br />for your business.</div>
    <div class="step-type">
      <div class="type-item" v-for="(type, index) in typeList" :key="type">
        {{ type }}<i v-if="index !== typeList.length - 1"></i>
      </div>
    </div>
    <div class="step-video">
      <div class="left-flower"></div>
      <div class="right-flower"></div>
      <div class="video-round-box">
        <div class="step-video-box">
          <TcPlayer
            v-if="playOption && changedTcPlayer"
            ref="tcPlayerRef"
            class="control-player__play-inner"
            :id="`tc-player-${new Date().getTime()}`"
            v-bind="playOption"
          ></TcPlayer>
        </div>
        <div class="video-mask"></div>
      </div>
    </div>
    <div class="step-advance">
      <div class="acvance-item" v-for="item in advanceList" :key="item"><i></i>{{ item }}</div>
    </div>
  </div>
</template>

<style lang="scss">
.page-first-step {
  width: 100%;
  min-height: 100vh;
  padding: 140px 0 0;

  .step-title {
    margin-bottom: 20px;
    font-family: Montserrat-Bold, Montserrat;
    font-size: 32px;
    font-weight: bold;
    line-height: 39px;
    color: #fff;
    text-align: center;
  }

  .step-type {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;

    .type-item {
      display: flex;
      align-items: center;
      font-family: Montserrat-Regular, Montserrat;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      color: #fff;

      i {
        display: block;
        width: 1px;
        height: 12px;
        margin: 0 15px;
        background: rgba(255, 255, 255, 0.3);
      }
    }
  }

  .step-video {
    position: relative;
    width: 39vw;
    min-width: 590px;
    max-width: 750px;
    margin: 0 auto 50px;

    .left-flower {
      position: absolute;
      bottom: 0;
      left: -93px;
      z-index: 1;
      width: 120px;
      height: 220px;
      background: url(@/assets/images/icon-left-flower.png) no-repeat;
      background-size: 100%;
    }

    .right-flower {
      position: absolute;
      right: -117px;
      bottom: 0;
      z-index: 1;
      width: 164px;
      height: 326px;
      background: url(@/assets/images/icon-right-flower.png) no-repeat;
      background-size: 100%;
    }

    .video-round-box {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 15px;

      .step-video-box {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 56.25%;
        overflow: hidden;
        border-radius: 12px;

        .control-player__play-inner {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 200 !important;
          width: 100% !important;
          height: 100% !important;
        }
      }

      .video-mask {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 100;
        background: rgba(255, 255, 255, 0.1);
        border: 2px solid rgba(96, 107, 255, 0.6);
        border-radius: 12px;
        border-radius: 12px;
        backdrop-filter: blur(22.5px);
      }
    }
  }

  .step-advance {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 39vw;
    min-width: 590px;
    max-width: 750px;
    margin: 0 auto;

    .acvance-item {
      display: flex;
      align-items: center;
      font-family: Montserrat-Bold, Montserrat;
      font-size: 22px;
      font-weight: bold;
      color: #fff;

      i {
        display: block;
        width: 12px;
        height: 12px;
        margin-right: 15px;
        background: rgba(255, 255, 255, 0.29);
        border-radius: 27px;
      }
    }
  }
}
</style>
