import HttpServer from '@yunxi/request';
import { message } from 'ant-design-vue';

import { FACEBOOKGROUP_GUIDE_SHOWED, PACKAGE_EXPIRE_TIP_SHOWED } from '@/constant/cache';
import router from '@/router';
import { getAccountStore, getAccountStoreKey } from '@/utils/accountStore';
import { localStore, sessionStore } from '@/utils/store';

export const loginOut = () => {
  /** 用户退出之后 常用的本地缓存设置保持 清除其他登录等信息 */
  const keepStoreKeys = [PACKAGE_EXPIRE_TIP_SHOWED, FACEBOOKGROUP_GUIDE_SHOWED];
  const keepStoreMapList = keepStoreKeys
    .filter(item => getAccountStore(item) != undefined)
    .map(item => [getAccountStoreKey(item), getAccountStore(item)]);
  localStore.clearAll();
  sessionStore.clearAll();
  keepStoreMapList.forEach(([k, v]) => {
    localStore.set(k, v);
  });
  location.replace(`/user/login?redirect=${router.currentRoute.value.fullPath}`);
};

const request = new HttpServer(
  {
    baseURL: import.meta.env.DEV ? '' : (import.meta.env.VITE_BASEURL as ''),
    timeout: 50000,
  },
  {
    handleRequest: config => {
      config.headers = config.headers || {};
      config.headers.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const token = localStore.get('token');
      if (token) {
        config.headers['cast-auth'] = token;
      }
      if (config.method && config.method.toLowerCase() === 'get') {
        if (config.params) {
          config.params.timestamp = +new Date();
        } else {
          config.params = {
            timestamp: +new Date(),
          };
        }
      }
      return config;
    },
    errorHandle: response => {
      switch (response.code) {
        case 99999:
        case 10009:
          // message.error(<string>response.message || '您未登录，或者登录已经超时，请先登录！');
          loginOut();
          break;
        default:
          message.error(<string>response.msg || response.message || 'Service error');
          break;
      }
    },
  },
);

export const rawRequest = new HttpServer(
  {
    baseURL: import.meta.env.DEV ? '' : (import.meta.env.VITE_BASEURL as ''),
    timeout: 50000,
  },
  {
    raw: true,
    handleRequest: config => {
      config.headers = config.headers || {};
      config.headers.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const token = localStore.get('token');
      if (token) {
        config.headers['cast-auth'] = token;
      }
      return config;
    },
  },
);

export default request;
