const driver = {
  mounted(el, { value }, vnode) {
    console.log(el, value, vnode);
  },
  unmounted(el) {
    console.log(el);
  },
  _handle(vnode, e) {
    e.stopPropagation();
  },
  _setElStyle(el) {
    const timer = setTimeout(() => {
      if (!['relative', 'fixed'].includes(window.getComputedStyle(el).position)) {
        el.style.position = 'relative';
      }
    }, 0);
    return function () {
      clearTimeout(timer);
    };
  },
  _mask(el, vnode) {
    const maskDom = document.createElement('div');
    maskDom.style.position = 'absolute';
    maskDom.style.zIndex = '1';
    maskDom.style.left = '0';
    maskDom.style.top = '0';
    maskDom.style.width = '100%';
    maskDom.style.height = '100%';
    maskDom.style.cursor = 'no-drop';
    maskDom.addEventListener('click', permission._handle.bind(null, vnode));
    el.append(maskDom);
    return function () {
      maskDom.removeEventListener('click', permission._handle.bind(null, vnode));
      maskDom.remove();
    };
  },
};

export default driver;
