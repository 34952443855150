<script setup lang="ts">
import { Modal } from 'ant-design-vue';
import dayjs from 'dayjs';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import defaultAvatar from '@/assets/images/default-user.png';
import FreeTrial from '@/components/free-trial/index.vue';
import { PLAN_NAME } from '@/constant/planName';
import * as ConstantRouter from '@/constant/router';
import useCurrentPlanStore from '@/store/currentPlan';
import { uploadModal } from '@/store/main';
import useNewGuideStore from '@/store/newGuide';
import useNotifications from '@/store/notifications';
import useUserStore from '@/store/user';
import { loginOut } from '@/utils/request';

import NotifiPopover from './notifi-popover.vue';

const router = useRouter();
const route = useRoute();
const userStore = useUserStore();
const notificationsStore = useNotifications();
const currentPlanStore = useCurrentPlanStore();
const newGuideStore = useNewGuideStore();
const { detail: userInfo } = toRefs(userStore);
const notifiPopoverVisible = ref(false);
const freeTrialRef = ref();
const breadcrumbs: any = computed(() => {
  const matched = route.matched.slice(1);
  const matchedItems = matched
    .filter(item => item.meta.name)
    .map(item => ({
      link: item.path,
      name: item.meta.name,
    }));
  return matchedItems;
});
// 付费用户禁止弹窗，禁止横幅消息
if (
  [2, 3, undefined].includes(currentPlanStore.currentPlanData?.planType) &&
  !notificationsStore.broadcastList
) {
  notificationsStore.fetchNotificationsBroadcastList();
}

const store = uploadModal();
const formatTimeDate = (time: string | number | dayjs.Dayjs | Date) => {
  return dayjs(time).format('MMM D, YYYY');
};
const planData = computed(() => {
  /** 套餐类型 1 正常套餐 2 无限期使用套餐（试用套餐结束后套餐） 3 注册十四天试用套餐 4 盒子套餐 */
  if ([2, 3, undefined].includes(currentPlanStore.currentPlanData?.planType)) {
    return {
      bgColor: '#000000',
      planName: 'Trial',
      expireDate:
        currentPlanStore.currentPlanData?.planType === 3
          ? formatTimeDate(currentPlanStore.currentPlanData!.expireDate)
          : '',
    };
  }
  if (
    [4, undefined].includes(currentPlanStore.currentPlanData?.planType) &&
    currentPlanStore.currentPlanData?.bondingData === 10
  ) {
    return {
      bgColor: '#000000',
      planName: PLAN_NAME.BOX,
      expireDate: currentPlanStore.currentPlanData!.subscriptionEnabled
        ? ''
        : formatTimeDate(currentPlanStore.currentPlanData!.expireDate),
    };
  }
  if (
    [4, undefined].includes(currentPlanStore.currentPlanData?.planType) &&
    currentPlanStore.currentPlanData?.bondingData === 20
  ) {
    return {
      bgColor: '#000000',
      planName: PLAN_NAME.BONDING20G,
      expireDate: currentPlanStore.currentPlanData!.subscriptionEnabled
        ? ''
        : formatTimeDate(currentPlanStore.currentPlanData!.expireDate),
    };
  }
  if (
    [4, undefined].includes(currentPlanStore.currentPlanData?.planType) &&
    currentPlanStore.currentPlanData?.bondingData === 50
  ) {
    return {
      bgColor: '#000000',
      planName: PLAN_NAME.BONDING50G,
      expireDate: currentPlanStore.currentPlanData!.subscriptionEnabled
        ? ''
        : formatTimeDate(currentPlanStore.currentPlanData!.expireDate),
    };
  }
  if (
    [4, undefined].includes(currentPlanStore.currentPlanData?.planType) &&
    currentPlanStore.currentPlanData?.bondingData === 100
  ) {
    return {
      bgColor: '#000000',
      planName: PLAN_NAME.BONDING100G,
      expireDate: currentPlanStore.currentPlanData!.subscriptionEnabled
        ? ''
        : formatTimeDate(currentPlanStore.currentPlanData!.expireDate),
    };
  }
  if (currentPlanStore.currentPlanData?.name.indexOf(PLAN_NAME.PRIMARY) > -1) {
    return {
      bgColor: '#C9CDD4',
      planName: PLAN_NAME.PRIMARY,
      expireDate: currentPlanStore.currentPlanData!.subscriptionEnabled
        ? ''
        : formatTimeDate(currentPlanStore.currentPlanData!.expireDate),
    };
  }
  if (currentPlanStore.currentPlanData?.name.indexOf(PLAN_NAME.INTERMEDIATE) > -1) {
    return {
      bgColor: '#1957FF',
      planName: PLAN_NAME.INTERMEDIATE,
      expireDate: currentPlanStore.currentPlanData!.subscriptionEnabled
        ? ''
        : formatTimeDate(currentPlanStore.currentPlanData!.expireDate),
    };
  }
  if (currentPlanStore.currentPlanData?.name.indexOf(PLAN_NAME.ADVANCED) > -1) {
    return {
      bgColor: '#2ED92B',
      planName: PLAN_NAME.ADVANCED,
      expireDate: currentPlanStore.currentPlanData!.subscriptionEnabled
        ? ''
        : formatTimeDate(currentPlanStore.currentPlanData!.expireDate),
    };
  }
  return {};
});
function logout() {
  const uploadList: any = store.uploadModal.uploadList;
  let uploading = false;
  if (uploadList && uploadList.length > 0) {
    for (let i = 0; i < uploadList.length; i++) {
      if (!uploadList[i].uoloadtype) {
        uploading = true;
        break;
      }
    }
  }
  if (uploading) {
    Modal.confirm({
      content: 'Uploading not ended. Are you sure you want to leave?',
      okText: 'Done',
      cancelText: 'Cancel',
      type: 'warning',
      title: 'Prompt',
      onOk: () => {
        loginOut();
      },
    });
  } else {
    loginOut();
  }
}

function toAccount() {
  router.push({ name: ConstantRouter.ACCOUNT });
}

function readNews() {
  notificationsStore.clearNotificationsNews();
  notifiPopoverVisible.value = true;
}
function toPlan() {
  router.push({ name: ConstantRouter.PLANS });
}
function getFreeTrial() {
  freeTrialRef.value.show();
}
</script>

<template>
  <div class="head__left">
    <!-- <a-breadcrumb>
      <a-breadcrumb-item v-for="(item, i) in breadcrumbs" :key="item">
        <router-link v-if="i !== breadcrumbs.length - 1 && item.link" :to="item.link">
          {{ item.name }}
        </router-link>
        <span v-else>{{ item.name }}</span>
      </a-breadcrumb-item>
    </a-breadcrumb> -->
    {{ breadcrumbs[breadcrumbs.length - 1]?.name }}
  </div>
  <div class="head__right">
    <div
      class="trial-btn-box"
      v-if="
        newGuideStore.guideDetail &&
        !newGuideStore.guideDetail?.doneTrial &&
        currentPlanStore.currentPlanData &&
        currentPlanStore.currentPlanData.planType === 2
      "
    >
      <div class="trial-handle"></div>
      <a-button class="head-trial-btn" type="primary" ghost @click="getFreeTrial">
        Get 14 Days Free Trial
      </a-button>
    </div>
    <a-button
      class="head-upgrade-btn"
      type="primary"
      @click="toPlan"
      v-if="[2, 3, undefined].includes(currentPlanStore.currentPlanData.planType)"
    >
      Upgrade
    </a-button>
    <div
      class="head-notifi-box"
      v-if="[2, 3, undefined].includes(currentPlanStore.currentPlanData?.planType)"
    >
      <div
        class="head-carousel-box"
        v-if="notificationsStore.broadcastList && notificationsStore.broadcastList.length > 0"
        @click="readNews"
      >
        <a-carousel dot-position="left" autoplay>
          <div
            class="notifi-item"
            v-for="item in notificationsStore.broadcastList"
            :key="item.messageId"
          >
            <i></i>
            <div class="notifi-msg-text">{{ item.title }}</div>
          </div>
        </a-carousel>
      </div>
      <div class="notifi-popover-box">
        <NotifiPopover
          v-if="notifiPopoverVisible"
          @close="notifiPopoverVisible = false"
        ></NotifiPopover>
      </div>
    </div>

    <a-popover placement="bottomRight" overlayClassName="popover-user-msg">
      <template #content>
        <div class="head__user-msg">
          <a-avatar :src="userInfo?.avatar || defaultAvatar"> </a-avatar>
          <div class="msg-detail">
            <p class="head__user-name c-overout" :title="userInfo?.name">{{ userInfo?.name }}</p>
            <p class="head__user-plan c-overout" :title="planData?.planName">
              <span>
                <i :style="{ background: planData?.bgColor }">{{ planData?.planName }}</i>
                <b v-if="planData?.expireDate">to {{ planData?.expireDate }}</b>
              </span>
            </p>
            <p class="head__user-email c-overout" :title="userInfo?.email">{{ userInfo?.email }}</p>
          </div>
        </div>
        <a-space direction="vertical" size="small" style="display: flex" class="head__actions">
          <div class="user-btn to-account" @click="toAccount">
            <i></i>
            Account
          </div>
          <div class="user-btn to-logout" @click="logout">
            <i></i>
            Logout
          </div>
        </a-space>
      </template>
      <div class="head__user">
        <a-avatar :src="userInfo?.avatar || defaultAvatar"> </a-avatar>
        <i class="g-icon-arrows-down head__icon-toggle"></i>
      </div>
    </a-popover>
    <FreeTrial ref="freeTrialRef"></FreeTrial>
  </div>
</template>

<style lang="scss" scoped>
.head__left {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 16px;
  font-size: 20px;
  font-weight: bold;

  .ant-breadcrumb {
    margin: 10px;
  }
}

.head__right {
  position: relative;
  z-index: 9;
  display: flex;
  align-items: center;
}

.head__user {
  display: flex;
  align-items: center;

  .head__user-info {
    margin: 0 12px 0 8px;
  }

  .head__icon-toggle {
    margin-left: 5px;
  }
}

.head-upgrade-btn {
  margin-right: 10px;
}

.trial-btn-box {
  position: relative;
  display: flex;
  align-items: center;
  height: 34px;
  margin-right: 10px;

  .trial-handle {
    position: absolute;
    left: -40px;
    top: 5px;
    width: 24px;
    height: 24px;
    background: url(@/assets/images/trial-jiantou.png) center;
    background-size: 100%;
    animation: move 2s ease-in-out infinite;
  }
}

.head-notifi-box {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 10px;

  .head-carousel-box {
    width: 376px;
    height: 32px;
    overflow: hidden;
    cursor: pointer;
    background: #e8efff;
    border-radius: 4px;

    .ant-carousel :deep(.slick-slide) {
      height: 32px;
      overflow: hidden;
      background: #e8efff;
      border-radius: 4px;
    }

    .ant-carousel :deep(.slick-slide h3) {
      color: #fff;
    }

    :deep {
      .slick-dots {
        display: none !important;
      }
    }

    .notifi-item {
      display: flex !important  ;
      align-items: center;
      height: 32px;
      padding: 0 10px;
      font-weight: 600;
      line-height: 32px;
      color: #4e5969;

      i {
        display: block;
        width: 20px;
        height: 20px;
        margin-right: 5px;
        background: url(@/assets/images/notifications/icon-notifi.png) no-repeat;
        background-size: 100%;
      }

      .notifi-msg-text {
        flex: 1;
        padding-top: 2px;
        overflow: hidden;
        color: #142380;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .notifi-popover-box {
    position: absolute;
    top: 45px;
    right: -60px;
    overflow: hidden;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 8px 20px 0 rgba(29, 33, 41, 0.12);
  }
}
@keyframes move {
  0% {
    left: -40px;
  }
  20% {
    left: -30px;
  }
  40% {
    left: -40px;
  }
  60% {
    left: -30px;
  }
  80% {
    left: -40px;
  }
  100% {
    left: -40px;
  }
}
</style>
<style lang="scss">
.popover-user-msg {
  width: 240px;

  .head__user-msg {
    display: flex;
    padding-left: 16px;
    margin-bottom: 12px;

    .ant-avatar {
      width: 40px;
      height: 40px;
    }

    .msg-detail {
      flex: 1;
      margin-left: 5px;
    }

    .head__user-name,
    .head__user-email,
    .head__user-plan {
      margin: 0;
    }

    .head__user-name {
      width: 150px;
      margin-bottom: 2px;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      color: #1d2129;
    }

    .head__user-email {
      width: 150px;
      font-size: 14px;
      line-height: 16px;
      color: #4e5969;
    }

    .head__user-plan {
      width: 100%;
      margin-bottom: 6px;

      span {
        display: flex;
        align-items: center;

        i {
          display: block;
          height: 16px;
          padding: 0 2px;
          margin-right: -4px;
          margin-left: -4px;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          color: #fff;
          background: #c9cdd4;
          border-radius: 2px;
          transform: scale(0.9);
        }

        b {
          margin-left: 4px;
          font-size: 12px;
          font-weight: normal;
          color: #86909c;
        }
      }
    }
  }

  .ant-popover-inner-content {
    padding-right: 5px;
    padding-left: 5px;
  }

  .head__actions {
    width: 100%;

    div {
      width: 100%;
      height: 36px;
      line-height: 36px;
      cursor: pointer;
      border-radius: 4px;

      &:hover {
        background: #f2f3f5;
      }

      &.user-btn {
        display: flex;
        align-items: center;
        padding: 0 10px;

        i {
          display: block;
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
      }

      &.to-account {
        i {
          background: url('@/assets/images/icon-user-account.png') no-repeat;
          background-size: 100%;
        }
      }

      &.to-logout {
        i {
          background: url('@/assets/images/icon-user-logout.png') no-repeat;
          background-size: 100%;
        }
      }
    }
  }
}
</style>
