import * as ConstantRouter from '@/constant/router';
import * as ConstantLabel from '@/constant/label';
import loadable from '@/utils/loadable';

const route = {
  path: 'themes',
  name: ConstantRouter.THEMES,
  component: loadable(() => import(/* webpackChunkName: "media" */ '@/pages/themes/index.vue')),
  meta: {
    name: ConstantLabel.THEMES,
    showUploadBox: true,
  },
};

export default route;
