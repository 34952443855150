<script setup lang="ts">
import PreviewDialog from '@/pages/media/components/preview-dialog.vue';

const helpVisible = ref(false);
const helpVideoRef = ref();
const helpVideoPlaying = ref<null | boolean>(null);
const helpUrl =
  'https://static.yololiv.com/static/videos/4351a091-02e9-4c10-a5d5-1523bc62b650_YoloCast-help.mp4';
const helpCoverUrl =
  'https://static.yololiv.com/static/imgs/ecfd26ba-3cac-4eaf-8cb4-0a88f6ed4603_help-cover.png?imageMogr2/thumbnail/!30p';
const previewVisible = ref(false);
const previewVideo = {
  url: '',
  format: '',
  currentPlayTime: '',
};

function playHelpVideo() {
  helpVideoPlaying.value = true;
  helpVideoRef.value.play();
}

function stopHelpVideo() {
  helpVideoPlaying.value = false;
  helpVideoRef.value.pause();
}

function changeHelp() {
  helpVisible.value = !helpVisible.value;
  if (!helpVisible.value) {
    stopHelpVideo();
    helpVideoPlaying.value = null;
    helpVideoRef.value.currentTime = 0;
  }
}

function previewHelpVideo() {
  previewVideo.url = helpUrl;
  previewVideo.format = 'mp4';
  previewVideo.currentPlayTime = helpVideoRef.value.currentTime;
  previewVisible.value = true;
  stopHelpVideo();
}

function previewClose(currentTime: number) {
  helpVideoRef.value.currentTime = currentTime;
  previewVisible.value = false;
}

onMounted(() => {
  helpVideoRef.value.addEventListener('ended', () => {
    helpVideoPlaying.value = null;
  });
});
</script>

<template>
  <div class="helper" v-drag="{ dragByHandleClass: 'yolocast-drag' }">
    <div class="help-box" :class="{ 'help-box-show': helpVisible }">
      <div class="bg-blue"></div>
      <div class="bg-gray"></div>
      <div class="helper-title">Welcome to YoloCast</div>
      <div class="helper-desc">Hi there !</div>
      <div class="helper-tips">Thank you for choosing YoloCast!</div>
      <div class="video-box">
        <video ref="helpVideoRef" :src="helpUrl" :poster="helpCoverUrl"></video>
        <i class="icon-help-play-start" @click="playHelpVideo" v-if="helpVideoPlaying === null"></i>
        <i class="icon-help-pause" @click="stopHelpVideo" v-if="helpVideoPlaying === true"></i>
        <i class="icon-help-play" @click="playHelpVideo" v-if="helpVideoPlaying === false"></i>
        <div class="help-full-screen" v-if="helpVideoPlaying !== null">
          <i class="icon-help-full-screen" @click="previewHelpVideo"></i>
        </div>
      </div>
      <div class="contact-box">
        <div class="contact-title">Contact Us</div>
        <div class="contact-msg">
          Whether you’re looking for product information, purchase consultation or assistance for
          live streaming, please contact us at <br />
          <a href="mailto:contact@yololiv.com">contact@yololiv.com</a>
        </div>
        <div class="helper-over">We’ll get back to you shortly. Thanks!</div>
      </div>
    </div>
    <div class="helper-indicator yolocast-drag" @click="changeHelp">
      <img
        :style="{ opacity: !helpVisible ? 1 : 0 }"
        src="@/assets/images/icon-contact.png"
        alt=""
      />
      <img
        :style="{ opacity: helpVisible ? 1 : 0 }"
        src="@/assets/images/icon-contact-show.png"
        alt=""
      />
    </div>
    <slot></slot>
  </div>
  <PreviewDialog
    v-if="previewVisible"
    :current-item="previewVideo"
    @close="previewClose"
  ></PreviewDialog>
</template>

<style lang="scss" scoped>
.helper {
  position: fixed;
  right: 35px;
  bottom: 35px;
  z-index: 999;
  width: 60px;
  height: 60px;
}

.helper-indicator {
  width: 60px;
  height: 60px;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 4px 4px 16px 0 rgba(20, 35, 128, 0.25);

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transition: all 0.3s;
  }
}

.help-box {
  position: absolute;
  top: -614px;
  right: -11px;
  width: 376px;
  height: 594px;
  padding: 20px;
  pointer-events: none;
  visibility: hidden;
  border-radius: 8px;
  box-shadow: 0 8px 20px 0 rgba(29, 33, 41, 0.12);
  opacity: 0;
  transition: all 0.3s;
  transform: scale(0);
  transform-origin: 376px 594px;

  a {
    text-decoration: underline;
  }

  &.help-box-show {
    pointer-events: auto;
    visibility: initial;
    opacity: 1;
    transition: all 0.3s;
    transform: scale(1);
  }

  .bg-blue {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: -1;
    height: 200px;
    background: linear-gradient(315deg, #2d49b7 0%, #142380 100%);
    border-radius: 8px 8px 0 0;
  }

  .bg-gray {
    position: absolute;
    top: 200px;
    right: 0;
    left: 0;
    z-index: -1;
    height: 393px;
    background: #f7f8fa;
    border-radius: 8px;
    box-shadow: 0 8px 20px 0 rgba(29, 33, 41, 0.12);
  }

  .helper-title {
    padding: 0 10px;
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: 900;
    line-height: 33px;
    color: #fff;
  }

  .helper-desc {
    padding: 0 10px;
    margin-bottom: 5px;
    font-size: 24px;
    line-height: 28px;
    color: #fff;
  }

  .helper-tips {
    padding: 0 10px;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
  }

  .video-box {
    position: relative;
    width: 100%;
    height: 189px;
    margin-bottom: 20px;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 4px;

    &:hover {
      .icon-help-play,
      .icon-help-pause {
        display: block;
      }
    }

    .icon-help-play-start {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      cursor: pointer;
      background: rgba(0, 0, 0, 0.3) url(@/assets/images/icon-help-play.png) no-repeat center;
      background-size: 72px;
    }

    .icon-help-play {
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      width: 100%;
      height: 100%;
      cursor: pointer;
      background: rgba(0, 0, 0, 0.3) url(@/assets/images/icon-help-play.png) no-repeat center;
      background-size: 72px;
    }

    .icon-help-pause {
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      width: 100%;
      height: 100%;
      cursor: pointer;
      background: rgba(0, 0, 0, 0.3) url(@/assets/images/icon-help-pause.png) no-repeat center;
      background-size: 72px;
    }

    .help-full-screen {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 336px;
      height: 48px;
      padding: 0 12px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
      border-radius: 0 0 4px 4px;

      i {
        display: block;
        width: 24px;
        height: 24px;
        cursor: pointer;
        background: url(@/assets/images/icon-help-full-screen.png) no-repeat;
        background-size: 100%;
      }
    }

    video {
      width: 100%;
      height: 100%;
    }
  }

  .contact-box {
    width: 100%;
    height: 209px;
    padding: 25px 20px;
    background: #fff;
    border-top: 4px solid #142380;
    border-radius: 4px;

    .contact-title {
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      color: #1d2129;
    }

    .contact-msg {
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 20px;
      color: #1d2129;
      word-break: break-word;
    }

    .helper-over {
      font-size: 14px;
      line-height: 20px;
      color: #1d2129;
    }
  }
}
</style>
