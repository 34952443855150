import { v4 as uuidv4 } from 'uuid';

export const AuthConfigMock = [
  {
    id: uuidv4(),
    name: 'Overview',
    features: [
      {
        id: uuidv4(),
        name: '7-Day Money Back Guarantee',
        tips: "If you're not 100% satisfied with your paid subscription plan in your first 7 days, you can cancel at any time to receive a full refund.",
        type: 1,
        dict: {
          '8': { text: '', enableFlag: true },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
      {
        id: uuidv4(),
        name: 'User',
        tips: 'Unlimited team members to manage your events together.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: true },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
      {
        id: uuidv4(),
        name: 'Social Login',
        tips: 'Support login with Google account',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: true },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'Events',
    features: [
      {
        id: uuidv4(),
        name: 'Concurrent Events',
        tips: 'Account for overlapping events by streaming multiple events simultaneously.',
        type: 2,
        dict: {
          '8': { text: '1', enableFlag: false },
          '9': { text: '2', enableFlag: false },
          '10': { text: '5', enableFlag: false },
          '21': { text: '5', enableFlag: false },
        },
      },
      {
        id: uuidv4(),
        name: 'Maximum Event Duration',
        tips: 'Stream your event for up to 12 consecutive hours.',
        type: 2,
        dict: {
          '8': { text: '12 Hours', enableFlag: false },
          '9': { text: '12 Hours', enableFlag: false },
          '10': { text: '12 Hours', enableFlag: false },
          '21': { text: '12 Hours', enableFlag: false },
        },
      },
      {
        id: uuidv4(),
        name: 'Concurrent Viewers',
        tips: 'The highest number of viewers for an event at the same time.',
        type: 2,
        dict: {
          '8': { text: '/', enableFlag: false },
          '9': { text: '1000', enableFlag: false },
          '10': { text: '5000', enableFlag: false },
          '21': { text: '5000', enableFlag: false },
        },
      },
      {
        id: uuidv4(),
        name: 'Trailer',
        tips: "Engage your viewers before your event begins, with a looping video on the player when you're not live.",
        type: 1,
        dict: {
          '8': { text: '', enableFlag: false },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
      {
        id: uuidv4(),
        name: 'Thumbnail',
        tips: 'Display this thumbnail before the event.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: true },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
      {
        id: uuidv4(),
        name: 'Video Resolution',
        tips: 'Deliver high-quality video with resolution up to 1080p.',
        type: 2,
        dict: {
          '8': { text: '1080P', enableFlag: false },
          '9': { text: '1080P', enableFlag: false },
          '10': { text: '1080P', enableFlag: false },
          '21': { text: '1080P', enableFlag: false },
        },
      },
      {
        id: uuidv4(),
        name: '24*7 Live Streaming',
        tips: 'The special event that you can stream to multiple times without changing your event page link or embed code.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: true },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'Sources',
    features: [
      {
        id: uuidv4(),
        name: 'YoloBox Pro',
        tips: 'Live broadcast more conveniently with YoloBox Pro.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: true },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
      {
        id: uuidv4(),
        name: 'Custom RTMP',
        tips: 'Supports any encoder using RTMP protocol.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: true },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
      {
        id: uuidv4(),
        name: 'Custom SRT',
        tips: 'Supports any encoder using the SRT protocol.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: true },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
      {
        id: uuidv4(),
        name: 'Simulated Live',
        tips: 'Reach audiences who couldn’t tune in live by re-event recorded or uploaded videos to any destination at any time.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: false },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'Distributions',
    features: [
      {
        id: uuidv4(),
        name: 'Stream to Facebook/YouTube/Twitch,etc.',
        tips: 'Reach your audience by streaming simultaneously to Facebook/YouTube/Twitch or any RTMP destination.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: true },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
      {
        id: uuidv4(),
        name: 'Social Scheduling',
        tips: 'Attract audiences by creating event pages on social platforms ahead of time.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: true },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
      {
        id: uuidv4(),
        name: 'Concurrent Stream Destinations',
        tips: 'Streaming to multiple destinations simultaneously.',
        type: 2,
        dict: {
          '8': { text: '10', enableFlag: false },
          '9': { text: '10', enableFlag: false },
          '10': { text: '30', enableFlag: false },
          '21': { text: '30', enableFlag: false },
        },
      },
      {
        id: uuidv4(),
        name: 'Bandwidth',
        tips: 'Amount of bandwidth availble for live and video hosting palyer.',
        type: 2,
        dict: {
          '8': { text: '/', enableFlag: false },
          '9': { text: '1024GB/month', enableFlag: false },
          '10': { text: '2048GB/month', enableFlag: false },
          '21': { text: '512GB/month', enableFlag: false },
        },
      },
      {
        id: uuidv4(),
        name: 'YoloCast Video Player',
        tips: 'Creates its own watch link for each event on the YoloCast video Player and you can share it to social platforms or embedding on your website.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: false },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
      {
        id: uuidv4(),
        name: 'Appearance Settings',
        tips: "Customize your event's watch page, including setting branding, countdown timer, player logo and scheduled words.",
        type: 1,
        dict: {
          '8': { text: '', enableFlag: false },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
      {
        id: uuidv4(),
        name: 'Social Share',
        tips: 'Let you and your viewers promote your streams with easy social media sharing.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: false },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
      {
        id: uuidv4(),
        name: 'Live DVR',
        tips: "Allow your viewers to restart or jump to specific points in your event while it's still live.",
        type: 1,
        dict: {
          '8': { text: '', enableFlag: false },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
      {
        id: uuidv4(),
        name: 'High Frame Rate(Up to 60fps)',
        tips: 'Capture motion fluidly by streaming with frame rates up to 60 fps.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: false },
          '9': { text: '', enableFlag: false },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
      {
        id: uuidv4(),
        name: 'Comment',
        tips: 'Freely chat with your viewers while live, and integrating comments from third-party platforms.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: false },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'Stream Support',
    features: [
      {
        id: uuidv4(),
        name: 'ASP(Ardent Streaming Protocol)',
        tips: 'Add an extra delay between the time when your stream is happening to when it shows up in the player. And stores it on the YoloBox Pro to ensure that your live event will not be affected if you have network issues.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: false },
          '9': { text: '', enableFlag: false },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
      {
        id: uuidv4(),
        name: 'Multi-CDN',
        tips: 'If there is a problem we will automatically use the backup CDN so the stream will not be interrupted.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: true },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
      {
        id: uuidv4(),
        name: 'Backup Stream',
        tips: 'To ensure smooth viewing, YoloCast offers a backup stream feature that automatically switches to another stream in case of unexpected interruptions.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: true },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
      // {
      //   id: uuidv4(),
      //   name: 'Multi-network Aggregation(Coming Soon...)',
      //   tips: '*****',
      //   type: 1,
      //   dict: {
      //     '8': { text: '/', enableFlag: true },
      //     '9': { text: '/', enableFlag: true },
      //     '10': { text: '10GB', enableFlag: true },
      //     '21': { text: '10GB', enableFlag: true },
      //   },
      // },
    ],
  },
  {
    id: uuidv4(),
    name: 'Embed',
    features: [
      {
        id: uuidv4(),
        name: 'Embed to Website',
        tips: 'you can use iframe code、js code or link embed the live event or video onto your website.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: false },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
      {
        id: uuidv4(),
        name: 'White-Labeled Embed',
        tips: 'Present a cleaner viewer experience that removes “YoloLiv”logo from the embedded player.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: false },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'Privacy Security',
    features: [
      {
        id: uuidv4(),
        name: 'Password Protection',
        tips: 'Protect your events with a single, secure password.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: false },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'Analytics',
    features: [
      {
        id: uuidv4(),
        name: 'Stream Diagnostics',
        tips: 'Monitors your stream, collecting data and generating alerts in real-time continually.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: true },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
      {
        id: uuidv4(),
        name: 'Real-time Viewer Analytics',
        tips: 'View your audience data in real time when streaming.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: false },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
      {
        id: uuidv4(),
        name: 'Viewer Engagement States',
        tips: 'Understand your reach with engagement graphs and data on total views, unique viewers, and average view duration.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: false },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
      {
        id: uuidv4(),
        name: 'Viewer Insights',
        tips: 'Take a detailed look at how viewers watch your content with data on viewing destinations, devices, and playback quality.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: false },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
      {
        id: uuidv4(),
        name: 'Geo-Analytics',
        tips: 'See where in the world your viewers watched.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: false },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'Media Management',
    features: [
      {
        id: uuidv4(),
        name: 'Storage',
        tips: 'Amount of storage available for storing event recoedings and media files',
        type: 2,
        dict: {
          '8': { text: '50GB', enableFlag: false },
          '9': { text: '100GB', enableFlag: false },
          '10': { text: '500GB', enableFlag: false },
          '21': { text: '50GB', enableFlag: false },
        },
      },
      {
        id: uuidv4(),
        name: 'Cloud Recording',
        tips: 'Automatically record and store your live events in the Media Center.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: true },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
      {
        id: uuidv4(),
        name: 'Cloud Transcoding',
        tips: 'Remove bandwidth burdens by streaming a single source to the cloud.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: true },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
      {
        id: uuidv4(),
        name: 'Cloud Downloads',
        tips: 'Downloading video of your recorded events or content you uploaded to the Media Center.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: true },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
      {
        id: uuidv4(),
        name: 'Trimming',
        tips: 'Cut out dead time by trimming the beginning and ends from your event.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: false },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
      {
        id: uuidv4(),
        name: 'Highlight Clipping',
        tips: 'Capture important moments and share them on social media by quickly clipping highlights mid-event or after your event is over.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: false },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
      {
        id: uuidv4(),
        name: 'Event Replacements',
        tips: 'Showcase the best version by replacing live events with post-produced video without changing links, embeds, or analytics.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: false },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
      {
        id: uuidv4(),
        name: 'Video Marker(Coming Soon...)',
        tips: 'Help viewers easily find significant moments by naming and tagging points in a recorded event.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: false },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'Advanced',
    features: [
      // {
      //   id: uuidv4(),
      //   name: 'YoloCut(Coming Soon...)',
      //   tips: '******',
      //   type: 1,
      //   dict: {
      //     '8': { text: '', enableFlag: false },
      //     '9': { text: '', enableFlag: false },
      //     '10': { text: '', enableFlag: true },
      //     '21': { text: '', enableFlag: true },
      //   },
      // },
      {
        id: uuidv4(),
        name: 'Graphic Overlay',
        tips: 'Enhance your event with graphics like logos, watermarks, lower thirds, full-screen overlays, or basic ads.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: false },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
      {
        id: uuidv4(),
        name: 'Automated Captioning',
        tips: 'Make your recorded events more accessible by automatically adding closed captions.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: false },
          '9': { text: '', enableFlag: false },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
      {
        id: uuidv4(),
        name: 'OverlayLab(Web URL Overlay)',
        tips: 'A truly comprehensive live graphic solution for all scenarios, ranging from professional sports to general live events. No additional production hardware or software needed.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: true },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'Video Monetization',
    features: [
      {
        id: uuidv4(),
        name: 'Donations',
        tips: 'Support linking external sites to accept donations from viewers.',
        type: 1,
        dict: {
          '8': { text: '', enableFlag: false },
          '9': { text: '', enableFlag: true },
          '10': { text: '', enableFlag: true },
          '21': { text: '', enableFlag: true },
        },
      },
    ],
  },
];

export const AddOnsAuthConfig = [
  {
    title: 'Add-Ons',
    uuid: uuidv4(),
    data: [
      {
        title: 'Simulated Live',
        uuid: uuidv4(),
        tips: 'Reach audiences who couldn’t tune in live by re-event recorded or uploaded events to any destination at any time.',
        data: [
          {
            title: 'Trial',
            auth: false,
            uuid: uuidv4(),
          },
          {
            title: 'Essential',
            auth: true,
            uuid: uuidv4(),
          },
          {
            title: 'Growth',
            auth: true,
            uuid: uuidv4(),
          },
          {
            title: 'Monthly Price',
            auth: true,
            uuid: uuidv4(),
            text: '$5',
            textS: '$25',
            textSymbol: '/mo',
          },
        ],
      },
      {
        title: 'Highlight Clipping',
        uuid: uuidv4(),
        tips: 'Capture important moments and share them on social media by quickly clipping highlights mid-event or after your event is over.',
        data: [
          {
            title: 'Trial',
            auth: false,
            uuid: uuidv4(),
          },
          {
            title: 'Essential',
            auth: true,
            uuid: uuidv4(),
          },
          {
            title: 'Growth',
            auth: true,
            uuid: uuidv4(),
          },
          {
            title: 'Monthly Price',
            auth: true,
            uuid: uuidv4(),
            text: '$5',
            textS: '$25',
            textSymbol: '/mo',
          },
        ],
      },
      {
        title: 'Document Display',
        uuid: uuidv4(),
        tips: 'Provide your online viewers with a PDF version of the same documents you share in person, right beside your live stream.',
        data: [
          {
            title: 'Trial',
            auth: false,
            uuid: uuidv4(),
          },
          {
            title: 'Essential',
            auth: true,
            uuid: uuidv4(),
          },
          {
            title: 'Growth',
            auth: true,
            uuid: uuidv4(),
          },
          {
            title: 'Monthly Price',
            auth: true,
            uuid: uuidv4(),
            text: '$5',
            textS: '$25',
            textSymbol: '/mo',
          },
        ],
      },
      {
        title: 'Video Markers',
        uuid: uuidv4(),
        tips: 'Help viewers easily find significant moments by naming and tagging points in a recorded event.',
        data: [
          {
            title: 'Trial',
            auth: false,
            uuid: uuidv4(),
          },
          {
            title: 'Essential',
            auth: true,
            uuid: uuidv4(),
          },
          {
            title: 'Growth',
            auth: true,
            uuid: uuidv4(),
          },
          {
            title: 'Monthly Price',
            auth: true,
            uuid: uuidv4(),
            text: '$5',
            textS: '$25',
            textSymbol: '/mo',
          },
        ],
      },
      {
        title: 'White-Labeled Embed',
        uuid: uuidv4(),
        tips: 'Present a cleaner viewer experience that removes “YoloLiv”logo from the embedded player.',
        data: [
          {
            title: 'Trial',
            auth: false,
            uuid: uuidv4(),
          },
          {
            title: 'Essential',
            auth: true,
            uuid: uuidv4(),
          },
          {
            title: 'Growth',
            auth: true,
            uuid: uuidv4(),
          },
          {
            title: 'Monthly Price',
            auth: true,
            uuid: uuidv4(),
            text: '$5',
            textS: '$25',
            textSymbol: '/mo',
          },
        ],
      },
      {
        title: 'Graphic Overlays',
        uuid: uuidv4(),
        tips: 'Enhance your event with graphics like logos, watermarks, lower thirds, full-screen overlays, or basic ads.',
        data: [
          {
            title: 'Trial',
            auth: false,
            uuid: uuidv4(),
          },
          {
            title: 'Essential',
            auth: true,
            uuid: uuidv4(),
          },
          {
            title: 'Growth',
            auth: true,
            uuid: uuidv4(),
          },
          {
            title: 'Monthly Price',
            auth: true,
            uuid: uuidv4(),
            text: '$10',
            textS: '$25',
            textSymbol: '/mo',
          },
        ],
      },
      {
        title: 'Automated Captioning',
        uuid: uuidv4(),
        tips: 'Save time, adhere to compliance standards, and make your recorded events more accessible by automatically adding closed captions.',
        data: [
          {
            title: 'Trial',
            auth: false,
            uuid: uuidv4(),
          },
          {
            title: 'Essential',
            auth: false,
            uuid: uuidv4(),
          },
          {
            title: 'Growth',
            auth: true,
            uuid: uuidv4(),
          },
          {
            title: 'Monthly Price',
            auth: true,
            uuid: uuidv4(),
            text: '$10',
            textS: '$25',
            textSymbol: '/mo',
          },
        ],
      },
      {
        title: 'High Frame Rate (Up to 60fps)',
        uuid: uuidv4(),
        tips: 'Capture motion fluidly by streaming with frame rates up to 60 fps.',
        data: [
          {
            title: 'Trial',
            auth: false,
            uuid: uuidv4(),
          },
          {
            title: 'Essential',
            auth: false,
            uuid: uuidv4(),
          },
          {
            title: 'Growth',
            auth: true,
            uuid: uuidv4(),
          },
          {
            title: 'Monthly Price',
            auth: true,
            uuid: uuidv4(),
            text: '$10',
            textS: '$25',
            textSymbol: '/mo',
          },
        ],
      },
      {
        title: 'Manual Scoreboard Overlay',
        uuid: uuidv4(),
        tips: 'Overlay a live scoreboard on your event. Control the scoreboard on your computer, phone, or tablet.',
        data: [
          {
            title: 'Trial',
            auth: false,
            uuid: uuidv4(),
          },
          {
            title: 'Essential',
            auth: false,
            uuid: uuidv4(),
          },
          {
            title: 'Growth',
            auth: true,
            uuid: uuidv4(),
          },
          {
            title: 'Monthly Price',
            auth: true,
            uuid: uuidv4(),
            text: '$10',
            textS: '$25',
            textSymbol: '/mo',
          },
        ],
      },
    ],
  },
];

export const countryList = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Åland Islands', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'Andorra', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: "Cote D'Ivoire", code: 'CI' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: "Korea, Democratic People's Republic of", code: 'KP' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: "Lao People's Democratic Republic", code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'Rwanda', code: 'RW' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia and Montenegro', code: 'CS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan, Province of China', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'United States', code: 'US' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Vietnam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' },
];
