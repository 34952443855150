import { rawRequest } from '@/utils/request';

/**
 * 打点上报
 * 接口文档: https://yolocast-dev.yololiv.com/datacenter/swagger/index.html#/view/post_view_dot_report
 * 技术文档: https://work.yunxi.tv/pages/viewpage.action?pageId=60948560
 */
export const dotReport = (data: any) => {
  return rawRequest.post(`/datacenter/view/dot-report`, data);
};
