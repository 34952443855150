import useUserStore from '@/store/user';
import md5 from 'js-md5';
import { localStore } from './store';

export function getAccountStoreKey(key: string) {
  const userStore = useUserStore();
  const userId = userStore.detail?.userId;
  if (userId) return md5(`${userId}-${key}`);
  return key;
}

export function setAccountStore(key: string, val: any) {
  localStore.set(getAccountStoreKey(key), val);
}

export function getAccountStore(key: string) {
  return localStore.get(getAccountStoreKey(key));
}
