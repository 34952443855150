<script setup lang="ts">
import { LockOutlined } from '@ant-design/icons-vue';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import SubMenu from '@/components/sub-menu/index.vue';
import menu, { IMenuItem } from '@/config/menu';
import * as ConstantRouter from '@/constant/router';
import { PACKAGE_EMBED_ACCESS_DESC } from '@/constant/tip';
import useCurrentPlanStore from '@/store/currentPlan';
import useIntro from '@/store/intro';
import usePermissionStore from '@/store/permission';
import { PermissionEnum } from '@/types/permission';
import upgradeTip from '@/utils/upgradeTip';

const props = defineProps({
  collapsed: Boolean,
  className: String,
});
const router = useRouter();
const route = useRoute();
const intro = useIntro();
const selectedKeys = computed(() => route.matched.map(item => item.name).filter(item => !!item));
const permission = usePermissionStore();
const { hasPermission } = permission;
const currentPlanStore = useCurrentPlanStore();

const matchedRouteKeys = computed(() => {
  return route.matched.map(item => item.name).filter(item => !!item) as string[];
});
const openKeys = ref([...matchedRouteKeys.value]);

onMounted(async () => {
  await nextTick();
  intro.oneStepsUpdate(0, {
    element: document.getElementById('menu-item-broadcasts'),
    title: 'Event Management',
    intro: 'Monitor and review events in the <strong><i>“Events"</i></strong>',
    position: 'right',
  });
  intro.oneStepsUpdate(3, {
    element: document.getElementById('menu-item-sources'),
    title: 'Sources',
    intro:
      'Add new sources and configure settings for hardware and software streaming methods in <strong><i>"Sources"</i></strong>.',
    position: 'right',
  });
  intro.oneStepsUpdate(4, {
    element: document.getElementById('menu-item-reports'),
    title: 'Reports',
    intro:
      'Admins and data nerds rejoice! Dive deep into viewer analytics with <strong><i>"Reports"</i></strong>.',
    position: 'right',
  });
  intro.oneStepsUpdate(5, {
    element: document.getElementById('menu-item-settings'),
    title: 'Account Settings',
    intro: 'Manage users, billing and plan in <strong><i>"Settings."</i></strong>',
    position: 'right',
  });
  intro.twoStepsUpdate(0, {
    element: document.getElementById('menu-item-embed'),
    title: 'Embed to Your Website',
    intro: 'Display events on your website with <strong><i>“Embed"</i></strong>',
    position: 'right',
  });
  intro.twoStepsUpdate(1, {
    element: document.getElementById('menu-item-media'),
    title: 'Media Managerment',
    intro: 'Manage videos, documents, images and more in <strong><i>“Media Center”</i></strong>',
    position: 'right',
  });
  intro.threeStepsUpdate(0, {
    element: document.getElementById('menu-item-sources'),
    title: 'Add a Video Source',
    intro: '',
    position: 'right',
  });
});

function isActive(menu: IMenuItem) {
  return selectedKeys.value.includes(menu.key);
}

function menuItemHasPermission(item: IMenuItem) {
  if (item.key === ConstantRouter.EMBED)
    return hasPermission(PermissionEnum.StreamToYourWebsite) && hasPermission(PermissionEnum.Embed);
  if (item.key === ConstantRouter.REPORTS) {
    if (currentPlanStore?.currentPlanData?.planType === 2) return false;
    if (!hasPermission(PermissionEnum.StreamToYourWebsite)) return false;
    return [
      PermissionEnum.ViewerEngagementStates,
      PermissionEnum.ViewerInsights,
      PermissionEnum.GeoAnalytics,
    ].every(item => hasPermission(item));
  }
  if (item.key === ConstantRouter.SOURCES) {
    if (currentPlanStore?.currentPlanData?.planType === 2) return false;
  }
  return true;
}

function toRoute(item: IMenuItem) {
  if (item.thirdUrl) {
    window.open(item.thirdUrl);
    return;
  }
  if (route.meta.name === item.title) {
    return;
  }
  if (!menuItemHasPermission(item)) {
    if (item.key === ConstantRouter.EMBED) {
      upgradeTip({
        desc: PACKAGE_EMBED_ACCESS_DESC,
      });
    } else {
      upgradeTip();
    }

    return;
  }
  if (item.key === ConstantRouter.SETTINGS) {
    if ([1, 4, undefined].includes(currentPlanStore?.currentPlanData?.planType)) {
      router.push({
        name: ConstantRouter.BILLING,
      });
    } else {
      router.push({
        name: ConstantRouter.PLANS,
      });
    }
    return;
  }
  router.push({
    name: item.key,
    params: item.initRouteParams || route.params,
  });
}
</script>

<template>
  <div class="head__logo">
    <img v-if="props.collapsed" src="@/assets/images/logo-pack-white.png" alt="logo" />
    <img v-else src="@/assets/images/logo-white.png" alt="logo" />
  </div>
  <div class="head__menu" :class="props.className">
    <a-menu mode="inline" :selectedKeys="selectedKeys" :openKeys="openKeys">
      <template v-for="menuItem in menu" :key="menuItem.key">
        <template v-if="!menuItem.children || menuItem.hideChildrenInMenu">
          <div v-if="menuItem.splitLine" class="menu-split-line"></div>
          <a-menu-item
            :key="menuItem.key"
            @click="toRoute(menuItem)"
            :class="{
              'ant-menu-item-disabled': !menuItemHasPermission(menuItem),
              'ant-menu-item-setting': menuItem.key === ConstantRouter.SETTINGS,
              'ant-menu-item-video': menuItem.key === ConstantRouter.VIDEO_TUTORIALS,
            }"
            :id="'menu-item-' + menuItem.key"
          >
            <template #icon>
              <i :class="isActive(menuItem) ? menuItem.activeIcon : menuItem.icon"></i>
            </template>
            <div style="display: flex; align-items: center; justify-content: space-between">
              {{ menuItem.title }}
              <img
                v-if="menuItem.newFeature"
                src="@/assets/images/icon-new.png"
                alt="icon-new"
                class="icon-new"
              />
              <img
                v-if="menuItem.key === ConstantRouter.YOLOCUT_LIST"
                src="@/assets/images/icon-beta.png"
                alt="icon-beta"
                class="icon-beta"
              />
              <b
                v-if="menuItem.key === ConstantRouter.SETTINGS"
                v-badge="{
                  text: 'Upgrade',
                  backgroundColor: 'rgb(255, 136, 25)',
                  top: '-7px',
                  right: '-21px',
                  fontWeight: 400,
                }"
                >&emsp;</b
              >
              <LockOutlined v-if="!menuItemHasPermission(menuItem)" />
            </div>
          </a-menu-item>
        </template>
        <template v-else>
          <div v-if="menuItem.splitLine" class="menu-split-line"></div>
          <sub-menu
            :key="menuItem.key"
            :menu-info="menuItem"
            @menuClick="toRoute"
            :isActive="isActive"
          />
        </template>
      </template>
    </a-menu>
  </div>
</template>

<style lang="scss" scoped>
.head__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;

  img {
    height: 26px;
  }
}

.head__menu {
  :deep {
    .ant-menu {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #fff;
      background: transparent !important;
      border-right: none;
    }

    .ant-menu-item {
      width: unset;
      height: auto !important;
      padding: 12px !important;
      margin: 8px !important;
      line-height: unset !important;
      color: rgba(255, 255, 255, 0.8) !important;
      border-radius: 4px;

      &:active {
        background: transparent;
      }

      &:hover {
        color: #fff !important;
        background: rgba(255, 255, 255, 0.1);
      }
    }

    .ant-menu-item-icon {
      display: block;
      width: 20px;
      height: 20px;
    }

    .ant-menu-title-content {
      display: block;
    }

    .ant-menu-item-selected {
      color: #fff !important;
      background-color: #5265b3 !important;

      &::after {
        opacity: 0 !important;
      }
    }

    .ant-menu.ant-menu-inline-collapsed {
      .ant-menu-title-content {
        display: none;
        text-align: center;
      }
    }

    .ant-menu-item-setting {
      position: absolute;
      right: 0;
      bottom: 160px;
      left: 0;
    }

    .ant-menu-item-video {
      position: absolute;
      right: 0;
      bottom: 100px;
      left: 0;
    }
  }

  .icon-beta {
    position: relative;
    top: -1px;
    left: 5px;
    width: 28px;
  }

  .icon-new {
    position: absolute;
    right: 12px;
    width: 28px;
  }

  .menu-split-line {
    width: 140px;
    height: 1px;
    margin: 18px auto;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
  }
}

.ant-tooltip-content {
  .anticon {
    margin-right: 4px;
  }
}

.no-brandwidth-side {
  :deep {
    .ant-menu-item-setting {
      position: absolute;
      right: 0;
      bottom: 60px;
      left: 0;
    }

    .ant-menu-item-video {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}
</style>

<style lang="scss">
.icon-beta {
  position: relative;
  top: -1px;
  left: 5px;
  width: 28px;
  margin-right: 6px;
}
</style>
