import { defineStore } from 'pinia';
import { INotifications, getAlertListBroadcast, getAlertAndBanner } from '@/api/notifications';
import { sessionStore } from '@/utils/store';

interface IError {
  message: string;
  code: number;
}

const useNotifications = defineStore('notifications', {
  state: (): {
    loading: boolean;
    error: Nullable<IError>;
    alertList: Nullable<Array<INotifications>>;
    bannerList: Nullable<Array<INotifications>>;
    broadcastList: Nullable<Array<INotifications>>;
    broadcastNum: number;
    hasNews: boolean;
  } => {
    return {
      loading: true,
      error: null,
      alertList: null,
      bannerList: null,
      broadcastList: null,
      broadcastNum: 0,
      hasNews: false,
    };
  },
  getters: {},
  actions: {
    // 获取弹窗类通知和横幅类通知
    async fetchAlertAndBanner() {
      try {
        const { data } = await getAlertAndBanner({
          booleanLogin: sessionStore.get('booleanLogin') || 0,
        });
        this.alertList = data.alertData;
        this.bannerList = data.bannerData;
        sessionStore.set('booleanLogin', 0);
        return true;
      } catch (error) {
        this.error = error as IError;
        return false;
      } finally {
        this.loading = false;
      }
    },
    // 走马灯类通知
    async fetchNotificationsBroadcastList() {
      try {
        const { data } = await getAlertListBroadcast();
        this.broadcastList = data.broadcasts;
        this.hasNews = data.hasUnread;
        return true;
      } catch (error) {
        this.error = error as IError;
        return false;
      } finally {
        this.loading = false;
      }
    },
    clearNotificationsNews() {
      this.hasNews = false;
    },
  },
});

export default useNotifications;
