<script lang="ts" setup>
export interface Instance {
  show: () => void;
  hide: () => void;
}
const emits = defineEmits(['sure', 'skip']);
const visible = ref(false);
const show = () => {
  visible.value = true;
};

const hide = () => {
  visible.value = false;
};

const skip = () => {
  emits('skip');
};

defineExpose({
  show,
  hide,
});
</script>

<template>
  <a-modal
    v-model:visible="visible"
    :footer="null"
    :closable="false"
    :maskClosable="false"
    wrapClassName="newbie-welcome"
    :width="800"
  >
    <img
      class="newbie-welcome__img"
      src="@/assets/images/newbie-welcome.png"
      alt="newbie-welcome.png"
    />
    <div class="newbie-welcome__main">
      <p class="newbie-welcome__title">Welcome to YoloCast !</p>
      <p class="newbie-welcome__desc">
        We’re offering a <b>14 Days Free Trial</b> with 100GB of bandwidth and 5GB of storage. No
        credit card required.
      </p>
      <a-space :size="20">
        <a-button class="newbie-welcome__sure" @click="skip"> Done </a-button>
      </a-space>
    </div>
  </a-modal>
</template>

<style lang="scss">
.newbie-welcome {
  .ant-modal-body {
    padding: 0;
  }
}

.newbie-welcome__img {
  width: 100%;
  height: 400px;
}

.newbie-welcome__main {
  padding: 30px 80px 35px;
  text-align: center;
}

.newbie-welcome__title {
  margin-bottom: 15px;
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  color: #1d2129;
}

.newbie-welcome__desc {
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #1d2129;
}

.newbie-welcome__cancel {
  width: 180px;
  height: 40px;
  padding: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
  color: #86909c;
  border: none;
  border-radius: 20px;
}

.newbie-welcome__sure {
  width: 180px;
  height: 40px;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  color: #4e5969;
  border: 1px solid #4e5969;
  border-radius: 20px;
}
</style>
