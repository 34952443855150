<script setup lang="ts">
import { useRoute } from 'vue-router';

import { queryAllInfo } from '@/api/user';
import Help from '@/components/help/index.vue';
import Notifications from '@/components/package-tip/notifications.vue';
import useCurrentPlanStore from '@/store/currentPlan';
import useCurrentResource from '@/store/currentResource';
import useIntro from '@/store/intro';
import useNotifications from '@/store/notifications';
import usePackageTip from '@/store/packageTip';
import usePageStore from '@/store/page';
import usePermissionStore from '@/store/permission';
import useUserStore from '@/store/user';

const userStore = useUserStore();
const currentPlanStore = useCurrentPlanStore();
const currentResource = useCurrentResource();
const notificationsStore = useNotifications();
const permission = usePermissionStore();
const route = useRoute();
const intro = useIntro();
const pageStore = usePageStore();
const packageStore = usePackageTip();

onMounted(async () => {
  // 初始化获取全部的用户信息、权限信息、套餐信息、存储信息
  let mainInfo: any;
  try {
    const { data } = await queryAllInfo();
    mainInfo = data;
  } catch (error) {
    return false;
  }
  await userStore.setUserInfo(mainInfo.userInfo);
  await currentPlanStore.setCurrentPlan(mainInfo.planInfo);
  await permission.setCurrentPlan(mainInfo.permissionList);
  currentResource.setCurrentResource(mainInfo.resourceInfo);
  pageStore.homeUpdate(true);
  setTimeout(async () => {
    // currentPlanStore.fetchInvitationCode();
    // 付费用户禁止弹窗，禁止横幅消息
    if ([2, 3, undefined].includes(currentPlanStore.currentPlanData?.planType)) {
      notificationsStore.fetchAlertAndBanner();
    }
    // await nextTick();
    // intro.oneStepsUpdate(6, {
    //   element: document.getElementById('contact-help-placeholder'),
    //   title: 'Feeling Stuck?',
    //   intro: 'Our friendly streaming coaches are ready and waiting to help, seven days a week.',
    //   position: 'top',
    // });
  }, 1300);
});

/** 消息通知 */
watch(
  () => notificationsStore.alertList,
  notificationsData => {
    if (route.meta.noOverdue) {
      return false;
    }
    if (notificationsData && notificationsData.length > 0) {
      packageStore.notificationsShowUpdate(true);
    }
  },
);
</script>

<template>
  <div class="app-container">
    <router-view></router-view>
    <Help>
      <div class="contact-help-placeholder" id="contact-help-placeholder"></div>
    </Help>
    <Notifications></Notifications>
  </div>
</template>

<style lang="scss" scoped>
.app-container {
  min-height: 100vh;
}

.contact-help-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
</style>
