import { defineStore } from 'pinia';

// main is the name of the store. It is unique across your application
// and will appear in devtools
export const useMainStore = defineStore('main', {
  // a function that returns a fresh state
  state: () => ({
    counter: 1,
    name: 'Eduardo',
  }),
  // optional getters
  getters: {
    // getters receive the state as first parameter
    doubleCount: state => state.counter * 2,
    // use getters in other getters
    doubleCountPlusOne(): number {
      return this.doubleCount * 2 + 1;
    },
  },
  // optional actions
  actions: {
    reset() {
      // `this` is the store instance
      this.counter = 0;
    },
  },
});

export const uploadModal = defineStore('uploadCom', {
  state: () => ({
    uploadModal: {
      visible: false,
      count: 0,
      uploadList: [],
    },
  }),
  actions: {
    uploadModalUpdate(state: any) {
      this.uploadModal = state;
    },
  },
});
