<script setup lang="ts">
export interface Instance {
  show: () => void;
  hide: () => void;
  setHasGones: (value: number) => void;
}
const total = 3;
const emits = defineEmits(['oneStep', 'twoStep', 'threeStep', 'doneAll']);
const visible = ref(false);
const hasGones = ref(0);

const hasGonesPercent = computed(() => {
  if (hasGones.value === 0) return 0;
  if (hasGones.value === 1) return 33;
  if (hasGones.value === 2) return 66;
  return 100;
});

const show = () => {
  visible.value = true;
};

const hide = () => {
  visible.value = false;
};

const setHasGones = (value: number) => {
  if (hasGones.value > value) {
    return;
  }
  hasGones.value = value;
};

const oneStep = () => {
  emits('oneStep');
};

const twoStep = () => {
  if (hasGones.value >= 1) {
    emits('twoStep');
  }
};

const threeStep = () => {
  if (hasGones.value >= 2) {
    emits('threeStep');
  }
};

defineExpose({
  show,
  hide,
  setHasGones,
});
</script>

<template>
  <a-popover
    placement="topLeft"
    :visible="visible"
    class="newbie-step-popover"
    overlayClassName="newbie-step-popover-overlay"
  >
    <template #title>
      <div class="step-head">
        <div class="step-head__title">
          {{ hasGones === 3 ? 'Congratulations!' : 'Get Started' }}
          <span v-if="hasGones === 3" @click="emits('doneAll')">I'm Done</span>
          <i v-else @click="visible = false"></i>
        </div>
        <div class="step-head__desc">
          {{
            hasGones === 3
              ? 'Now that you have a good understanding of how to use YoloCast for live streaming, try creating your very first live event.'
              : 'Set up your YoloCast account and start your first live stream.'
          }}
        </div>
        <div class="step-head__progress" v-if="hasGonesPercent !== 100">
          <em>{{ hasGonesPercent }}%</em>
          <a-progress
            strokeColor="#FF0043"
            :strokeWidth="16"
            :percent="hasGonesPercent"
            :showInfo="false"
          />
        </div>
      </div>
    </template>
    <template #content>
      <div class="step-content">
        <ul>
          <li
            :class="{
              'step-content-selected': hasGones >= 1,
              'step-content-one-selected': hasGones >= 1,
              'step-content-enable': hasGones >= 0,
            }"
            @click="oneStep"
          >
            <i>
              <svg width="100%" height="100%">
                <polyline
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                  points="10,15 14,19 21,11"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="tick"
                />
              </svg>
            </i>
            <span>Welcome + Dashboard Overview</span> <b></b>
          </li>
          <li
            :class="{
              'step-content-selected': hasGones >= 2,
              'step-content-two-selected': hasGones >= 2,
              'step-content-enable': hasGones >= 1,
            }"
            @click="twoStep"
          >
            <i>
              <svg width="100%" height="100%">
                <polyline
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                  points="10,15 14,19 21,11"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="tick"
                />
              </svg>
            </i>
            <span>Viewership Tools + Media</span> <b></b>
          </li>
          <li
            :class="{
              'step-content-selected': hasGones >= 3,
              'step-content-three-selected': hasGones >= 3,
              'step-content-enable': hasGones >= 2,
            }"
            @click="threeStep"
          >
            <i>
              <svg width="100%" height="100%">
                <polyline
                  fill="none"
                  stroke="#fff"
                  stroke-width="2"
                  points="10,15 14,19 21,11"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="tick"
                />
              </svg>
            </i>
            <span>Adding a Streaming Method</span> <b></b>
          </li>
        </ul>
      </div>
      <div class="step-action" @click="emits('doneAll')" v-if="hasGones !== 3">
        <a>No thanks</a>
      </div>
    </template>
    <div class="newbie-step" v-drag @click="visible = !visible">
      <i class="icon-newbie"></i>
      Get Started <b>{{ total - hasGones }}</b>
    </div>
  </a-popover>
</template>

<style lang="scss" scoped>
.newbie-step {
  position: fixed;
  bottom: 55px;
  left: 220px;
  z-index: 1009;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 151px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  cursor: pointer;
  user-select: none;
  background: #ff0043;
  border-radius: 20px;
  box-shadow: 0 14px 8px -13px rgba(255, 0, 67, 0.5);

  i {
    width: 20px;
    height: 20px;
    margin-right: 4px;
    background: url('@/assets/images/icon-start-newbie.png') no-repeat center / cover;
  }

  b {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 20px;
    height: 20px;
    font-style: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #ff0043;
    text-align: center;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  }
}

.step-head {
  padding: 15px 20px;
}

.step-head__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #1d2129;

  i {
    width: 20px;
    height: 20px;
    cursor: pointer;
    background: url('@/assets/images/icon-arrows-top.png') no-repeat center / cover;
    transition: all 0.5s;

    &:hover {
      transform: rotate(180deg);
    }
  }

  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #86909c;
    cursor: pointer;
  }
}

.step-head__desc {
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #4e5969;
}

.step-head__progress {
  display: flex;
  align-items: center;

  em {
    padding-top: 2px;
    margin-right: 6px;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    color: #1d2129;
  }
}

.step-content {
  padding: 10px 0;

  li {
    display: flex;
    align-items: center;
    padding: 10px 15px 10px 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #999;

    span {
      flex: 1;
    }

    i {
      position: relative;
      width: 30px;
      height: 30px;
      margin-right: 10px;
      background: url('@/assets/images/media/icon-not-select.png') no-repeat center / cover;
      opacity: 0.5;

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        display: none;
        transform: translate(-50%, -50%);
      }

      .tick {
        stroke-dasharray: 50;
        stroke-dashoffset: 50;
      }
    }
  }

  @keyframes tick {
    from {
      stroke-dashoffset: 50;
    }

    to {
      stroke-dashoffset: 0;
    }
  }

  .step-content-one-selected,
  .step-content-two-selected,
  .step-content-three-selected {
    i {
      background-image: url('@/assets/images/media/icon-select.png');

      svg {
        display: block;
      }

      .tick {
        animation: tick 0.8s ease-in-out;
        animation-fill-mode: forwards;
        animation-delay: 0.3s;
      }
    }
  }

  .step-content-enable {
    color: #4e5969;
    cursor: pointer;

    i {
      opacity: 1;
    }

    b {
      width: 20px;
      height: 20px;
      margin-left: 10px;
      background: no-repeat center / cover;
    }

    &:hover {
      background: #f2f3f5;
    }

    &:not(.step-content-selected):hover {
      i {
        background-image: url('@/assets/images/media/icon-not-select-hover.png');
      }

      b {
        background-image: url('@/assets/images/icon-arrows-right-red.png');
      }
    }
  }
}

.step-action {
  padding: 15px;
  margin-top: -10px;
  text-align: right;

  a {
    color: #86909c;
  }
}
</style>
<style lang="scss">
.newbie-step-popover-overlay {
  width: 330px;

  .ant-popover-title {
    padding: 0;
  }

  .ant-popover-inner-content {
    padding: 0;
  }
}
</style>
