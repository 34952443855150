export const PLAN_NAME = {
  // 初级套餐
  PRIMARY: 'Starter',
  // 中级套餐
  INTERMEDIATE: 'Professional',
  // 高级套餐
  ADVANCED: 'Business',
  // 盒子套餐
  BOX: 'Exclusive',
  BONDING20G: 'Basic',
  BONDING50G: 'Plus',
  BONDING100G: 'Pro',
};
