<script setup lang="ts">
import TcPlayer from '@/components/tcplayer/index.vue';
import DefaultAutomatedUrl from '@/assets/images/login/icon-login-automated.png';
import ActivedAutomatedUrl from '@/assets/images/login/icon-login-automated-actived.png';
import DefaultMultiUrl from '@/assets/images/login/icon-login-multi.png';
import ActivedMultiUrl from '@/assets/images/login/icon-login-multi-actived.png';
import DefaultSimulatedUrl from '@/assets/images/login/icon-login-simulated.png';
import ActivedSimulatedUrl from '@/assets/images/login/icon-login-simulated-actived.png';
import DefaultConcurrentUrl from '@/assets/images/login/icon-login-concurrent.png';
import ActivedConcurrentUrl from '@/assets/images/login/icon-login-concurrent-actived.png';

const changedTcPlayer = ref(false);
const tcPlayerRef = ref();
const typeList = ['Discover the difference YoloCast provides.'];
const advanceList = ref([
  {
    videoUrl: 'https://static.yololiv.com/static/videos/9258351d-7768-4adc-9109-78097d16e79c_2.mp4',
    iconDefaultUrl: DefaultAutomatedUrl,
    iconActivedUrl: ActivedAutomatedUrl,
    title: 'Automated Scheduling',
    desc: 'Save time and plan ahead by scheduling broadcasts before you go live.',
    select: true,
    poster:
      'https://static.yololiv.com/static/imgs/d0582a44-44aa-43ba-9522-cf0161f751cd_login-2-scheduling.jpg',
  },
  {
    videoUrl: 'https://static.yololiv.com/static/videos/b2e7dd79-4d10-4868-a8e4-6fb5d7eb648b_3.mp4',
    iconDefaultUrl: DefaultMultiUrl,
    iconActivedUrl: ActivedMultiUrl,
    title: 'Multi-Streaming',
    desc: 'Multistream your broadcasts to multiple destinations with a single stream at the same time.',
    select: false,
    poster:
      'https://static.yololiv.com/static/imgs/76755e0d-c5fd-4b6e-a434-8951ec7a36c2_login-3-multi.jpg',
  },
  {
    videoUrl: 'https://static.yololiv.com/static/videos/45ab5c70-7619-4684-8f6f-ffcd028b86c8_4.mp4',
    iconDefaultUrl: DefaultSimulatedUrl,
    iconActivedUrl: ActivedSimulatedUrl,
    title: 'Simulated Live',
    desc: 'Rebroadcast or uploaded stream to your viewers as if it were happening live.',
    select: false,
    poster:
      'https://static.yololiv.com/static/imgs/b2a6dcb1-226e-4db1-977e-ae85d488ea17_login-4-simulated.jpg',
  },
  {
    videoUrl: 'https://static.yololiv.com/static/videos/8dc18158-fdcb-4e8b-8ccd-ab0d1ab72fd1_5.mp4',
    iconDefaultUrl: DefaultConcurrentUrl,
    iconActivedUrl: ActivedConcurrentUrl,
    title: 'Concurrent Broadcasts',
    desc: 'Create multiple live events and stream to multiple destinations separately and simultaneously.',
    select: false,
    poster:
      'https://static.yololiv.com/static/imgs/cbe14982-0387-4b96-bd85-d982502d6de0_login-5-concurrent.jpg',
  },
]);
const currentAdvance = ref(advanceList.value[0]);
const playOption = computed(() => {
  if (currentAdvance.value && currentAdvance.value.videoUrl) {
    return {
      src: currentAdvance.value.videoUrl,
      poster: currentAdvance.value.poster,
    };
  }
  return null;
});

function selectCurrentAdvance(item: any) {
  changedTcPlayer.value = false;
  advanceList.value.forEach(item => {
    item.select = false;
  });
  item.select = true;
  currentAdvance.value = item;
  let timer: any = setTimeout(() => {
    changedTcPlayer.value = true;
    clearTimeout(timer);
    timer = null;
  }, 100);
}

function stopPlayer() {
  if (tcPlayerRef.value && tcPlayerRef.value.getPlayerInstance()) {
    tcPlayerRef.value.getPlayerInstance().pause();
    changedTcPlayer.value = false;
  }
}

function startPlayer() {
  changedTcPlayer.value = true;
}

defineExpose({
  stopPlayer,
  startPlayer,
});
</script>

<template>
  <div class="page-second-step">
    <div class="step-title">Why YoloCast?</div>
    <div class="step-type">
      <div class="type-item" v-for="(type, index) in typeList" :key="type">
        {{ type }}<i v-if="index !== typeList.length - 1"></i>
      </div>
    </div>
    <div class="step-video">
      <div class="left-flower"></div>
      <div class="right-flower"></div>
      <div class="video-round-box">
        <div class="step-video-box">
          <TcPlayer
            v-if="playOption && changedTcPlayer"
            ref="tcPlayerRef"
            class="control-player__play-inner"
            :id="`tc-player-${new Date().getTime()}`"
            v-bind="playOption"
          ></TcPlayer>
        </div>
        <div class="video-mask"></div>
      </div>
    </div>
    <div class="step-advance">
      <div
        class="advance-item"
        :class="{ 'select-advance-item': item.select }"
        v-for="item in advanceList"
        :key="item.title"
        @click="selectCurrentAdvance(item)"
      >
        <div class="advance-mask"></div>
        <div class="advance-item-logo">
          <img class="default-logo" :src="item.iconDefaultUrl" alt="" />
          <img class="actived-logo" :src="item.iconActivedUrl" alt="" />
        </div>
        <div class="advance-item-msg">
          <div class="msg-title">{{ item.title }}</div>
          <div class="msg-desc">{{ item.desc }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.page-second-step {
  width: 100%;
  min-height: 100vh;
  padding: 70px 0 0;

  .step-title {
    margin-bottom: 20px;
    font-family: Montserrat-Bold, Montserrat;
    font-size: 32px;
    font-weight: bold;
    line-height: 39px;
    color: #fff;
    text-align: center;
  }

  .step-type {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 45px;

    .type-item {
      display: flex;
      align-items: center;
      font-family: Montserrat-Regular, Montserrat;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      color: #fff;

      i {
        display: block;
        width: 1px;
        height: 12px;
        margin: 0 15px;
        background: rgba(255, 255, 255, 0.3);
      }
    }
  }

  .step-video {
    position: relative;
    width: 39vw;
    min-width: 590px;
    max-width: 750px;
    margin: 0 auto 30px;

    .left-flower {
      position: absolute;
      bottom: 0;
      left: -93px;
      z-index: 1;
      width: 120px;
      height: 220px;
      background: url(@/assets/images/icon-left-flower.png) no-repeat;
      background-size: 100%;
    }

    .right-flower {
      position: absolute;
      right: -117px;
      bottom: 0;
      z-index: 1;
      width: 164px;
      height: 326px;
      background: url(@/assets/images/icon-right-flower.png) no-repeat;
      background-size: 100%;
    }

    .video-round-box {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 15px;

      .step-video-box {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 56.25%;
        overflow: hidden;
        border-radius: 12px;

        .control-player__play-inner {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 200 !important;
          width: 100% !important;
          height: 100% !important;
        }
      }

      .video-mask {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 100;
        background: rgba(255, 255, 255, 0.1);
        border: 2px solid rgba(96, 107, 255, 0.6);
        border-radius: 12px;
        backdrop-filter: blur(22.5px);
      }
    }
  }

  .step-advance {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 50vw;
    min-width: 740px;
    margin: 0 auto;

    .advance-item {
      position: relative;
      display: flex;
      width: 49%;
      height: 110px;
      padding: 15px;
      margin-bottom: 20px;
      cursor: pointer;
      background: transparent;
      border-radius: 8px;
      transition: all 0.3s;

      .advance-mask {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        border: 1px solid #fff;
        border-radius: 8px;
        opacity: 0;
        transition: all 0.3s;
      }

      .advance-item-logo {
        width: 40px;
        height: 40px;
        margin-right: 20px;

        img {
          width: 100%;
          height: 100%;
        }

        .default-logo {
          display: block;
        }

        .actived-logo {
          display: none;
        }
      }

      .advance-item-msg {
        flex: 1;

        .msg-title {
          margin-bottom: 4px;
          font-family: Montserrat-Bold, Montserrat;
          font-size: 18px;
          font-weight: bold;
          line-height: 22px;
          color: rgba(255, 255, 255, 0.7);
        }

        .msg-desc {
          font-family: Montserrat-Regular, Montserrat;
          font-size: 16px;
          font-weight: 400;
          line-height: 18px;
          color: rgba(255, 255, 255, 0.7);
        }
      }

      &.select-advance-item {
        background: rgba(255, 255, 255, 0.1);
        transition: all 0.3s;

        .advance-mask {
          opacity: 1;
          transition: all 0.3s;
        }

        .advance-item-logo {
          .default-logo {
            display: none;
          }

          .actived-logo {
            display: block;
          }
        }

        .advance-item-msg {
          flex: 1;

          .msg-title {
            color: rgba(255, 255, 255, 1);
          }

          .msg-desc {
            color: rgba(255, 255, 255, 1);
          }
        }
      }

      &:hover {
        .advance-mask {
          opacity: 1;
          transition: all 0.3s;
        }
      }
    }
  }
}
</style>
