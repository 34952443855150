export const HOME = 'Home';
export const BROADCASTS = 'Live Events';
export const BROADCASTSADD = 'Create a live event';
export const BROADCASTSUPDATE = 'Create a live event';

export const EMBED = 'Embed & Playlist';
export const EMBED_GENERAL = 'General';
export const EMBED_MAIN = 'Embed';

export const MEDIA = 'Media Center';
export const REPORTS = 'Reports';
export const DETAILEDREPORTS = 'Detailed Reports';
export const NEW_DETAILED_REPORTS = 'Detailed Reports';
export const SOURCES = 'Stream Source';
export const SETTINGS = 'Settings';
export const ACCOUNT = 'Account';
export const PERFERENCES = 'Perferences';
export const BILLING = 'Billing';
export const RESOURCES = 'Resources';
export const USERS = 'Users';
export const PLANS = 'Plans';
export const COMMISSION = 'Commission';
export const PREFERENCES = 'Preferences';
export const INTEGRATIONS = 'Integrations';
export const PAYMENT = 'Payment';
export const STORAGE = 'Storage';
export const APIKEYS = 'Api Keys';
export const APPEARANCE = 'Preferences';
export const DESTINATIONS = 'Destinations';
export const THEMES = 'Themes';
export const THEMES_DETAIL = 'Themes-detail';
export const URL_OVERLAY = 'OverlayLab';
export const OVERLAYS_LIBRARY = 'Overlays Library';
export const MY_OVERLAYS = 'My Overlays';
export const MY_COMPOSITIONS = 'My Compositions';
export const MY_THIRD_OVERLAYS = 'Third-Party GFX';
export const VIDEO_TUTORIALS = 'Video Tutorials';

export const BROADCAST_SOURCES = 'Sources';
export const BROADCAST_OVERVIEW = 'Overview';
export const BROADCAST_DESTINATIONS = 'Destinations';
export const BROADCAST_DIAGNOSTICS = 'Diagnostics';
export const BROADCAST_ANALYTICS = 'Analytics';
export const BROADCAST_DOCUMENTS = 'Documents';
export const BROADCAST_ADS = 'Overlay Video';
export const BROADCAST_CHAT = 'Comments';
export const BROADCAST_CHAT_COMMENT = 'Comments';
export const BROADCAST_CHAT_HIDDEN = 'Hiddenner';
export const BROADCAST_REPORT = 'Report';
export const BROADCAST_REPORT_LIVE_DATA = 'Live Data';
export const BROADCAST_REPORT_VIEWERS_DATA = 'Viewers Data';
export const BROADCAST_REPORT_STREAM_DATA = 'Stream Analytics';
export const BROADCAST_TRIM = 'Trim Recording';
export const BROADCAST_CLIPS = 'Highlight Clips';
export const BROADCAST_OVERLAYS = 'Overlays';
export const BROADCAST_SCOREBOARD = 'Scoreboard';

export const YOLOCUT_LIST = 'YoloCut';

export const WEB_CONTROL = 'Web Control';
export const WEB_CONTROL_LINK = 'Web Control';
export const WEB_CONTROL_DETAIL = 'Web Control';
