import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import './global.scss';
import '@yunxi/snow-crop/lib/style.css';
import ga from '@/directives/ga';
import copy from '@/directives/copy';
import drag from '@/directives/drag';
import scroll from '@/directives/scroll';
import permission from '@/directives/permission';
import driver from '@/directives/driver';
import badge from '@/directives/badge';
import dayjs from 'dayjs';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import 'viewerjs/dist/viewer.css';
import 'driver.js/dist/driver.min.css';
import 'vant/lib/index.css';
import { CountDown, List } from 'vant';

import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(advancedFormat);
dayjs.extend(duration);
const app = createApp(App);

if (import.meta.env.VITE_DEPLOY === 'production') {
  Sentry.init({
    app,
    dsn: 'https://0746626d9b894f5d848c6134db7077d3@o1385194.ingest.sentry.io/6704651',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // release: process.env.CI_COMMIT_TAG || import.meta.env.VITE_DEPLOY || 'yolo-cast-b',
  });
}

app.use(createPinia()).use(router).mount('#app');
app.directive('ga', ga);
app.directive('copy', copy);
app.directive('drag', drag);
app.directive('permission', permission);
app.directive('driver', driver);
app.directive('scroll', scroll);
app.directive('badge', badge);
app.use(CountDown).use(List);
